import React, { useEffect, useState } from "react";
import SearchInput from "../../../../components/ui/SearchInput";
import BasicTableRender from "../../../../components/tables/Basic/BasicTableRender";
import { getCodeCedDechetColumns, getCodeFamilleDechetColumns, getConditionnementColumns } from "../../../../components/tables/Basic/BasicTableColumns";
import Modal from "../../../../components/Modal";
import Hider from 'react-hider';
import AjouterCodeFamille from "./AjouterCodeFamille";
import SupprimerModalForm from "../../../../components/forms/SupprimerModalForm";
import ModifierCodeFamille from "./ModifierCodeFamille";
import AjouterCodeCed from "./AjouterCodeCed";
import ModifierCodeCed from "./ModifierCodeCed";
import { showError } from "../../../../utils/ErrorHelper";
import axiosInstance from "../../../../utils/axiosInstance";
import AjouterConditionnement from "./AjouterConditionnement";
import ModifierConditionnement from "./ModifierConditionnement";

const OptionsDechets = (props) => {
    const [codeFamilleOptions, setCodeFamilleOptions] = useState([]);
    const [codeCedOptions, setCodeCedOptions] = useState([]);
    const [conditionnementOptions, setConditionnementOptions] = useState([]);

    useEffect(() => {
        axiosInstance.get("/formOptions/options/codeFamilleDechet").then((result) => {
            setCodeFamilleOptions(result.data);
        }).catch((err) => {
            showError(err);
        });
        axiosInstance.get("/formOptions/options/codeCedDechet").then((result) => {
            setCodeCedOptions(result.data);
        }).catch((err) => {
            showError(err);
        });
        axiosInstance.get("/formOptions/options/conditionnementDechet").then((result) => {
            setConditionnementOptions(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    //--------------- DECHETS ------------------

    // Code famille
    const ajouterCodeFamille = (nouveauCodeFamille) => {
        setCodeFamilleOptions(codeFamilleOptions.concat(nouveauCodeFamille));
    };

    const modifierCodeFamille = (codeModifier, oldValue) => {
        setCodeFamilleOptions(codeFamilleOptions.map((code) => {
            if (code.value === oldValue) {
                return codeModifier;
            }
            return code;
        }));
    };

    const supprimerCodeFamille = (codeSupprimer) => {
        setCodeFamilleOptions(codeFamilleOptions.filter((code) => code.value !== codeSupprimer.value));
    };

    // Code CED
    const ajouterCodeCed = (nouveauCodeCed) => {
        setCodeCedOptions(codeCedOptions.concat(nouveauCodeCed));
    };

    const modifierCodeCed = (codeModifier, oldValue) => {
        setCodeCedOptions(codeCedOptions.map((code) => {
            if (code.value === oldValue) {
                return codeModifier;
            }
            return code;
        }));
    };

    const supprimerCodeCed = (codeSupprimer) => {
        setCodeCedOptions(codeCedOptions.filter((code) => code.value !== codeSupprimer.value));
    };

    // Conditionnement

    const ajouterConditionnement = (nouveauConditionnement) => {
        setConditionnementOptions(conditionnementOptions.concat(nouveauConditionnement));
    };

    const modifierConditionnement = (conditionnementModifier, oldValue) => {
        setConditionnementOptions(conditionnementOptions.map((conditionnement) => {
            if (conditionnement.value === oldValue) {
                return conditionnementModifier;
            }
            return conditionnement;
        }));
    };

    const supprimerConditionnement = (codeSupprimer) => {
        setConditionnementOptions(conditionnementOptions.filter((code) => code.value !== codeSupprimer.value));
    };

    //--------------- CODE FAMILLE ------------------

    const [searchCodeFamilleBarContent, setSearchCodeFamilleBarContent] = useState(null);

    const onChangeSearchCodeFamille = (e) => {
        setSearchCodeFamilleBarContent(e.target.value);
    };

    const [filterTableCodeFamille, setFilterTableCodeFamille] = useState(false);

    useEffect(() => {
        if (searchCodeFamilleBarContent !== null && searchCodeFamilleBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTableCodeFamille(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTableCodeFamille(true);
        }
    }, [codeFamilleOptions, searchCodeFamilleBarContent]);

    //--------------- CODE CED ------------------
    const [searchCodeCedBarContent, setSearchCodeCedBarContent] = useState(null);

    const onChangeSearchCodeCed = (e) => {
        setSearchCodeCedBarContent(e.target.value);
    };

    const [filterTableCodeCed, setFilterTableCodeCed] = useState(false);

    useEffect(() => {
        if (searchCodeCedBarContent !== null && searchCodeCedBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTableCodeCed(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTableCodeCed(true);
        }
    }, [codeCedOptions, searchCodeCedBarContent]);

    //--------------- TYPE DE CONDITIONNEMENT ------------------
    const [searchConditionnementBarContent, setSearchConditionnementBarContent] = useState(null);

    const onChangeSearchConditionnement = (e) => {
        setSearchConditionnementBarContent(e.target.value);
    };

    const [filterTableConditionnement, setFilterTableConditionnement] = useState(false);

    useEffect(() => {
        if (searchConditionnementBarContent !== null && searchConditionnementBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTableConditionnement(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTableConditionnement(true);
        }
    }, [conditionnementOptions, searchConditionnementBarContent]);

    //--------------- MODAL ------------------
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalCodeFamilleSelected, setModalCodeFamilleSelected] = useState({});
    const [modalCodeCedSelected, setModalCodeCedSelected] = useState({});
    const [modalConditionnementSelected, setModalConditionnementSelected] = useState({});

    const openAjouterCodeFamilleModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouterCodeFamille");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierCodeFamilleModal = (codeFamille) => {
        setModalCodeFamilleSelected(codeFamille);
        setModalClasses("modal modal__active");
        setModalContent("modifierCodeFamille");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openSupprimerCodeFamilleModal = (codeFamille) => {
        setModalCodeFamilleSelected(codeFamille);
        setModalClasses("modal modal__active");
        setModalContent("supprimerCodeFamille");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openAjouterCodeCedModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouterCodeCed");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierCodeCedModal = (codeCed) => {
        setModalCodeCedSelected(codeCed);
        setModalClasses("modal modal__active");
        setModalContent("modifierCodeCed");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openSupprimerCodeCedModal = (codeCed) => {
        setModalCodeCedSelected(codeCed);
        setModalClasses("modal modal__active");
        setModalContent("supprimerCodeCed");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openAjouterConditionnementModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouterConditionnement");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierConditionnementModal = (conditionnement) => {
        setModalConditionnementSelected(conditionnement);
        setModalClasses("modal modal__active");
        setModalContent("modifierConditionnement");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openSupprimerConditionnementModal = (conditionnement) => {
        setModalConditionnementSelected(conditionnement);
        setModalClasses("modal modal__active");
        setModalContent("supprimerConditionnement");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    return (
        <>
            <div className="setings-container mgT-s2">
                <div className="settings-top-section">
                    <h4>Déchets</h4>
                    <p>Modifier les codes de famille de déchets et les codes CED</p>
                </div>
                <div className="settings-main-section">
                    <div className="options-container">
                        <label className='form-label'>Code famille déchet</label>
                        <div className="filterContainer mgT-s2">
                            <SearchInput fullwidth={true} placeholder="Rechercher un code famille de déchets" searchContent={searchCodeFamilleBarContent ? searchCodeFamilleBarContent : ""} onChangeSearch={onChangeSearchCodeFamille} />
                            <button className='btn btn-primary' onClick={() => openAjouterCodeFamilleModal()}>
                                + Ajouter un code famille
                            </button>
                        </div>
                        <BasicTableRender
                            columns={getCodeFamilleDechetColumns((codeFamille) => openSupprimerCodeFamilleModal(codeFamille), (codeFamille) => openModifierCodeFamilleModal(codeFamille))}
                            tableData={codeFamilleOptions}
                            sortBy={[
                                {
                                    id: "value",
                                    desc: false
                                }
                            ]}
                            pageSize={5}
                            filterTable={filterTableCodeFamille}
                            setFilterTable={setFilterTableCodeFamille}
                            searchBarContent={searchCodeFamilleBarContent}
                        />
                    </div>
                    <div className="options-container">
                        <label className='form-label'>Code CED</label>
                        <div className="filterContainer mgT-s2">
                            <SearchInput fullwidth={true} placeholder="Rechercher un code CED" searchContent={searchCodeCedBarContent ? searchCodeCedBarContent : ""} onChangeSearch={onChangeSearchCodeCed} />
                            <button className='btn btn-primary' onClick={() => openAjouterCodeCedModal()}>
                                + Ajouter un code CED
                            </button>
                        </div>
                        <BasicTableRender
                            columns={getCodeCedDechetColumns((codeCed) => openSupprimerCodeCedModal(codeCed), (codeCed) => openModifierCodeCedModal(codeCed))}
                            tableData={codeCedOptions}
                            sortBy={[
                                {
                                    id: "value",
                                    desc: false
                                }
                            ]}
                            pageSize={5}
                            filterTable={filterTableCodeCed}
                            setFilterTable={setFilterTableCodeCed}
                            searchBarContent={searchCodeCedBarContent}
                        />
                    </div>
                    <div className="options-container">
                        <label className='form-label'>Conditionnement du déchet</label>
                        <div className="filterContainer mgT-s2">
                            <SearchInput fullwidth={true} placeholder="Rechercher un type de conditionnement" searchContent={searchConditionnementBarContent ? searchConditionnementBarContent : ""} onChangeSearch={onChangeSearchConditionnement} />
                            <button className='btn btn-primary' onClick={() => openAjouterConditionnementModal()}>
                                + Ajouter un conditionnement
                            </button>
                        </div>
                        <BasicTableRender
                            columns={getConditionnementColumns((conditionnement) => openModifierConditionnementModal(conditionnement), (conditionnement) => openSupprimerConditionnementModal(conditionnement))}
                            tableData={conditionnementOptions}
                            sortBy={[
                            ]}
                            pageSize={5}
                            filterTable={filterTableConditionnement}
                            setFilterTable={setFilterTableConditionnement}
                            searchBarContent={searchConditionnementBarContent}
                        />
                    </div>
                </div>
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouterCodeFamille"}>
                    <AjouterCodeFamille ajouterCodeFamille={ajouterCodeFamille} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierCodeFamille"}>
                    <ModifierCodeFamille modifierCodeFamille={modifierCodeFamille} codeFamilleSelected={modalCodeFamilleSelected} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimerCodeFamille"}>
                    <SupprimerModalForm supprimerCodeFamille={supprimerCodeFamille} destination="supprimerCodeFamilleOptions" codeFamilleSelected={modalCodeFamilleSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "ajouterCodeCed"}>
                    <AjouterCodeCed ajouterCodeCed={ajouterCodeCed} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierCodeCed"}>
                    <ModifierCodeCed modifierCodeCed={modifierCodeCed} codeCedSelected={modalCodeCedSelected} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimerCodeCed"}>
                    <SupprimerModalForm supprimerCodeCed={supprimerCodeCed} destination="supprimerCodeCedOptions" codeCedSelected={modalCodeCedSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "ajouterConditionnement"}>
                    <AjouterConditionnement ajouterConditionnement={ajouterConditionnement} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierConditionnement"}>
                    <ModifierConditionnement modifierConditionnement={modifierConditionnement} progressBarRef={props.progressBarRef} conditionnementSelected={modalConditionnementSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimerConditionnement"}>
                    <SupprimerModalForm supprimerConditionnement={supprimerConditionnement} destination="supprimerConditionnementOptions" conditionnementSelected={modalConditionnementSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>
    );
};

export default OptionsDechets;