import React, { useEffect, useRef, useState } from 'react';
import { getNumeroTransportDemande, getNumeroTransportType } from '../../../utils/GeneralHelper';
import * as yup from "yup";
import { useFormik } from 'formik';
import DatePicker from 'react-date-picker';
import format from 'date-fns/format';
import axiosInstance from '../../../utils/axiosInstance';
import Select from "react-select";
import { showError } from '../../../utils/ErrorHelper';
import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import SingleTimeInput from '../../SingleTimeInput';
import customToast from '../../../utils/ToastifyHelper';
import { socket } from '../../../utils/Socket';
import Creatable from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import ProfilePicture from '../../ui/ProfilePicture';
import DefaultProfilePicture from '../../ui/DefaultProfilePicture';
import Hider from 'react-hider';

const ModifierTransportAssigner = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [afficherCarteDepart, setAfficherCarteDepart] = useState(false);
    const [afficherCarteArriver, setAfficherCarteArriver] = useState(false);
    const [vehiculesGroupedOptions, setVehiculesGroupedOptions] = useState({ "tracteurBenne": [], "remorque": [] });

    const tracteurPorteurSchema = yup.object().shape({
        label: yup.string().required().typeError('Veuillez sélectionner le tracteur/porteur'),
        value: yup.string().matches(/^[0-9a-fA-F]{24}$/).required().typeError('Veuillez sélectionner le tracteur/porteur')
    });

    const remorqueSchema = yup.object().shape({
        label: yup.string().required().typeError('Veuillez sélectionner la remorque'),
        value: yup.string().matches(/^[0-9a-fA-F]{24}$/).required().typeError('Veuillez sélectionner la remorque'),
    });

    const getFormSchema = () => {
        if (props.transportData.demande.type === "DemandeTransportAmpiroll") {
            return yup.object().shape({
                lieuDepart: yup.string().required("Veuillez sélectionner le lieu de prise"),
                lieuArriver: yup.string().required("Veuillez sélectionner le lieu d'arrivée"),
                remorque: remorqueSchema,
                tracteurPorteur: tracteurPorteurSchema,
                materiaux: yup.string().required("Veuillez sélectionner un matériaux").typeError('Veuillez sélectionner un matériaux'),
                provenanceMateriau: yup.string().nullable(),
                vehicule: yup.string().required("Veuillez sélectionner l'ampiroll").typeError('Veuillez sélectionner l\'ampiroll'),
                priorite: yup.string().required("Veuillez sélectionner la priorité"),
                dateLivraison: yup.date(),
                info: yup.string(),
                informationsTransport: yup.string()
            });
        }
        else if (props.transportData.demande.type === "DemandeTransportCamion") {
            return yup.object().shape({
                lieuDepart: yup.string().required("Veuillez sélectionner le lieu de prise"),
                lieuArriver: yup.string().required("Veuillez sélectionner le lieu d'arrivée"),
                typeVehicule: yup.string().required("Veuillez sélectionner un type de véhicule").typeError('Veuillez sélectionner un type de véhicule'),
                remorque: remorqueSchema,
                tracteurPorteur: tracteurPorteurSchema,
                materiaux: yup.string().required("Veuillez sélectionner un matériaux").typeError('Veuillez sélectionner un matériaux'),
                provenanceMateriau: yup.string().nullable(),
                priorite: yup.string().required("Veuillez sélectionner la priorité"),
                nombreTours: yup.string("Veuillez sélectionner le nombre de tours").required("Veuillez sélectionner le nombre de tours").typeError('Veuillez sélectionner le nombre de tours').test("valid-numeric-value", "Le nombre de tours doit être une valeur numérique", ((value) => {
                    return value === "Rotation sur place" || value.match(/^\d+$/);
                })),
                dateLivraison: yup.date(),
                info: yup.string(),
                doubleFret: yup.boolean(),
                informationsTransport: yup.string()
            });
        }
        else {
            return yup.object().shape({
                lieuDepart: yup.string().required("Veuillez sélectionner le lieu de prise"),
                lieuArriver: yup.string().required("Veuillez sélectionner le lieu de livraison"),
                dateLivraison: yup.date(),
                typeVehicule: yup.string().required("Veuillez sélectionner un type de vehicule").typeError("Veuillez sélectionner un type de vehicule"),
                priorite: yup.string().required("Veuillez sélectionner la priorité"),
                info: yup.string(),
                remorque: remorqueSchema,
                tracteurPorteur: tracteurPorteurSchema,
                informationsTransport: yup.string()
            });
        }
    };

    const formSchema = getFormSchema();

    const onSubmit = (values, actions) => {
        let data;
        if (props.transportData.demande.type === "DemandeTransportAmpiroll") {
            data = {
                lieuDepart: values.lieuDepart,
                typeLieuDepart: typeLieuDepart,
                lieuArriver: values.lieuArriver,
                typeLieuArriver: typeLieuArriver,
                vehicule: values.vehicule,
                priorite: values.priorite,
                remorque: values.remorque.value,
                tracteurPorteur: values.tracteurPorteur.value,
                typeDemande: props.transportData.demande.typeDemande,
                materiau: values.materiaux,
                provenanceMateriau: values.provenanceMateriau,
                dateLivraison: format(values.dateLivraison, "yyyy-MM-dd"),
                horaire: {
                    heure: horaire.heure !== "" ? parseInt(horaire.heure) : null,
                    minute: horaire.minute !== "" ? parseInt(horaire.minute) : null,
                },
                info: values.info,
                informationsTransport: values.informationsTransport
            };
            props.progressBarRef.current.continuousStart();
            axiosInstance.patch(`/transports/modifierTransportAmpirollAssigner/${props.transportData._id}`, data).then((result) => {
                props.progressBarRef.current.complete();
                customToast.success('Un transport a été modifié avec succès');
                // socket createur
                socket.emit("demande_transport:edit", { demandeTransport: result.data.demande, room: props.transportData.demande.creator._id });
                // socket chauffeur
                socket.emit("transport_chauffeur:edit", { room: result.data.chauffeur._id._id, transport: result.data });
                props.onEditTransport(result.data);
                close();
            }).catch((err) => {
                props.progressBarRef.current.complete();
                showError(err);
            });
        }
        else if (props.transportData.demande.type === "DemandeTransportCamion") {
            data = {
                lieuDepart: values.lieuDepart,
                typeLieuDepart: typeLieuDepart,
                lieuArriver: values.lieuArriver,
                typeLieuArriver: typeLieuArriver,
                typeVehicule: values.typeVehicule,
                remorque: values.remorque.value,
                tracteurPorteur: values.tracteurPorteur.value,
                priorite: values.priorite,
                typeDemande: props.transportData.demande.typeDemande,
                dateLivraison: format(values.dateLivraison, "yyyy-MM-dd"),
                horaire: {
                    heure: horaire.heure !== "" ? parseInt(horaire.heure) : null,
                    minute: horaire.minute !== "" ? parseInt(horaire.minute) : null,
                },
                info: values.info,
                materiau: values.materiaux,
                provenanceMateriau: values.provenanceMateriau,
                nombreTours: values.nombreTours === "Rotation sur place" ? -1 : parseInt(values.nombreTours),
                doubleFret: values.doubleFret,
                informationsTransport: values.informationsTransport
            };
            props.progressBarRef.current.continuousStart();
            axiosInstance.patch(`/transports/modifierTransportCamionAssigner/${props.transportData._id}`, data).then((result) => {
                props.progressBarRef.current.complete();
                customToast.success('Un transport a été modifié avec succès');
                // socket createur
                socket.emit("demande_transport:edit", { demandeTransport: result.data.demande, room: props.transportData.demande.creator._id });
                // socket chauffeur
                socket.emit("transport_chauffeur:edit", { room: result.data.chauffeur._id._id, transport: result.data });
                props.onEditTransport(result.data);
                close();
            }).catch((err) => {
                props.progressBarRef.current.complete();
                showError(err);
            });
        }
        else {
            data = {
                lieuDepart: values.lieuDepart,
                typeLieuDepart: typeLieuDepart,
                lieuArriver: values.lieuArriver,
                typeLieuArriver: typeLieuArriver,
                typeVehicule: values.typeVehicule,
                remorque: values.remorque.value,
                tracteurPorteur: values.tracteurPorteur.value,
                priorite: values.priorite,
                dateLivraison: format(values.dateLivraison, "yyyy-MM-dd"),
                horaire: {
                    heure: horaire.heure !== "" ? parseInt(horaire.heure) : null,
                    minute: horaire.minute !== "" ? parseInt(horaire.minute) : null,
                },
                info: values.info,
                doubleFret: values.doubleFret,
                informationsTransport: values.informationsTransport
            };
            props.progressBarRef.current.continuousStart();
            axiosInstance.patch(`/transports/modifierTransportPorteEnginAssigner/${props.transportData._id}`, data).then((result) => {
                props.progressBarRef.current.complete();
                customToast.success('Un transport a été modifié acec succès');
                // socket createur
                socket.emit("demande_transport:edit", { demandeTransport: result.data.demande, room: props.transportData.demande.creator._id });
                // socket chauffeur
                socket.emit("transport_chauffeur:edit", { room: result.data.chauffeur._id._id, transport: result.data });
                props.onEditTransport(result.data);
                close();
            }).catch((err) => {
                props.progressBarRef.current.complete();
                showError(err);
            });
        }
    };

    const [allAmpirollesOptions, setAllAmpirollesOptions] = useState([]);

    const fetchAmpiroll = () => {
        axiosInstance.get("/vehicules?typeVehicule=Ampiroll").then((res) => {
            setAllAmpirollesOptions(res.data.map((ampirolle) => {
                return { value: ampirolle._id, label: `n°${ampirolle.numeroParc} - ${ampirolle.immatriculation} - ${ampirolle.marque}` };
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    const [chantiersOptions, setChantiersOptions] = useState([]);

    const fetchChantiers = () => {
        axiosInstance.get("/chantiers?statut_chantier=0&zone=true")
            .then((result) => {
                setChantiersOptions(result.data.map((chantier) => {
                    return { label: `${chantier.codeChantier} - ${chantier._id.libelle_lieu}`, value: chantier._id.libelle_lieu, value: chantier._id._id, adresse: chantier._id.adresse, typeLieu: "chantier" };
                }));
            }).catch((err) => {
                showError(err);
            });
    };

    const [sitesOptions, setSitesOptions] = useState([]);

    const fetchSites = () => {
        axiosInstance.get("/site").then((res) => {
            setSitesOptions(res.data.map((site) => {
                return { value: site._id._id, label: site._id.libelle_lieu, adresse: site._id.adresse, typeLieu: "site" };
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    const groupedOptions = [
        {
            label: "Chantier",
            options: chantiersOptions
        },
        {
            label: "Site",
            options: sitesOptions
        }
    ];


    const getInitialValues = () => {
        if (props.transportData.demande.type === "DemandeTransportAmpiroll") {
            return {
                lieuDepart: props.transportData.demande.lieuDepart._id._id,
                lieuArriver: props.transportData.demande.lieuArriver._id._id,
                priorite: props.transportData.demande.priorite,
                dateLivraison: new Date(props.transportData.demande.dateLivraison),
                info: props.transportData.demande.info,
                vehicule: props.transportData.demande.vehicule._id,
                materiaux: props.transportData.demande.materiau._id,
                provenanceMateriau: props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? props.transportData.demande.provenanceMateriau._id : null,
                informationsTransport: props.transportData.informationsTransport ? props.transportData.informationsTransport : "",
                remorque: { label: `n°${props.transportData.remorque.numeroParc} - ${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}`, value: props.transportData.remorque._id },
                tracteurPorteur: props.transportData.tracteurPorteur.numeroParc ? { label: `n°${props.transportData.tracteurPorteur.numeroParc} - ${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`, value: props.transportData.tracteurPorteur._id } : { label: `${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`, value: props.transportData.tracteurPorteur._id }
            };
        }
        else if (props.transportData.demande.type === "DemandeTransportCamion") {
            return {
                lieuDepart: props.transportData.demande.lieuDepart._id._id,
                lieuArriver: props.transportData.demande.lieuArriver._id._id,
                typeVehicule: props.transportData.demande.typeVehicule,
                materiaux: props.transportData.demande.materiau._id,
                provenanceMateriau: props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? props.transportData.demande.provenanceMateriau._id : null,
                priorite: props.transportData.demande.priorite,
                nombreTours: props.transportData.demande.nombreTours.toString() === "-1" ? "Rotation sur place" : props.transportData.demande.nombreTours.toString(),
                dateLivraison: new Date(props.transportData.demande.dateLivraison),
                info: props.transportData.demande.info,
                doubleFret: props.transportData.demande.doubleFret,
                informationsTransport: props.transportData.informationsTransport ? props.transportData.informationsTransport : "",
                remorque: { label: `n°${props.transportData.remorque.numeroParc} - ${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}`, value: props.transportData.remorque._id },
                tracteurPorteur: props.transportData.tracteurPorteur.numeroParc ? { label: `n°${props.transportData.tracteurPorteur.numeroParc} - ${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`, value: props.transportData.tracteurPorteur._id } : { label: `${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`, value: props.transportData.tracteurPorteur._id }
            };
        }
        else {
            return {
                lieuDepart: props.transportData.demande.lieuDepart._id._id,
                lieuArriver: props.transportData.demande.lieuArriver._id._id,
                dateLivraison: new Date(props.transportData.demande.dateLivraison),
                priorite: props.transportData.demande.priorite,
                typeVehicule: props.transportData.demande.typeVehicule,
                info: props.transportData.demande.info,
                informationsTransport: props.transportData.informationsTransport ? props.transportData.informationsTransport : "",
                remorque: { label: `n°${props.transportData.remorque.numeroParc} - ${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}`, value: props.transportData.remorque._id },
                tracteurPorteur: props.transportData.tracteurPorteur.numeroParc ? { label: `n°${props.transportData.tracteurPorteur.numeroParc} - ${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`, value: props.transportData.tracteurPorteur._id } : { label: `${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`, value: props.transportData.tracteurPorteur._id }
            };
        }
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting, submitForm } = useFormik({
        initialValues: getInitialValues(),
        validationSchema: formSchema,
        onSubmit
    });

    //--------------- Map ------------------
    const lieuDepartMapRef = useRef();
    const lieuArriverMapRef = useRef();

    const [typeLieuDepart, setTypeLieuDepart] = useState(props.transportData.demande.hasOwnProperty("typeLieuDepart") ? props.transportData.demande.typeLieuDepart : null);
    const [typeLieuArriver, setTypeLieuArriver] = useState(props.transportData.demande.hasOwnProperty("typeLieuArriver") ? props.transportData.demande.typeLieuArriver : null);

    const [lieuDepartMarker, setLieuDepartMarker] = useState(props.transportData.demande.hasOwnProperty("lieuDepart") && props.transportData.demande.lieuDepart._id.adresse !== null && props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude } : null);
    const [lieuArriverMarker, setLieuArriverMarker] = useState(props.transportData.demande.hasOwnProperty("lieuArriver") && props.transportData.demande.lieuArriver._id.adresse !== null && props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude } : null);

    const onChangeLieuDepart = (opt) => {
        if (opt !== null) {
            setTypeLieuDepart(opt.typeLieu);
            setFieldValue("lieuDepart", opt.value);
            if (opt.adresse !== null && opt.adresse.hasOwnProperty("gps") && opt.adresse.gps.latitude !== null && opt.adresse.gps.longitude !== null) {
                setLieuDepartMarker({ latitude: opt.adresse.gps.latitude, longitude: opt.adresse.gps.longitude });
                if (afficherCarteDepart) {
                    lieuDepartMapRef.current.flyTo({
                        center: [opt.adresse.gps.longitude, opt.adresse.gps.latitude],
                        essential: true,
                        duration: 1000
                    });
                }
            }
            else {
                setLieuDepartMarker(null);
            }
        }
        else {
            setLieuDepartMarker(null);
            setFieldValue("lieuDepart", "");
        }
    };

    const onChangeLieuArriver = (opt) => {
        if (opt !== null) {
            setTypeLieuArriver(opt.typeLieu);
            setFieldValue("lieuArriver", opt.value);
            if (opt.adresse !== null && opt.adresse.hasOwnProperty("gps") && opt.adresse.gps.latitude !== null && opt.adresse.gps.longitude !== null) {
                setLieuArriverMarker({ latitude: opt.adresse.gps.latitude, longitude: opt.adresse.gps.longitude });
                if (afficherCarteArriver) {
                    lieuArriverMapRef.current.flyTo({
                        center: [opt.adresse.gps.longitude, opt.adresse.gps.latitude],
                        essential: true,
                        duration: 1000
                    });
                }
            }
            else {
                setLieuArriverMarker(null);
            }
        }
        else {
            setLieuArriverMarker(null);
            setFieldValue("lieuArriver", "");
        }
    };

    //--------------- Supprimer demande ------------------
    const [confirmSuppression, setConfirmSuppression] = useState(false);
    const [displaySuppression, setDisplaySuppression] = useState(false);
    const openSuppressionTransport = () => {
        setDisplaySuppression(true);
        setConfirmSuppression(true);
    };
    const closeSuppressionTransport = () => {
        setDisplaySuppression(false);
        setConfirmSuppression(false);
    };

    //--------------- Dupliquer demande ------------------
    const [confirmDuplication, setConfirmDuplication] = useState(false);
    const [displayDuplication, setDisplayDuplication] = useState(false);
    const openDuplicationTransport = () => {
        setDisplayDuplication(true);
        setConfirmDuplication(true);
    };
    const closeDuplicationTransport = () => {
        setDisplayDuplication(false);
        setConfirmDuplication(false);
    };

    //--------------- Date demande ------------------
    const [dateDemandeDuplication, setDateDemandeDuplication] = useState(new Date());
    const onChangeDateDemande = (date) => {
        setDateDemandeDuplication(date);
    };

    const formatHoraire = (horaire) => {
        if (horaire.length < 2) {
            return `0${horaire}`;
        }
        return horaire;
    };

    const [horaire, setHoraire] = useState({ heure: props.transportData.demande.horaire.heure !== "" && props.transportData.demande.horaire.heure !== null ? formatHoraire(props.transportData.demande.horaire.heure.toString()) : "", minute: props.transportData.demande.horaire.minute !== "" && props.transportData.demande.horaire.minute !== null ? formatHoraire(props.transportData.demande.horaire.minute.toString()) : "" });
    const onModifierHeure = (horaire) => {
        setHoraire(horaire);
    };

    //--------------- Materiaux ------------------
    const [allMateriaux, setAllMateriaux] = useState([]);
    const fetchMateriaux = () => {
        axiosInstance.get("/materiaux").then((res) => {
            setAllMateriaux(res.data.flatMap((materiaux) => {
                let materiauxFormat = [{ value: materiaux._id, label: materiaux.libelle_mat, provenances: null }];
                for (const materiauxProvenances of materiaux.provenances) {
                    materiauxFormat.push({ value: materiaux._id, label: `${materiaux.libelle_mat} (${materiauxProvenances._id.libelle_lieu})`, provenances: materiauxProvenances._id._id });
                }
                return materiauxFormat;
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    const fetchVehicules = () => {
        axiosInstance.get("/vehicules").then((res) => {
            let groupedOptionsTracteur = {
                label: "Tracteur",
                options: res.data.filter(vehicule => {
                    return vehicule.categorie === "Tracteur";
                }).map((vehicule) => {
                    return { label: `n°${vehicule.numeroParc} - ${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id };
                })
            };
            let groupedOptionsPorteur = {
                label: "Porteur",
                options: res.data.filter(vehicule => {
                    return vehicule.categorie === "Porteur";
                }).map((vehicule) => {
                    return { label: `n°${vehicule.numeroParc} - ${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id };
                })
            };
            let remorqueOptions = res.data.filter(vehicule => {
                return vehicule.categorie === "Remorque";
            }).map((vehicule) => {
                return vehicule.numeroParc ? { label: `n°${vehicule.numeroParc} - ${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id } : { label: `${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id };
            });
            setVehiculesGroupedOptions({ "tracteurBenne": [groupedOptionsTracteur, groupedOptionsPorteur], "remorque": remorqueOptions });
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        fetchChantiers();
        fetchSites();
        fetchVehicules();
        if (props.transportData.demande.type === "DemandeTransportAmpiroll") {
            fetchAmpiroll();
            fetchMateriaux();
        }
        else if (props.transportData.demande.type === "DemandeTransportCamion") {
            fetchMateriaux();
        }
    }, []);

    const onSelectMateriaux = (materiaux) => {
        if (materiaux) {
            setFieldValue("materiaux", materiaux.value);
            setFieldValue("provenanceMateriau", materiaux.provenances);
        }
        else {
            setFieldValue("materiaux", materiaux);
            setFieldValue("provenanceMateriau", null);
        }
    };

    const materiauxOptionsIsSelected = (materiaux) => {
        return (materiaux.value === values.materiaux && materiaux.provenances === values.provenanceMateriau);
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 style={{ marginBottom: 0 }} className='modal-title'>{getNumeroTransportType(props.transportData.demande.type)}{getNumeroTransportDemande(props.transportData.demande.typeDemande)}-{props.transportData.demande.numeroTransport}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <p>Demande de <span style={{ textDecoration: "underline" }}>{props.transportData.demande.creator.nom} {props.transportData.demande.creator.prenom}</span> le {format(new Date(props.transportData.demande.dateDemande), "dd/MM/yyyy")} à {props.transportData.demande.dateDemande.substring(11, 16)}, pour le {format(new Date(props.transportData.demande.dateLivraison), "dd/MM/yyyy")} {props.transportData.demande.horaire.heure !== null && props.transportData.demande.horaire.minute !== null ? `à ${props.transportData.demande.horaire.heure}:${props.transportData.demande.horaire.minute}` : ""} </p>
            {/* <div style={{display: "flex", alignItems: "center"}} className='mgL-s1'>
                <p className='mgR-s2'>pour le</p>
                <DatePicker onChange={(date) => setFieldValue("dateLivraison", date)} value={values.dateLivraison} className="singleDatePicker" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd/MM/yyyy" showLeadingZeros={true} calendarIcon={null} clearIcon={null} />
            </div> */}
            <div style={{ overflowX: "hidden", position: "relative", overflowY: displayDuplication || displaySuppression ? "hidden" : "auto", height: "100%" }}>
                <form className="vertical-form demandeForm mgT-b1" style={{ display: displayDuplication || displaySuppression ? "none" : "flex" }} onSubmit={handleSubmit}>
                    <div className='formSection mgB-m1'>
                        <h4 className='mgB-s3'>Chauffeur</h4>
                        <div className="salarieBasicInfo mgT-s2 mgB-s3">
                            <Hider state={props.transportData.chauffeur._id.profilePicture !== null}>
                                <ProfilePicture width="5rem" height="5rem" edit={false} profilePicture={props.transportData.chauffeur._id.profilePicture} />
                                <DefaultProfilePicture additionalClassname={"form"} width="5rem" height="5rem" edit={false} />
                            </Hider>
                            <div className="salarieNomStatut-container" style={{ cursor: !props.editable ? "pointer" : "auto" }}>
                                <p><span className="salarieNom">{props.transportData.chauffeur._id.nom} {props.transportData.chauffeur._id.prenom}</span></p>
                                <p className="salarieStatut">{props.transportData.chauffeur._id.login_matricule}</p>
                            </div>
                        </div>
                        <div className='label-and-input'>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: 1, marginRight: "1.4rem" }}>
                                    <label htmlFor='documentTitle' className='form-label'>Tracteur/Porteur</label>
                                    <Select
                                        options={vehiculesGroupedOptions.tracteurBenne}
                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                        classNamePrefix="formSelect-search"
                                        onChange={(opt) => setFieldValue("tracteurPorteur", opt ? opt : { label: null, value: null })}
                                        placeholder={"Porteur et tracteur"}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                fontFamily: "DM Sans",
                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                borderRadius: "0.6rem",
                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                '&:hover': {
                                                    border: '1px solid black',
                                                },
                                            })
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={values.tracteurPorteur}
                                    />
                                    {errors.tracteurPorteur && touched.tracteurPorteur && (
                                        <p className="form-error-message">{errors.tracteurPorteur.label || errors.tracteurPorteur.value}</p>
                                    )}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <label htmlFor='documentTitle' className='form-label'>Remorque</label>
                                    <Select
                                        options={vehiculesGroupedOptions.remorque}
                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                        classNamePrefix="formSelect-search"
                                        onChange={(opt) => setFieldValue("remorque", opt ? opt : { label: null, value: null })}
                                        placeholder={"Remorque"}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                fontFamily: "DM Sans",
                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                borderRadius: "0.6rem",
                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                '&:hover': {
                                                    border: '1px solid black',
                                                },
                                            })
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={values.remorque}
                                    />
                                    {errors.remorque && touched.remorque && (
                                        <p className="form-error-message">{errors.remorque.label || errors.remorque.value}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='informationsTransport' className='form-label'>Informations pour le chauffeur</label>
                            <input name='informationsTransport' className={`new-default-input${errors.informationsTransport && touched.informationsTransport ? " input-error" : ""}`} type="text" placeholder="Informations pour le chauffeur" value={values.informationsTransport} onChange={(text) => { setFieldValue("informationsTransport", text.target.value); }} />
                        </div>
                    </div>
                    <h4 className='mgB-s3'>Demande</h4>
                    {props.transportData.demande.type === "DemandeTransportAmpiroll" &&
                        <>
                            <div className='label-and-input'>
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1, marginRight: "1.4rem" }}>
                                        <label htmlFor='documentTitle' className='form-label'>{props.transportData.demande.typeDemande}</label>
                                        <Select
                                            options={allAmpirollesOptions}
                                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                            classNamePrefix="formSelect-search"
                                            onChange={(opt) => setFieldValue("vehicule", opt ? opt.value : opt)}
                                            placeholder={"Type"}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    fontFamily: "DM Sans",
                                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                    borderRadius: "0.6rem",
                                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                    '&:hover': {
                                                        border: '1px solid black',
                                                    },
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            defaultValue={{ value: props.transportData.demande.vehicule._id, label: `n°${props.transportData.demande.vehicule.numeroParc} - ${props.transportData.demande.vehicule.immatriculation} - ${props.transportData.demande.vehicule.marque}` }}
                                        />
                                        {errors.vehicule && touched.vehicule && <p className="form-error-message">{errors.vehicule}</p>}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <label htmlFor='documentTitle' className='form-label'>Matériaux</label>
                                        <Select
                                            options={allMateriaux}
                                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                            classNamePrefix="formSelect-search"
                                            onChange={(opt) => onSelectMateriaux(opt)}
                                            isOptionSelected={(materiaux) => materiauxOptionsIsSelected(materiaux)}
                                            placeholder={""}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    fontFamily: "DM Sans",
                                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                    borderRadius: "0.6rem",
                                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                    '&:hover': {
                                                        border: '1px solid black',
                                                    },
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            defaultValue={{
                                                value: props.transportData.demande.materiau._id,
                                                label: props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? `${props.transportData.demande.materiau.libelle_mat} (${props.transportData.demande.provenanceMateriau.libelle_lieu})` : props.transportData.demande.materiau.libelle_mat,
                                                provenances: props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? props.transportData.demande.provenanceMateriau._id : null
                                            }}
                                        />
                                        {errors.materiaux && touched.materiaux && <p className="form-error-message" style={{ textAlign: "left" }}>{errors.materiaux}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='label-and-input horizontal' style={{ marginBottom: "0.8rem" }}>
                                <label htmlFor='documentTitle' className='form-label'>De</label>
                                <Select
                                    options={groupedOptions}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => onChangeLieuDepart(opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}

                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.lieuDepart._id._id, label: props.transportData.demande.lieuDepart._id.libelle_lieu, adresse: props.transportData.demande.lieuDepart._id.adresse }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapDe-" + props.transportData.demande.lieuDepart._id._id}
                                        onClick={() => setAfficherCarteDepart(!afficherCarteDepart)}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapDe-" + props.transportData.demande.lieuDepart._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteDepart ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {afficherCarteDepart &&
                                <Map
                                    attributionControl={false}
                                    ref={lieuDepartMapRef}
                                    initialViewState={props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                >
                                    {lieuDepartMarker !== null &&
                                        <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                                        </Marker>
                                    }
                                </Map>
                            }
                            {errors.lieuDepart && touched.lieuDepart && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuDepart}</p>}
                            <div className='label-and-input horizontal mgT-s2' style={{ marginBottom: "0.8rem" }}>
                                <label htmlFor='documentTitle' className='form-label'>Vers</label>
                                <Select
                                    options={groupedOptions}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => onChangeLieuArriver(opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.lieuArriver._id._id, label: props.transportData.demande.lieuArriver._id.libelle_lieu, adresse: props.transportData.demande.lieuArriver._id.adresse }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapVers-" + props.transportData.demande.lieuArriver._id._id}
                                        onClick={() => setAfficherCarteArriver(!afficherCarteArriver)}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapVers-" + props.transportData.demande.lieuArriver._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteArriver ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {
                                afficherCarteArriver &&
                                <Map
                                    attributionControl={false}
                                    ref={lieuArriverMapRef}
                                    initialViewState={props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                >
                                    {lieuArriverMarker !== null &&
                                        <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                                        </Marker>
                                    }
                                </Map>
                            }
                            {errors.lieuArriver && touched.lieuArriver && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuArriver}</p>}
                        </>
                    }
                    {
                        props.transportData.demande.type === "DemandeTransportCamion" && <>
                            <div className='label-and-input'>
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1, marginRight: "1.4rem" }}>
                                        <label htmlFor='documentTitle' className='form-label'>Véhicule</label>
                                        <Select
                                            options={[{ label: "19t", value: "19t" }, { label: "8x4", value: "8x4" }, { label: "6x4", value: "6x4" }, { label: "Pata", value: "Pata" }, { label: "Semi", value: "Semi" }]}
                                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                            classNamePrefix="formSelect-search"
                                            onChange={(opt) => setFieldValue("typeVehicule", opt ? opt.value : opt)}
                                            placeholder={""}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    fontFamily: "DM Sans",
                                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                    borderRadius: "0.6rem",
                                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                    '&:hover': {
                                                        border: '1px solid black',
                                                    },
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            defaultValue={{ value: props.transportData.demande.typeVehicule, label: props.transportData.demande.typeVehicule }}
                                        />
                                        {errors.typeVehicule && touched.typeVehicule && <p className="form-error-message" style={{ textAlign: "left" }}>{errors.typeVehicule}</p>}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <label htmlFor='documentTitle' className='form-label'>Matériaux</label>
                                        <Select
                                            options={allMateriaux}
                                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                            classNamePrefix="formSelect-search"
                                            onChange={(opt) => onSelectMateriaux(opt)}
                                            isOptionSelected={(materiaux) => materiauxOptionsIsSelected(materiaux)}
                                            placeholder={""}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    fontFamily: "DM Sans",
                                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                    borderRadius: "0.6rem",
                                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                    '&:hover': {
                                                        border: '1px solid black',
                                                    },
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            defaultValue={{
                                                value: props.transportData.demande.materiau._id,
                                                label: props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? `${props.transportData.demande.materiau.libelle_mat} (${props.transportData.demande.provenanceMateriau.libelle_lieu})` : props.transportData.demande.materiau.libelle_mat,
                                                provenances: props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? props.transportData.demande.provenanceMateriau._id : null
                                            }}
                                        />
                                        {errors.materiaux && touched.materiaux && <p className="form-error-message" style={{ textAlign: "left" }}>{errors.materiaux}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='label-and-input horizontal' style={{ marginBottom: "0.8rem" }}>
                                <label htmlFor='documentTitle' className='form-label'>De</label>
                                <Select
                                    options={groupedOptions}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => onChangeLieuDepart(opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}

                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.lieuDepart._id._id, label: props.transportData.demande.lieuDepart._id.libelle_lieu, adresse: props.transportData.demande.lieuDepart._id.adresse }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapDe-" + props.transportData.demande.lieuDepart._id._id}
                                        onClick={() => setAfficherCarteDepart(!afficherCarteDepart)}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapDe-" + props.transportData.demande.lieuDepart._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteDepart ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {errors.lieuDepart && touched.lieuDepart && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuDepart}</p>}
                            {afficherCarteDepart &&
                                <Map
                                    attributionControl={false}
                                    ref={lieuDepartMapRef}
                                    initialViewState={props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                >
                                    {lieuDepartMarker !== null &&
                                        <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                                        </Marker>
                                    }
                                </Map>
                            }
                            <div className='label-and-input horizontal mgT-s2' style={{ marginBottom: "0.8rem" }}>
                                <label htmlFor='documentTitle' className='form-label'>Vers</label>
                                <Select
                                    options={groupedOptions}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => onChangeLieuArriver(opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.lieuArriver._id._id, label: props.transportData.demande.lieuArriver._id.libelle_lieu, adresse: props.transportData.demande.lieuArriver._id.adresse }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapVers-" + props.transportData.demande.lieuArriver._id._id}
                                        onClick={() => setAfficherCarteArriver(!afficherCarteArriver)}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapVers-" + props.transportData.demande.lieuArriver._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteArriver ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {errors.lieuArriver && touched.lieuArriver && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuArriver}</p>}
                            {
                                afficherCarteArriver &&
                                <Map
                                    attributionControl={false}
                                    ref={lieuArriverMapRef}
                                    initialViewState={props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                >
                                    {lieuArriverMarker !== null &&
                                        <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                                        </Marker>
                                    }
                                </Map>
                            }
                        </>
                    }
                    {
                        props.transportData.demande.type === "DemandeTransportPorteEngin" &&
                        <>
                            <div className='label-and-input'>
                                <label htmlFor='documentTitle' className='form-label'>Engin</label>
                                <Select
                                    options={[{ label: "19t", value: "19t" }, { label: "8x4", value: "8x4" }, { label: "6x4", value: "6x4" }, { label: "Ampiroll", value: "Ampiroll" }, { label: "Pata", value: "Pata" }, { label: "Semi", value: "Semi" }, { label: "Aucun", value: "Aucun" }]}
                                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => setFieldValue("typeVehicule", opt ? opt.value : opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.typeVehicule, label: props.transportData.demande.typeVehicule }}
                                />
                                {errors.typeVehicule && touched.typeVehicule && <p className="form-error-message">{errors.typeVehicule}</p>}
                            </div>
                            <div className='label-and-input horizontal' style={{ marginBottom: "0.8rem" }}>
                                <label htmlFor='documentTitle' className='form-label'>De</label>
                                <Select
                                    options={groupedOptions}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => onChangeLieuDepart(opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}

                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.lieuDepart._id._id, label: props.transportData.demande.lieuDepart._id.libelle_lieu, adresse: props.transportData.demande.lieuDepart._id.adresse }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapDe-" + props.transportData.demande.lieuDepart._id._id}
                                        onClick={() => setAfficherCarteDepart(!afficherCarteDepart)}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapDe-" + props.transportData.demande.lieuDepart._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteDepart ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {errors.lieuDepart && touched.lieuDepart && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuDepart}</p>}
                            {afficherCarteDepart &&
                                <Map
                                    attributionControl={false}
                                    ref={lieuDepartMapRef}
                                    initialViewState={props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                >
                                    {lieuDepartMarker !== null &&
                                        <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                                        </Marker>
                                    }
                                </Map>
                            }
                            <div className='label-and-input horizontal mgT-s2' style={{ marginBottom: "0.8rem" }}>
                                <label htmlFor='documentTitle' className='form-label'>Vers</label>
                                <Select
                                    options={groupedOptions}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => onChangeLieuArriver(opt)}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    defaultValue={{ value: props.transportData.demande.lieuArriver._id._id, label: props.transportData.demande.lieuArriver._id.libelle_lieu, adresse: props.transportData.demande.lieuArriver._id.adresse }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapVers-" + props.transportData.demande.lieuArriver._id._id}
                                        onClick={() => setAfficherCarteArriver(!afficherCarteArriver)}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapVers-" + props.transportData.demande.lieuArriver._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteArriver ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {errors.lieuArriver && touched.lieuArriver && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuArriver}</p>}
                            {
                                afficherCarteArriver &&
                                <Map
                                    attributionControl={false}
                                    ref={lieuArriverMapRef}
                                    initialViewState={props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                >
                                    {lieuArriverMarker !== null &&
                                        <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                                        </Marker>
                                    }
                                </Map>
                            }
                        </>

                    }
                    <div className='label-and-input mgT-m2'>
                        <div className="container-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                            <Select
                                options={[{ label: "Priorité normale", value: "Priorité normale" }, { label: "Urgent", value: "Urgent" }]}
                                className="formSelect-search-container formSelect-search-container-full"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => setFieldValue("priorite", opt.value)}
                                placeholder={""}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        fontFamily: "DM Sans",
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={false}
                                isClearable={false}
                                defaultValue={{ value: props.transportData.demande.priorite, label: props.transportData.demande.priorite }}
                            />
                            <SingleTimeInput style={{ flex: 0.5, marginLeft: "0.8rem" }} horaire={horaire} modifierHoraireInput={(horaire) => onModifierHeure(horaire)} />
                        </div>
                    </div>
                    {
                        props.transportData.demande.type === "DemandeTransportCamion" &&
                        <>
                            <div className='label-and-input'>
                                <Creatable
                                    options={[{ label: "Rotation sur place", value: "Rotation sur place" }]}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => setFieldValue("nombreTours", opt ? opt.value : opt)}
                                    placeholder={"Nombre de tours"}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    formatCreateLabel={value => value}
                                    createOptionPosition="first"
                                    defaultValue={props.transportData.demande.nombreTours !== -1 ? { value: props.transportData.demande.nombreTours.toString(), label: props.transportData.demande.nombreTours.toString() } : { value: "Rotation sur place", label: "Rotation sur place" }}
                                />
                                {errors.nombreTours && touched.nombreTours && <p className="form-error-message">{errors.nombreTours}</p>}
                            </div>
                            <div className='label-and-input' style={{ marginTop: "0.2rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <label htmlFor='documentTitle' className='form-label'>Double fret</label>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                                        <p>Oui</p>
                                        <input
                                            type="radio"
                                            name="doubleFret-oui"
                                            value="Oui"
                                            checked={values.doubleFret}
                                            onChange={() => setFieldValue("doubleFret", true)}
                                        />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                                        <p>Non</p>
                                        <input
                                            type="radio"
                                            name="doubleFret-non"
                                            value="Non"
                                            checked={!values.doubleFret}
                                            onChange={() => setFieldValue("doubleFret", false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='label-and-input'>
                        <input name='info' style={{ marginTop: 0 }} className={`new-default-input${errors.info && touched.info ? " input-error" : ""}`} type="text" placeholder="Informations" value={values.info} onChange={handleChange} onBlur={handleBlur} />
                        {errors.info && touched.info && <p className="form-error-message">{errors.info}</p>}
                    </div>
                    <div style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <div className='mgB-m1' style={{ display: "flex", alignItems: "center" }}>
                            <button type='button' disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
                            <button type="submit" disabled={isSubmitting} className='btn btn-primary fw medium-btn'>Modifier</button>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button type='button' disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => openSuppressionTransport()}>Supprimer ce transport</button>
                            <button type='button' disabled={isSubmitting} className='btn btn-secondary fw medium-btn' onClick={() => openDuplicationTransport()}>Dupliquer cette demande</button>
                        </div>
                    </div>
                </form>
                <div style={{ width: "100%", height: "100%", backgroundColor: "white", right: "0", left: 0, transition: "all .2s ease", position: "relative", zIndex: 10, top: confirmSuppression ? "10px" : "-100%", display: displaySuppression ? "flex" : "none", flexDirection: "column" }}>
                    <p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot' style={{ fontWeight: "700", color: "#d8556b" }}>suppression</span> de ce transport</p>
                    <div className="button-container mgT-m1">
                        <div style={{ display: "flex" }}>
                            <button type='button' disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => closeSuppressionTransport()}>Annuler</button>
                            <button type='button' disabled={isSubmitting} className='btn btn-primary fw medium-btn' onClick={() => { props.onSupprimerTransportAssigner(props.transportData); close(); }}>Valider</button>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "100%", backgroundColor: "white", right: "0", left: 0, transition: "all .2s ease", position: "relative", zIndex: 10, top: confirmDuplication ? "10px" : "-100%", display: displayDuplication ? "flex" : "none", flexDirection: "column" }}>
                    <div className='label-and-input' style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor='dupliquer' className='form-label mgB-s2'>Dupliquer la demande le</label>
                        <DatePicker onChange={(date) => onChangeDateDemande(date)} value={dateDemandeDuplication} className="singleDatePicker" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd/MM/yyyy" showLeadingZeros={true} clearIcon={null} />
                    </div>

                    <div className="button-container mgT-m1">
                        <div style={{ display: "flex" }}>
                            <button type='button' disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => closeDuplicationTransport()}>Annuler</button>
                            <button type='button' disabled={isSubmitting} className='btn btn-primary fw medium-btn' onClick={() => { props.onDupliquerTransport(props.transportData, dateDemandeDuplication); close(); }}>Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModifierTransportAssigner;
