import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from "yup";
import axiosInstance from '../../../utils/axiosInstance';
import customToast from "../../../utils/ToastifyHelper";
import { showError } from '../../../utils/ErrorHelper';
import { useFormik } from 'formik';
import { capitalizeFirstLetter, formatBytes, formatMateriaux, formatTechniqueModeOperatoire, getCoordinatesFromDD, getHeureParSalarie, getLastModified, iconExtension } from '../../../utils/GeneralHelper';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../../components/ui/Breadcrumb';
import InsideNavigationBar from '../../../components/ui/InsideNavigationBar';
import Hider from "react-hider";
import ProfilChantierAmiante_InformationsGenerales from './informationsGenerales/ProfilChantierAmiante_InformationsGenerales';
import ProfilChantierAmiante_Personnel from './personnel/ProfilChantierAmiante_Personnel';
import ProfilChantierAmiante_Zones from './zones/ProfilChantierAmiante_Zones';
import ProfilChantierAmiante_IntervenantsTiers from './IntervenantsTiers/ProfilChantierAmiante_IntervenantsTiers';
import ProfilChantierAmiante_EspaceDocumentaire from './espaceDocumentaire/ProfilChantierAmiante_EspaceDocumentaire';
import ProfilChantierAmiante_GestionsDechets from './gestionDechets/ProfilChantierAmiante_GestionDechets';
import { useSelector } from 'react-redux';

const ProfilChantierAmiante = () => {
    let { idChantier } = useParams();
    const progressBarRef = useRef(null);
    const user = useSelector(
        state => state.user.user
    );
    // Data
    const [chantier, setChantier] = useState(null);
    const [zoneChantiers, setZoneChantiers] = useState([]);
    const [allProcessus, setAllProcessus] = useState([]);
    const [allGestionsDocumentsChantiers, setAllGestionsDocumentsChantiers] = useState([]);
    const [salariesAmiante, setSalariesAmiante] = useState([]);
    const [allIntervenantsTiers, setAllIntervenantsTiers] = useState([]);
    const [releveHeure, setReleveHeure] = useState({ total: "0h", salaries: [] });



    // nav
    const [insideNavigationPage, setInsideNavigationPage] = useState("informationsGenerales");

    //--------------- Load Data ------------------
    useEffect(() => {
        axiosInstance.get(`/chantiers/${idChantier}`).then((result) => {
            setChantier(result.data);
            axiosInstance.get("/processusAmiante").then((res) => {
                let dataSelectFormat = [];
                for (const processus of res.data) {
                    dataSelectFormat.push({
                        value: processus._id, label: `${processus.codeProcessus} - ${formatMateriaux(processus.materiaux)} - Niveau ${processus.niveauEmpoussierement} - ${formatTechniqueModeOperatoire(processus.techniqueModesOperatoires)} - ${processus.protectionCollective}`,
                        isDisabled: result.data.processus.findIndex(process => process._id === processus._id) >= 0
                    });
                }
                setAllProcessus(dataSelectFormat);
            }).catch((error) => {
                showError(error);
            });
        })
            .catch((err) => {
                showError(err);
            });

        axiosInstance.get("/zones").then((res) => {
            setZoneChantiers(res.data.map((zone) => {
                return {
                    value: zone._id, label: `${zone.libelle_zone} - ${zone.portee_zone}km`
                };
            }));
        }).catch((error) => {
            showError(error);
        });

        axiosInstance.get("/uploads/gestionDocumentsChantiers").then((result) => {
            setAllGestionsDocumentsChantiers(result.data);
        })
            .catch((err) => {
                showError(err);
            });

        axiosInstance.get("/utilisateurs/allUtilisateursAmiante").then((result) => {
            setSalariesAmiante(result.data);
        }).catch((err) => {
            showError(err);
        });

        axiosInstance.get("/intervenantsTiers").then((result) => {
            setAllIntervenantsTiers(result.data);
        }).catch((err) => {
            showError(err);
        });
        if (user.statut === 'Administration') {
            axiosInstance.get(`/releve-heure-v2/resumerHeureChantier/${idChantier}`)
                .then((result) => {
                    setReleveHeure(result.data);
                })
                .catch((err) => {
                    showError(err);
                });
        }
    }, []);

    const saveChantier = () => {

    };

    const subNavigation = () => {
        if (chantier !== null) {
            if (chantier.statut_chantier === 0) {
                return [
                    { label: "Informations générales", value: "informationsGenerales" },
                    { label: "Tiers", value: "tiers" },
                    { label: "Personnel", value: "personnel" },
                    { label: "Zones", value: "zones" },
                    { label: "Gestions déchets", value: "gestionsDechets" },
                    { label: "PDRE", value: "pdre" },
                    { label: "Espace documentaire", value: "espaceDocumentaire" },
                ];
            }
            else if (chantier.statut_chantier === 1) {
                return [
                    { label: "Informations générales", value: "informationsGenerales" },
                    { label: "Documents", value: "documents" },
                    { label: "Export", value: "export" },
                    { label: "Processus", value: "processus" }
                ];
            }
        }
        return [];
    };

    const setPersonnelChantier = (personnels) => {
        setChantier({
            ...chantier,
            personnels: personnels
        });
    };

    const setIntervenantsTiersChantier = (intervenantsTiers) => {
        setChantier({
            ...chantier,
            intervenantsTiers: intervenantsTiers
        });
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {chantier &&
                <div className="section">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <h1>
                            {`(${chantier.codeChantier})`}{" "}
                            {capitalizeFirstLetter(chantier._id.libelle_lieu)}
                        </h1>
                        {/* <button onClick={() => saveChantier()} className='btn btn-primary medium-btn'>Sauvegarder</button> */}
                    </div>
                    <Breadcrumb
                        links={[
                            { label: "amiante", link: "/amiante" },
                            { label: "Chantiers amiante", link: "/amiante/chantiers" },
                            { label: chantier._id._id, link: "/amiante/chantiers/profil/" },
                        ]}
                    />
                    <InsideNavigationBar
                        activeValue={insideNavigationPage}
                        setActiveValue={(value) => setInsideNavigationPage(value)}
                        links={subNavigation()}
                    />
                    <Hider state={insideNavigationPage === "informationsGenerales"}>
                        <ProfilChantierAmiante_InformationsGenerales releveHeure={releveHeure} chantier={chantier} setChantier={setChantier} zoneChantiers={zoneChantiers} progressBarRef={progressBarRef} />
                    </Hider>
                    <Hider state={insideNavigationPage === "personnel"}>
                        <ProfilChantierAmiante_Personnel chantier={chantier} salariesAmiante={salariesAmiante} setSalariesAmiante={setSalariesAmiante} setPersonnelChantier={(personnels) => setPersonnelChantier(personnels)} setChantier={setChantier} progressBarRef={progressBarRef} />
                    </Hider>
                    <Hider state={insideNavigationPage === "zones"}>
                        <ProfilChantierAmiante_Zones chantier={chantier} setChantier={setChantier} progressBarRef={progressBarRef} />
                    </Hider>
                    <Hider state={insideNavigationPage === "tiers"}>
                        <ProfilChantierAmiante_IntervenantsTiers chantier={chantier} setChantier={setChantier} progressBarRef={progressBarRef} allIntervenantsTiers={allIntervenantsTiers} setAllIntervenantsTiers={setAllIntervenantsTiers} setIntervenantsTiersChantier={(intervenantsTiers) => setIntervenantsTiersChantier(intervenantsTiers)} />
                    </Hider>
                    <Hider state={insideNavigationPage === "espaceDocumentaire"}>
                        <ProfilChantierAmiante_EspaceDocumentaire idChantier={chantier._id._id} />
                    </Hider>
                    <Hider state={insideNavigationPage === "gestionsDechets"}>
                        <ProfilChantierAmiante_GestionsDechets idChantier={chantier._id._id} progressBarRef={progressBarRef} />
                    </Hider>
                </div>
            }
        </>
    );
};

export default ProfilChantierAmiante;
