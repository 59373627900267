import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { acceptStyle, focusedStyle, rejectStyle, baseStyle } from "../../../../utils/GeneralHelper";
import { Icon } from '@iconify/react';
import Hider from "react-hider";
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import customToast from "../../../../utils/ToastifyHelper";
import ClipLoader from "react-spinners/ClipLoader";
import { Lightbox } from "react-modal-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

const ProfilChantierAmiante_InformationsGenerales_apercuChantier = (props) => {

    const [imageFiles, setImageFiles] = useState(null);

    const uploadImage = () => {
        props.progressBarRef.current.continuousStart();
        if (imageFiles) {
            let imageChantierPostData = new FormData();
            imageChantierPostData.append("document", imageFiles.file);
            axiosInstance.post(`/uploads/imageLieu/${props.chantier._id._id}`, imageChantierPostData, { timeout: 0, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                props.progressBarRef.current.complete();
                customToast.success("La photo du chantier à été mise à jour");
                setImageFiles(null);
                props.ajouterImageChantier(result.data.imageLieu);
            }).catch((err) => {
                props.progressBarRef.current.complete();
                showError(err);
            });
        }
    };

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        multiple: false, accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': []
        }
    });

    const removeImage = () => {
        setImageFiles(null);
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setImageFiles({ file: acceptedFiles[0], preview: URL.createObjectURL(acceptedFiles[0]) });
        }
    }, [acceptedFiles]);

    const [loadingPicture, setLoadingPicture] = useState(false);
    const [initialImagePreview, setInitialImagePreview] = useState(null);


    // preview initialPicture
    useEffect(() => {
        if (props.chantier._id.hasOwnProperty("imageLieu") && props.chantier._id.imageLieu !== null) {
            setLoadingPicture(true);
            axiosInstance.get(`uploads/imageLieu/${props.chantier._id._id}`, {
                responseType: 'blob',
                timeout: 20000
            }).then((res) => {
                let fileURL = URL.createObjectURL(res.data);
                setInitialImagePreview(fileURL);
                setLoadingPicture(false);
            }).catch((error) => {
                setLoadingPicture(false);
                showError(error);
            });
        }
    }, [props.chantier._id.imageLieu]);

    const [deleteActive, setDeleteActive] = useState(false);
    const [openImageFullscreen, setOpenImageFullscreen] = useState(false);

    const supprimerImageChantier = () => {
        props.progressBarRef.current.continuousStart();
        axiosInstance.delete(`/uploads/imageLieu/${props.chantier._id._id}`).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("La photo du chantier a été supprimé");
            setInitialImagePreview(null);
            setDeleteActive(false);
            props.supprimerImageChantier();
        }).catch((err) => {
            props.progressBarRef.current.complete();
            showError(err);
        });

    };


    return (
        <div className="container-basic">
            <h4 className='mgB-b'>Photo du chantier</h4>
            <div className="container">
                <Hider state={props.chantier._id.imageLieu === null}>
                    {imageFiles ?
                        <>
                            <img src={imageFiles.preview} style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                            }} alt={imageFiles.name}></img>
                            <div className='mgT-m1' style={{ display: "flex" }}>
                                <button type='button' className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => removeImage()}>Annuler</button>
                                <button type="submit" className='btn btn-primary fw medium-btn' onClick={() => uploadImage()}>Enregistrer</button>
                            </div>
                        </>
                        :
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                            <p className='dropzone-text'>Glissez l'image du chantier, ou <span>cliquez pour le sélectionner</span></p>
                        </div>
                    }
                    {loadingPicture ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <ClipLoader loading={true} color={"#1E1E1E"} size={15} />
                        </div>
                        :
                        initialImagePreview ?
                            <div>
                                <div className="imageHover" style={{ overflow: "hidden", position: "relative" }}>
                                    <img className='voirArticleImage' src={initialImagePreview} style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover"
                                    }} alt={"fe"}></img>
                                    <div className="imageHoverWithButton">
                                        <div onClick={() => setOpenImageFullscreen(true)} className='smallRoundButton mgR-s2'>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </div>
                                        <div onClick={() => setDeleteActive(true)} className='smallRoundButton'>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </div>
                                    </div>
                                </div>
                                {deleteActive &&
                                    <div className="mgT-m1">
                                        <p>Confirmez la suppression de la photo du chantier ?</p>
                                        <div className='mgT-s2' style={{ display: "flex" }}>
                                            <button className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => setDeleteActive(false)}>Annuler</button>
                                            <button className='btn btn-primary fw medium-btn' onClick={() => supprimerImageChantier()}>Supprimer</button>
                                        </div>
                                    </div>
                                }
                            </div>

                            :
                            <p>erreur</p>
                    }
                </Hider>
                {openImageFullscreen &&
                    <Lightbox
                        large={initialImagePreview}
                        onClose={() => setOpenImageFullscreen(false)}
                        hideZoom={false}
                    />
                }
            </div>
        </div>
    );
};

export default ProfilChantierAmiante_InformationsGenerales_apercuChantier;
