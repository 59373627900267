import React, { useEffect, useState } from "react";
import ProfilePicture from "../../../../components/ui/ProfilePicture";
import DefaultProfilePicture from "../../../../components/ui/DefaultProfilePicture";
import Hider from 'react-hider';
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import { useNavigate } from "react-router-dom";
import { subDays, subMonths, subYears } from "date-fns";
import { getInsensitiveWorld } from "../../../../utils/GeneralHelper";


const PersonnelItem = (props) => {
    const [formations, setFormations] = useState([]);
    const navigate = useNavigate();

    const [checked, setChecked] = useState(props.editable ? props.defaultChecked : null);


    useEffect(() => {
        axiosInstance.get(`/salarieFormations/${props.salarie._id._id}`).then((result) => {
            let formationsResult = result.data;
            formationsResult.sort((a, b) => (a.formation.formationPour > b.formation.formationPour) ? 1 : ((b.formation.formationPour > a.formation.formationPour) ? -1 : 0));
            setFormations(formationsResult);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const getClassFormations = (dateExpiration, signalerUrgence) => {
        let currentDate = new Date();
        if (dateExpiration === null) {
            return "userlist-formation-valide";
        }
        else {
            let formatDateExpiration = new Date(dateExpiration);
            if (currentDate.getTime() > formatDateExpiration.getTime()) {
                return "userlist-formation-expired";
            }
            else {
                let dateUrgence = getDateSignalementUrgence(formatDateExpiration, signalerUrgence);
                if (currentDate.getTime() >= dateUrgence.getTime()) {
                    return "userlist-formation-urgent";
                }
                else {
                    return "userlist-formation-valide";
                }
            }
        }
    };

    const getDateSignalementUrgence = (dateExpiration, signalerUrgence) => {
        let dateUrgence = dateExpiration;
        if (signalerUrgence.annee !== null) {
            dateUrgence = subYears(dateUrgence, signalerUrgence.annee);
        }
        if (signalerUrgence.mois !== null) {
            dateUrgence = subMonths(dateUrgence, signalerUrgence.mois);
        }
        if (signalerUrgence.jour !== null) {
            dateUrgence = subDays(dateUrgence, signalerUrgence.jour);
        }
        return dateUrgence;
    };

    const onPersonnelClick = () => {
        if (!props.editable) {
            navigate(`/gestion-de-donnees/salaries/profil/${props.salarie._id._id}`);
        }
        else {
            if (checked) {
                props.supprimerSalarie();
            }
            else {
                props.ajouterSalarie();
            }
            setChecked(!checked);
        }
    };

    const onClickItem = () => {
        if (props.editable) {
            if (checked) {
                props.supprimerSalarie();
            }
            else {
                props.ajouterSalarie();
            }
            setChecked(!checked);
        }
    };

    const onChangeCheck = () => {
        if (checked) {
            props.supprimerSalarie();
        }
        else {
            props.ajouterSalarie();
        }
        setChecked(!checked);
    };

    const isSalarieVisible = () => {
        const fullname = props.salarie._id.nom + " " + props.salarie._id.prenom;
        return props.searchValue === null || getInsensitiveWorld(fullname).includes(getInsensitiveWorld(props.searchValue)) || getInsensitiveWorld(props.salarie._id.login_matricule).includes(getInsensitiveWorld(props.searchValue));
    };

    return (
        <div onClick={() => onClickItem()} className="container-basic personnelItem" style={{ opacity: checked === null || checked ? 1 : 0.5, cursor: props.editable ? "pointer" : "auto", display: isSalarieVisible() ? "flex" : "none" }}>
            <div onClick={() => onPersonnelClick()} className="salarieBasicInfo">
                <Hider state={props.salarie._id.profilePicture !== null}>
                    <ProfilePicture width="5rem" height="5rem" edit={false} profilePicture={props.salarie._id.profilePicture} />
                    <DefaultProfilePicture additionalClassname={"userlist"} width="5rem" height="5rem" edit={false} />
                </Hider>
                <div className="salarieNomStatut-container" style={{ cursor: !props.editable ? "pointer" : "auto" }}>
                    <p><span className="salarieNom">{props.salarie._id.nom} {props.salarie._id.prenom}</span> - {props.salarie._id.login_matricule}</p>
                    <p className="salarieStatut">{props.salarie._id.userRole.statut}</p>
                </div>
            </div>
            <div className={`salarieFormations${props.editable ? " selectable" : ""}`}>
                {
                    formations.map((formation, index) => {
                        return <p className={getClassFormations(formation.formationsValidees[0].dateExpiration, formation.formation.signalerUrgence)} key={formation._id}>
                            {formation.formation.nomFormation}
                            {index === formations.length - 1 ? "" : <span className="mgR-s1" style={{ color: "#1E1E1E" }}>,</span>}
                        </p>;
                    })
                }
            </div>
            {props.editable &&
                <div className="editBox-container">
                    <input className="login-checkbox" type="checkbox" checked={checked} onChange={onChangeCheck} />
                </div>
            }
        </div>
    );
};

export default PersonnelItem;
