import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import Breadcrumb from '../../components/ui/Breadcrumb';
import Hider from 'react-hider';
import SearchInput from '../../components/ui/SearchInput';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import TableRender from '../../components/tables/TableRender';
import { getEntretiensSuiviColumns, vehiculesColumns } from '../../components/tables/TableColumns';
import Modal from '../../components/Modal';
import AjouterVehicule from '../../components/forms/Vehicule/AjouterVehicule';
import ModifierVehicule from '../../components/forms/Vehicule/ModifierVehicule';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import AjouterEntretienSuivi from '../../components/forms/Vehicule/AjouterEntretienSuivi';
import ModifierEntretienSuivi from '../../components/forms/Vehicule/ModifierEntretienSuivi';


const VehiculeListPage = () => {
    const navigate = useNavigate();
    const [vehicules, setVehicules] = useState([]);
    const [showedItems, setShowedItems] = useState([]);
    const [formOptions, setFormOptions] = useState(null);

    const [filters, setFilters] = useState({
        categorieFilter: "All",
        typeVehiculeFilter: "All"
    });
    const progressBarRef = useRef(null);
    // _____________________________ //
    // ENTRETIEN VEHICULE
    const [modalEntretiensSelected, setModalEntretiensSelected] = useState({});
    const [entretiensSuivis, setEntretiensSuivis] = useState([]);
    const [showedEntretiensSuivis, setShowedEntretiensSuivis] = useState([]);

    const addEntretienSuivi = (entretienSuivi) => {
        setEntretiensSuivis(entretiensSuivis.concat(entretienSuivi));
    };

    const openAddEntretienSuiviModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter entretienSuivi");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierEntretienSuiviModal = (entretienData) => {
        setModalEntretiensSelected(entretienData);
        setModalClasses("modal modal__active");
        setModalContent("modifier entretienSuivi");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editEntretienSuivi = (updatedEntretien) => {
        setEntretiensSuivis(entretiensSuivis.map((entretien) => {
            if (entretien._id === updatedEntretien._id) {
                return updatedEntretien;
            }
            return entretien;
        }));
    };

    const deleteEntretienSuivi = (entretienId) => {
        setEntretiensSuivis(entretiensSuivis.filter(entretien => {
            return entretien._id !== entretienId;
        }));
    };

    const openSupprimerModalEntretien = (entretienData) => {
        setModalEntretiensSelected(entretienData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer entretienSuivi");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    //document search bar
    const [searchEntretienSuiviBarContent, setSearchEntretienSuiviBarContent] = useState(null);
    const onChangeSearchEntretienSuivi = (e) => {
        setSearchEntretienSuiviBarContent(e.target.value);
    };

    const entretienSuiviMatch = (entretien, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return true;
    };

    // SEARCH INPUT
    useEffect(() => {
        if (searchEntretienSuiviBarContent !== null && searchEntretienSuiviBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedEntretiensSuivis(entretiensSuivis.filter(entretien => {
                    return entretienSuiviMatch(entretien, searchEntretienSuiviBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedEntretiensSuivis(entretiensSuivis);
        }
    }, [entretiensSuivis, searchEntretienSuiviBarContent]);



    // ____________________________ //

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/vehicules").then((result) => {
            progressBarRef.current.complete();
            setVehicules(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
        axiosInstance.get("/vehicules/formOptions").then((result) => {
            let formatOptions = {};
            for (const option of result.data) {
                formatOptions[option.formName] = { options: option.options, _id: option._id };
            }
            setFormOptions(formatOptions);
        }).catch((err) => {
            showError(err);
        });
        axiosInstance.get("/entretienSuiviVehicule").then((result) => {
            setEntretiensSuivis(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const [searchBarContent, setSearchBarContent] = useState(null);
    const vehiculesMatch = (vehicule, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return (
            (vehicule.categorie !== null && getInsensitiveWorld(vehicule.categorie).includes(insensitiveSearch)) ||
            (vehicule.typeVehicule !== null && getInsensitiveWorld(vehicule.typeVehicule).includes(insensitiveSearch)) ||
            (vehicule.immatriculation !== null && getInsensitiveWorld(vehicule.immatriculation).includes(insensitiveSearch)) ||
            (vehicule.marque !== null && getInsensitiveWorld(vehicule.marque).includes(insensitiveSearch)) ||
            (vehicule.numeroParc !== null && vehicule.numeroParc.toString().includes(insensitiveSearch))
        );
    };

    const onChangeSearchVehicules = (e) => {
        setSearchBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(vehicules.filter(vehicule => {
                    return vehiculesMatch(vehicule, searchBarContent) && (filters.categorieFilter === "All" || vehicule.categorie === filters.categorieFilter) && (filters.typeVehiculeFilter === "All" || vehicule.typeVehicule === filters.typeVehiculeFilter);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(vehicules.filter(vehicule => {
                return (filters.categorieFilter === "All" || vehicule.categorie === filters.categorieFilter) && (filters.typeVehiculeFilter === "All" || vehicule.typeVehicule === filters.typeVehiculeFilter);
            }));
        }
    }, [vehicules, searchBarContent, filters]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalVehiculeSelected, setModalVehiculeSelected] = useState({});

    const openAddVehicules = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addVehicule = (vehicule) => {
        setVehicules(vehicules.concat(vehicule));
    };

    const openEditVehicule = (vehiculeData) => {
        setModalVehiculeSelected(vehiculeData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editVehicule = (vehiculePatch) => {
        setVehicules(vehicules.map((vehicule) => {
            if (vehicule._id === vehiculePatch._id) {
                return vehiculePatch;
            }
            return vehicule;
        }));
    };

    const openSupprimerVehiculeModal = (vehiculeData) => {
        setModalVehiculeSelected(vehiculeData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerVehicule = (vehiculeId) => {
        setVehicules(vehicules.filter(vehicule => {
            return vehicule._id !== vehiculeId;
        }));
    };

    const [insideNavigationPage, setInsideNavigationPage] = useState("tousLesVehicules");

    const addFormOptions = (formName, formOption) => {
        setFormOptions({ ...formOptions, [formName]: { ...formOptions[formName], options: formOptions[formName].options.concat(formOption) } });
    };




    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>{insideNavigationPage === "tousLesVehicules" ? "Liste des véhicules" : insideNavigationPage === "entretiensVehicules" ? "Contrôle/suivi véhicule" : "Export"}</h1>
                    {insideNavigationPage === "tousLesVehicules" && <button onClick={() => openAddVehicules()} className='btn btn-primary medium-btn'>+ Vehicule</button>}
                    {insideNavigationPage === "entretiensVehicules" && <button onClick={() => openAddEntretienSuiviModal()} className='btn btn-primary medium-btn'>+ Entretiens/Suivi</button>}
                </div>
                <Breadcrumb
                    links={[
                        { label: "Transport", link: "/transport" },
                        { label: "Véhicules", link: "/transport/vehicules" },
                    ]}
                />
                <InsideNavigationBar
                    activeValue={insideNavigationPage}
                    setActiveValue={(value) => setInsideNavigationPage(value)}
                    links={[
                        { label: "Véhicules", value: "tousLesVehicules" },
                        { label: "Contrôle/suivi", value: "entretiensVehicules" },
                        { label: "Export", value: "export" }
                    ]}
                />
                <Hider state={insideNavigationPage === "tousLesVehicules"}>
                    <>
                        <div className="filterContainer">
                            <SearchInput fullwidth={true} placeholder="Rechercher un véhicule" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchVehicules} />
                            <select value={filters.categorieFilter} className="new-default-input" onChange={(e) => setFilters({ ...filters, categorieFilter: e.target.value })} name="categorieVehiculeSelect" id="chantierStatut-select">
                                <option value="All">Tous les catégories</option>
                                {formOptions !== null && formOptions.categoriesVehiculeOptions.options.map((option) => {
                                    return (<option key={option} value={option}>{option}</option>);
                                })}
                            </select>
                            <select value={filters.typeVehiculeFilter} className="new-default-input" onChange={(e) => setFilters({ ...filters, typeVehiculeFilter: e.target.value })} name="typeVehiculeSelect">
                                <option value="All">Tous les types</option>
                                {formOptions !== null && formOptions.typesVehiculeOptions.options.map((option) => {
                                    return (<option key={option} value={option}>{option}</option>);
                                })}
                            </select>
                        </div>
                        <TableRender
                            columns={vehiculesColumns((vehiculeData) => openEditVehicule(vehiculeData), (vehiculeData) => openSupprimerVehiculeModal(vehiculeData), (link) => navigate(link))}
                            tableData={showedItems}
                            sortBy={[

                            ]}
                            pageName="GestionVehicules"
                        />
                    </>
                </Hider>
                <Hider state={insideNavigationPage === "entretiensVehicules"}>
                    <>
                        <div className='filterContainer mgT-m1'>
                            <SearchInput placeholder="Rechercher un entretien, suivi" searchContent={searchEntretienSuiviBarContent ? searchEntretienSuiviBarContent : ""} onChangeSearch={onChangeSearchEntretienSuivi} />
                        </div>
                        <TableRender
                            columns={getEntretiensSuiviColumns((entretienData) => openModifierEntretienSuiviModal(entretienData), (entretienData) => openSupprimerModalEntretien(entretienData))}
                            tableData={showedEntretiensSuivis}
                            sortBy={[

                            ]}
                            pageName="gestionentretienSuiviVehicule"
                        />
                    </>
                </Hider>
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter"}>
                    <AjouterVehicule progressBarRef={progressBarRef} formOptions={formOptions} addVehicule={(vehicule) => addVehicule(vehicule)} addFormOptions={(formName, formOption) => addFormOptions(formName, formOption)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier"}>
                    <ModifierVehicule editVehicule={(vehiculeData) => editVehicule(vehiculeData)} progressBarRef={progressBarRef} vehiculeData={modalVehiculeSelected} formOptions={formOptions} addFormOptions={(formName, formOption) => addFormOptions(formName, formOption)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer"}>
                    <SupprimerModalForm destination="supprimerVehicule" vehiculeData={modalVehiculeSelected} supprimerVehicule={(vehiculeId) => supprimerVehicule(vehiculeId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "ajouter entretienSuivi"}>
                    <AjouterEntretienSuivi progressBarRef={progressBarRef} addEntretienSuivi={(entretien) => addEntretienSuivi(entretien)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier entretienSuivi"}>
                    <ModifierEntretienSuivi editEntretienSuivi={((entretien) => editEntretienSuivi(entretien))} entretienData={modalEntretiensSelected} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer entretienSuivi"}>
                    <SupprimerModalForm destination="supprimerEntretienVehicule" entretienData={modalEntretiensSelected} deleteEntretienSuivi={(entretienId) => deleteEntretienSuivi(entretienId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>
    );
};

export default VehiculeListPage;