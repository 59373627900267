import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import Breadcrumb from "../../components/ui/Breadcrumb";
import SearchInput from "../../components/ui/SearchInput";
import { showError } from "../../utils/ErrorHelper";
import axiosInstance from "../../utils/axiosInstance";
import BasicTableRender from "../../components/tables/Basic/BasicTableRender";
import { getIntervenantsTiersColumns } from "../../components/tables/Basic/BasicTableColumns";
import Hider from 'react-hider';
import AjouterIntervenant from "./AjouterIntervenant";
import Modal from "../../components/Modal";
import ModifierIntervenant from "./ModifierIntervenant";
import SupprimerModalForm from "../../components/forms/SupprimerModalForm";

const IntervenantsTiers = (props) => {
    const navigate = useNavigate();
    const [intervenantsTiers, setIntervenantsTiers] = useState([]);
    const [filterTable, setFilterTable] = useState(false);
    const [searchBarContent, setSearchBarContent] = useState(null);
    const [categorieIntervenants, setCategorieIntervenants] = useState([]);
    const [modalIntervenantSelected, setModalIntervenantSelected] = useState({});

    const ajouterCategorieIntervenants = (intervenant) => {
        setCategorieIntervenants(categorieIntervenants.concat(intervenant));
    };
    const onChangeSearch = (e) => {
        setSearchBarContent(e.target.value);
    };

    const progressBarRef = useRef(null);

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTable(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTable(true);
        }
    }, [intervenantsTiers, searchBarContent]);

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    // const [modalZoneSelected, setModalZoneSelected] = useState({});

    const openAjouterIntervenantsTiersModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const ajouterIntervenantsTiers = (intervenant) => {
        setIntervenantsTiers(intervenantsTiers.concat(intervenant));
    };

    const openModifierIntervenantsTiersModal = (intervenantData) => {
        setModalIntervenantSelected(intervenantData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const modifierIntervenantsTiers = (intervenantUpdated) => {
        setIntervenantsTiers(intervenantsTiers.map((intervenant) => {
            if (intervenant._id === intervenantUpdated._id) {
                return intervenantUpdated;
            }
            return intervenant;
        }));
    };

    const openSupprimerIntervenantsTiersModal = (intervenantData) => {
        setModalIntervenantSelected(intervenantData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerIntervenantsTiers = (intervenantId) => {
        setIntervenantsTiers(intervenantsTiers.filter((intervenant) => intervenant._id !== intervenantId));
    };

    // load intervenants
    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/intervenantsTiers").then((result) => {
            progressBarRef.current.complete();
            setIntervenantsTiers(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });

        axiosInstance.get("/options?categorieOptions=categorieTiers").then((result) => {
            setCategorieIntervenants(result.data.options);
        }).catch((err) => {
            showError(err);
        });
    }, []);


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Intervenants / Tiers</h1>
                    <button onClick={() => openAjouterIntervenantsTiersModal()} className="btn btn-primary medium-btn">+ Intervenant</button>
                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Intervenants / Tiers", link: "/amiante/intervenants" }
                    ]}
                />
                <div className="filterContainer mgT-s3">
                    <SearchInput fullwidth={true} placeholder="Rechercher un intervenant" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearch} />
                </div>
                <BasicTableRender
                    columns={getIntervenantsTiersColumns((intervenantData) => openModifierIntervenantsTiersModal(intervenantData), (intervenantData) => openSupprimerIntervenantsTiersModal(intervenantData))}
                    tableData={intervenantsTiers}
                    sortBy={[
                    ]}
                    filterTable={filterTable}
                    setFilterTable={setFilterTable}
                    searchBarContent={searchBarContent}
                />
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter"}>
                    <AjouterIntervenant ajouterCategorieIntervenants={(intervenant) => ajouterCategorieIntervenants(intervenant)} categorieIntervenants={categorieIntervenants} ajouterIntervenantsTiers={(intervenant) => ajouterIntervenantsTiers(intervenant)} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier"}>
                    <ModifierIntervenant categorieIntervenants={categorieIntervenants} ajouterIntervenantsTiers={(intervenant) => ajouterIntervenantsTiers(intervenant)} modifierIntervenantsTiers={(intervenantUpdated) => modifierIntervenantsTiers(intervenantUpdated)} intervenantSelected={modalIntervenantSelected} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer"}>
                    <SupprimerModalForm destination="supprimerIntervenant" supprimerIntervenantsTiers={(intervenantId) => supprimerIntervenantsTiers(intervenantId)} intervenantSelected={modalIntervenantSelected} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>

            </Modal>
        </>
    );
};

export default IntervenantsTiers;
