import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from '../../utils/GeneralHelper';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import Hider from "react-hider";
import { format } from 'date-fns';
import SearchInput from '../../components/ui/SearchInput';
import { Icon } from '@iconify/react';
import TableRender from '../../components/tables/TableRender';
import { getDocumentsMaterielsColumns, getHistoriqueControlesColumns } from '../../components/tables/TableColumns';
import Modal from '../../components/Modal';
import AjouterDocumentMateriel from '../../components/forms/AmianteForms/AjouterDocumentMateriel';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ModifierDocumentMateriel from '../../components/forms/AmianteForms/ModifierDocumentMateriel';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import AjouterControleForm from '../../components/forms/AjouterControle';
import ModifierControleForm from '../../components/forms/ModifierControle';
import EntretienEquipementList from '../../components/EntretienEquipementList';

const ProfilMateriel = () => {
    let { idMateriel } = useParams();
    const progressBarRef = useRef(null);
    const [materiel, setMateriel] = useState(null);
    const [showedDocuments, setShowedDocuments] = useState([]);
    const [showedControles, setShowedControles] = useState([]);
    const [insideNavigationPage, setInsideNavigationPage] = useState("documents");

    //document search bar
    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchDocument = (e) => {
        setSearchBarContent(e.target.value);
    };

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/equipementEPC/${idMateriel}`).then((result) => {
            progressBarRef.current.complete();
            setMateriel(result.data);
            console.log(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, [idMateriel]);

    useEffect(() => {
        if (materiel) {
            if (searchBarContent !== null && searchBarContent !== "") {
                let timer = setTimeout(() => {
                    setShowedDocuments(materiel.documents.filter(doc => {
                        return getInsensitiveWorld(doc.titre).includes(getInsensitiveWorld(searchBarContent));
                    }));
                }, 1000);
                return () => {
                    clearTimeout(timer);
                };
            }
            else {
                setShowedDocuments(materiel.documents);
            }
        }

    }, [materiel, searchBarContent]);

    useEffect(() => {
        if (materiel) {
            setShowedControles(materiel.historique_controles);
        }
    }, [materiel]);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            //open modal
            openAddDocumentMaterielModal(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    // GESTION MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalDocumentSelected, setModalDocumentSelected] = useState({});
    const [modalControleSelected, setModalControleSelected] = useState({});

    const addDocumentMateriel = (doc) => {
        setMateriel({ ...materiel, documents: materiel.documents.concat(doc) });
    };

    const openAddDocumentMaterielModal = (documentData) => {
        setModalDocumentSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editDocumentMateriel = (doc) => {
        setMateriel({
            ...materiel, documents: materiel.documents.map((document) => {
                if (document._id === doc._id) {
                    return doc;
                }
                else {
                    return document;
                }
            })
        });
    };

    const openEditDocument = (documentData) => {
        setModalDocumentSelected(documentData);
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent("modifier");
    };

    const deleteDocumentMateriel = (documentId) => {
        setMateriel({
            ...materiel, documents: materiel.documents.filter(doc => {
                return doc._id !== documentId;
            })
        });
    };

    const openSupprimerDocument = (documentData) => {
        setModalDocumentSelected(documentData);
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent("supprimer");
    };

    //modal controles
    const openAjouterControle = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        // setModalContent(<AjouterControleForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
        setModalContent("ajouterControle");
    };
    const openModifierControle = (data) => {
        setModalControleSelected(data);
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        // setModalContent(<AjouterControleForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
        setModalContent("modifierControle");
    };
    const openSupprimerControle = (data) => {
        setModalControleSelected(data);
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        // setModalContent(<AjouterControleForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
        setModalContent("supprimerControle");
    };

    const addControleMateriel = (controles) => {

        setMateriel({ ...materiel, historique_controles: materiel.historique_controles.concat(controles) });

    };
    const modifyControleMateriel = (toBeModified, modifyBy) => {
        const toBM_conv = new Date(toBeModified);

        console.log(materiel);
        setMateriel({
            ...materiel, historique_controles: materiel.historique_controles.map((date) => {
                const date_conv = new Date(date);
                if (date_conv.getTime() === toBM_conv.getTime()) {
                    return modifyBy;
                } else {
                    return date;
                }
            })
        });
    };
    const deleteControleMateriel = (toBeDeleted) => {
        const toBD_conv = new Date(toBeDeleted);
        console.log(toBD_conv.getTime());

        setMateriel({
            ...materiel, historique_controles: materiel.historique_controles.filter(controle => {
                return new Date(controle).getTime() !== toBD_conv.getTime();
            })
        });
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {materiel !== null &&
                <div className="section">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <h3>{materiel.nom} n°{materiel.numero_de_serie}</h3>
                        <button onClick={() => openAjouterControle()} className='btn btn-primary medium-btn'>+ Controle</button>
                    </div>
                    <Breadcrumb
                        links={[
                            { label: "Gestion des données", link: "/gestion-de-donnees/" },
                            { label: "Suivi matériel", link: "/gestion-de-donnees/suivi-materiel" },
                            { label: materiel._id, link: `/gestion-de-donnees/suivi-materiel/profil/${materiel._id}` },
                        ]}
                    />

                    <InsideNavigationBar
                        activeValue={insideNavigationPage}
                        setActiveValue={(value) => setInsideNavigationPage(value)}
                        links={[
                            { label: "Documents", value: "documents" },
                            { label: "Historique des controles", value: "dateControles" },
                            { label: "Entretiens", value: "entretiens" },
                        ]}
                    />
                    <Hider state={insideNavigationPage === "documents"}>
                        <>
                            <div className="container-basic mgT-s3">
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: "flex" }}>
                                            {materiel.date_dernier_controle && <p className='materiel-info-label'>Date du dernier controle:</p>}
                                            {materiel.date_dernier_controle && <p style={{ fontWeight: 700 }}>{format(new Date(materiel.date_dernier_controle), "dd/MM/yyyy")}</p>}
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <p className='materiel-info-label'>
                                                Marque:
                                            </p>
                                            <p style={{ fontWeight: 700 }}>
                                                {materiel.marque}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: "flex" }}>
                                            {materiel.date_dernier_controle && <p className='materiel-info-label'>Date du premier controle:</p>}
                                            {materiel.date_prochain_controle && <p style={{ fontWeight: 700 }}>{format(new Date(materiel.date_prochain_controle), "dd/MM/yyyy")}</p>}
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <p className='materiel-info-label'>
                                                Référence interne:
                                            </p>
                                            <p style={{ fontWeight: 700 }}>
                                                {materiel.ref_interne}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filterContainer mgT-s3 mgB-s3">
                                <SearchInput fullwidth={true} placeholder="Rechercher un document" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchDocument} />
                            </div>
                            <div>
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                    <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                </div>
                            </div>
                            <TableRender
                                columns={getDocumentsMaterielsColumns((doc) => openEditDocument(doc), (doc) => openSupprimerDocument(doc))}
                                tableData={showedDocuments}
                                sortBy={[

                                ]}
                                pageName="ProfilMaterielDocuments"
                            />

                        </>

                    </Hider>
                    <Hider state={insideNavigationPage === "dateControles"}>
                        <>
                            <div className="container-basic mgT-s3 mgB-s3">
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: "flex" }}>
                                            {materiel.date_dernier_controle && <p className='materiel-info-label'>Date du dernier controle:</p>}
                                            {materiel.date_dernier_controle && <p style={{ fontWeight: 700 }}>{format(new Date(materiel.date_dernier_controle), "dd/MM/yyyy")}</p>}
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <p className='materiel-info-label'>
                                                Marque:
                                            </p>
                                            <p style={{ fontWeight: 700 }}>
                                                {materiel.marque}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: "flex" }}>
                                            {materiel.date_dernier_controle && <p className='materiel-info-label'>Date du premier controle:</p>}
                                            {materiel.date_prochain_controle && <p style={{ fontWeight: 700 }}>{format(new Date(materiel.date_prochain_controle), "dd/MM/yyyy")}</p>}
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <p className='materiel-info-label'>
                                                Référence interne:
                                            </p>
                                            <p style={{ fontWeight: 700 }}>
                                                {materiel.ref_interne}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TableRender
                                columns={getHistoriqueControlesColumns((doc) => openModifierControle(doc), (doc) => openSupprimerControle(doc))}
                                tableData={showedControles}
                                sortBy={[
                                    {
                                        id: 'dateControle',
                                        desc: false,
                                    }
                                ]}
                                pageName="ProfilMaterielControles"
                            />
                        </>

                    </Hider>
                    <Hider state={insideNavigationPage === "entretiens"}>
                        <EntretienEquipementList></EntretienEquipementList>

                    </Hider>
                </div>
            }
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter"}>
                    <AjouterDocumentMateriel materielId={idMateriel} progressBarRef={progressBarRef} addDocumentMateriel={(doc) => addDocumentMateriel(doc)} documentData={modalDocumentSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier"}>
                    <ModifierDocumentMateriel editDocumentMateriel={(doc) => editDocumentMateriel(doc)} startProgress={() => progressBarRef.current.continuousStart()} completeProgress={() => progressBarRef.current.complete()} materielId={idMateriel} documentData={modalDocumentSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer"}>
                    <SupprimerModalForm destination="supprimerDocumentMateriel" deleteDocumentMateriel={(documentId) => deleteDocumentMateriel(documentId)} documentData={modalDocumentSelected} materielId={idMateriel} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "ajouterControle"}>
                    <AjouterControleForm materielId={idMateriel} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} addControleMateriel={(controles) => addControleMateriel(controles)} />
                </Hider>
                <Hider state={modalContent === "modifierControle"}>
                    <ModifierControleForm materielId={idMateriel} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} controleData={modalControleSelected} modifyControleMateriel={(toBeModified, modifiedBy) => modifyControleMateriel(toBeModified, modifiedBy)} />
                </Hider>

                <Hider state={modalContent === "supprimerControle"}>
                    <SupprimerModalForm destination="supprimerControleEPC" controleData={modalControleSelected} materielId={idMateriel} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} deleteControleMateriel={(controles) => deleteControleMateriel(controles)} />
                </Hider>
            </Modal>
        </>
    );
};

export default ProfilMateriel;