import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import TableRender from '../../components/tables/TableRender';
import { getEntretiensEquipementColumns } from '../../components/tables/TableColumns';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import Modal from '../../components/Modal';
import Hider from 'react-hider';
import AjouterEntretienForm from '../../components/forms/AmianteForms/AjouterEntretienEquipement';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ModifierEntretien from '../../components/forms/AmianteForms/ModifierEntretien';
import { useNavigate } from 'react-router-dom';

const EntretienEquipement = () => {
    const progressBarRef = useRef(null);
    const navigate = useNavigate();
    const [entretiens, setEntretiens] = useState([]);
    const [showedEntretien, setShowedEntretien] = useState([]);

    // loadData
    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/entretienEquipement").then((result) => {
            progressBarRef.current.complete();

            setEntretiens(result.data);

        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    }, []);

    //document search bar
    const [searchEntretienBarContent, setSearchEntretienBarContent] = useState(null);
    const suiviEntretienMatch = (entretien, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(entretien.nomEntretien).includes(insensitiveSearch);
    };

    useEffect(() => {
        if (searchEntretienBarContent !== null && searchEntretienBarContent !== "") {

            let timer = setTimeout(() => {
                setShowedEntretien(entretiens.filter(entretien => {
                    return suiviEntretienMatch(entretien, searchEntretienBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedEntretien(entretiens);

        }

    }, [entretiens, searchEntretienBarContent]);

    const onChangeSearchEntretien = (e) => {
        setSearchEntretienBarContent(e.target.value);
    };


    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalEntretienSelected, setModalEntretienSelected] = useState({});

    const openAddEntretienModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addEntretien = (entretien) => {
        setEntretiens(entretiens.concat(entretien));
    };

    const openSupprimerModal = (entretienData) => {
        setModalEntretienSelected(entretienData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const deleteEntretien = (entretienId) => {
        setEntretiens(entretiens.filter(entretien => {
            return entretien._id !== entretienId;
        }));
    };

    const openModifierEntretienModal = (entretienData) => {
        setModalEntretienSelected(entretienData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editEntretien = (entretien) => {

        setEntretiens(entretiens.map((ent) => {
            if (ent._id === entretien.data._id) {
                return entretien.data;
            }
            return ent;
        }));


    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Entretiens du matériel</h1>
                    <button onClick={() => openAddEntretienModal()} className='btn btn-primary medium-btn' type="button">+ Entretien</button>

                </div>
                <Breadcrumb
                    links={[
                        { label: "amiante", link: "/amiante" },
                        { label: "Entretiens du matériel", link: "/amiante/suivi-entretiens" },
                    ]}
                />

                <div className='filterContainer mgT-m1'>
                    <SearchInput placeholder="Rechercher un entretien" searchContent={searchEntretienBarContent ? searchEntretienBarContent : ""} onChangeSearch={onChangeSearchEntretien} />
                </div>
                <TableRender
                    columns={
                        getEntretiensEquipementColumns((entretienData) => openSupprimerModal(entretienData), (entretienData) => openModifierEntretienModal(entretienData))
                    }
                    tableData={showedEntretien}
                    sortBy={[

                    ]}
                    pageName="gestionSuiviEntretiens"
                />
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter"}>
                    <AjouterEntretienForm progressBarRef={progressBarRef} addEntretien={(entretien) => addEntretien(entretien)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier"}>
                    <ModifierEntretien key={modalEntretienSelected._id} progressBarRef={progressBarRef} editEntretien={(entretien) => editEntretien(entretien)} entretienData={modalEntretienSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer"}>
                    <SupprimerModalForm key={modalEntretienSelected._id} destination="supprimerEntretienEquipement" entretienData={modalEntretienSelected} deleteEntretien={(entretien) => deleteEntretien(entretien)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>
    );
};

export default EntretienEquipement;