import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import LoadingBar from 'react-top-loading-bar';
import { showError } from '../../utils/ErrorHelper';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import Breadcrumb from '../../components/ui/Breadcrumb';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import SearchInput from '../../components/ui/SearchInput';
import TableRender from '../../components/tables/TableRender';
import { getChantierColumns, getChantiersAmianteColumns, getGestionDocumentsChantiersColumns } from '../../components/tables/TableColumns';
import Hider from 'react-hider';
import AjouterChantierAmianteForm from '../../components/forms/GestionsForms/AjouterChantierAmianteForm';
import Modal from '../../components/Modal';
import ModifierChantierAmianteForm from '../../components/forms/GestionsForms/ModifierChantierAmianteForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from '../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import AjouterDocumentGestionDoe from '../../components/forms/AmianteForms/AjouterDocumentGestionDoe';
import ModifierDocumentGestionDoe from '../../components/forms/AmianteForms/ModifierDocumentGestionDoe';



const GestionChantierAmiante = () => {
    const navigate = useNavigate();
    const [chantiersAmiante, setChantiersAmiante] = useState([]);
    const [zoneChantiers, setZoneChantiers] = useState([]);
    const [showedItems, setShowedItems] = useState([]);
    const progressBarRef = useRef(null);


    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/chantiersAmiante").then((result) => {
            progressBarRef.current.complete();
            setChantiersAmiante(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });

        axiosInstance.get("/zones").then((res) => {
            setZoneChantiers(res.data);
        }).catch((error) => {
            showError(error);
        });
    }, []);

    //document search bar
    const [searchBarContent, setSearchBarContent] = useState(null);
    const chantierAmiantesMatch = (chantier, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(chantier._id.libelle_lieu).includes(insensitiveSearch) || getInsensitiveWorld(chantier.codeChantier).includes(insensitiveSearch);
    };

    const onChangeSearchChantier = (e) => {
        setSearchBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(chantiersAmiante.filter(chantier => {
                    return chantierAmiantesMatch(chantier, searchBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(chantiersAmiante);
        }
    }, [chantiersAmiante, searchBarContent]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalChantierSelected, setModalChantierSelected] = useState({});

    const openAddChantier = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addChantier = (chantier) => {
        setChantiersAmiante(chantiersAmiante.concat(chantier));
    };

    const openModifyChantier = (chantierData) => {
        setModalChantierSelected(chantierData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editChantier = (chantierPatch) => {
        setChantiersAmiante(chantiersAmiante.map((chantier) => {
            if (chantier._id._id === chantierPatch._id._id) {
                return chantierPatch;
            }
            return chantier;
        }));
    };

    const openArchiverChantier = (chantierData) => {
        setModalChantierSelected(chantierData);
        setModalClasses("modal modal__active");
        setModalContent("archiver");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const archiverChantier = (chantierId) => {
        setChantiersAmiante(chantiersAmiante.filter(chantier => {
            return chantierId !== chantier._id._id;
        }));
    };

    const [insideNavigationPage, setInsideNavigationPage] = useState("tousLesChantiers");

    // DOE
    const [documentsDOE, setDocumentsDOE] = useState([]);
    const [modalDocumentDoeSelected, setModalDocumentDoeSelected] = useState([]);

    // load documents
    useEffect(() => {
        axiosInstance.get("/uploads/gestionDocumentsChantiers")
            .then((result) => {
                setDocumentsDOE(result.data);
            })
            .catch((err) => {
                showError(err);
            });
    }, []);

    const [searchBarDOE, setSearchBarDOE] = useState(null);
    const onChangeSearchDOE = (e) => {
        setSearchBarDOE(e.target.value);
    };

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            //open modal
            openAjouterDocumentDoeModal(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const openAjouterDocumentDoeModal = (documentData) => {
        setModalDocumentDoeSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("ajouter doc doe");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const ajouterDocumentDoe = (document) => {
        setDocumentsDOE(documentsDOE.concat(document));
    };

    const openSupprimerDocumentDoeModal = (documentData) => {
        setModalDocumentDoeSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer doc doe");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerDocumentDoe = (docId) => {
        setDocumentsDOE(documentsDOE.filter(document => {
            return document.baseDocument._id !== docId;
        }));
    };

    const openModifierDocumentDoe = (documentData) => {
        setModalDocumentDoeSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("modifier doc doe");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const modifierDocumentDoe = (document) => {
        setDocumentsDOE(documentsDOE.map((doc) => {
            if (document._id === doc._id) {
                return document;
            }
            return doc;
        }));
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>{insideNavigationPage === "tousLesChantiers" ? "Chantiers amiante" : insideNavigationPage === "gestionsDOE" ? "Gestion du DOE" : "Export"}</h1>
                    {insideNavigationPage === "tousLesChantiers" && <button onClick={() => openAddChantier()} className='btn btn-primary medium-btn'>+ Chantier</button>}
                </div>
                <Breadcrumb
                    links={[
                        { label: "amiante", link: "/amiante" },
                        { label: "Chantiers amiante", link: "/amiante/chantiers" },
                    ]}
                />
                <InsideNavigationBar
                    activeValue={insideNavigationPage}
                    setActiveValue={(value) => setInsideNavigationPage(value)}
                    links={[
                        { label: "Chantiers amiante", value: "tousLesChantiers" },
                        { label: "Gestion du DOE", value: "gestionsDOE" },
                        { label: "Export", value: "export" }
                    ]}
                />
                <Hider state={insideNavigationPage === "tousLesChantiers"}>
                    <>
                        <div className="filterContainer">
                            <SearchInput fullwidth={true} placeholder="Rechercher un chantier" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchChantier} />
                        </div>
                        <TableRender
                            columns={getChantiersAmianteColumns((chantierData) => openModifyChantier(chantierData), (chantierData) => openArchiverChantier(chantierData), (link) => navigate(link))}
                            tableData={showedItems}
                            sortBy={[
                                {
                                    id: '_id.libelle_lieu',
                                    desc: false
                                }
                            ]}
                            pageName="GestionChantiersAmiante"
                        />
                    </>
                </Hider>
                <Hider state={insideNavigationPage === "gestionsDOE"}>
                    <>
                        <div className="filterContainer mgT-s3 mgB-s3">
                            <SearchInput fullwidth={true} placeholder="Rechercher un document" searchContent={searchBarDOE ? searchBarDOE : ""} onChangeSearch={onChangeSearchDOE} />
                        </div>
                        <div>
                            <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                            </div>
                        </div>
                        <TableRender
                            columns={getGestionDocumentsChantiersColumns((doc) => openModifierDocumentDoe(doc), (doc) => openSupprimerDocumentDoeModal(doc))}
                            tableData={documentsDOE}
                            sortBy={[
                                {
                                    id: 'libelleDocument',
                                    desc: false
                                }
                            ]}
                            pageName="GestionChantiersAmianteDoe"
                        />
                    </>
                </Hider>
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter"}>
                    <AjouterChantierAmianteForm progressBarRef={progressBarRef} zoneChantiers={zoneChantiers} addChantier={(chantier) => addChantier(chantier)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier"}>
                    <ModifierChantierAmianteForm editChantier={(chantier) => editChantier(chantier)} progressBarRef={progressBarRef} zoneChantiers={zoneChantiers} modalChantierSelected={modalChantierSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "archiver"}>
                    <SupprimerModalForm destination="archiverChantierAmiante" modalChantierSelected={modalChantierSelected} archiverChantier={(chantierId) => archiverChantier(chantierId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "ajouter doc doe"}>
                    <AjouterDocumentGestionDoe progressBarRef={progressBarRef} ajouterDocumentDoe={(doc) => ajouterDocumentDoe(doc)} documentData={modalDocumentDoeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier doc doe"}>
                    <ModifierDocumentGestionDoe progressBarRef={progressBarRef} modifierDocumentDoe={(doc) => modifierDocumentDoe(doc)} documentData={modalDocumentDoeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer doc doe"}>
                    <SupprimerModalForm destination="supprimerGestionsDocumentDoe" documentData={modalDocumentDoeSelected} supprimerDocumentDoe={(docummentId) => supprimerDocumentDoe(docummentId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>

    );
};

export default GestionChantierAmiante

