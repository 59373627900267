import * as actionTypes from '../actions/actionTypes';
import { getMaxWeekInYear, getWeekNumber } from '../../utils/DateHelper';
import { getISOWeeksInYear, getYear } from 'date-fns';

const currentWeekState = {
    weekNumber: getWeekNumber(new Date()),
    year: {
        yearNumber: getYear(new Date()),
        maxWeekInYear: getMaxWeekInYear(new Date().getFullYear())
    }
};

const reducer = (state = currentWeekState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_CURRENT_WEEK:
            return { ...state, year: { ...state.year, yearNumber: action.yearNumber, maxWeekInYear: action.maxWeekInYear }, weekNumber: action.currentWeekNumber };
        case actionTypes.RESET_CURRENT_WEEK:
            return {
                weekNumber: getWeekNumber(new Date()),
                year: {
                    yearNumber: getYear(new Date()),
                    maxWeekInYear: getMaxWeekInYear(new Date().getFullYear())
                }
            };
        default:
            return state;
    }
};

export default reducer;
