import React, { useEffect, useState, useMemo } from "react";
import SearchInput from "../../../../components/ui/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, getInsensitiveWorld, rejectStyle } from "../../../../utils/GeneralHelper";
import { Icon } from "@iconify/react";
import Modal from "../../../../components/Modal";
import AjouterFichierModal from "../../../../components/ui/AjouterFichierModal";
import Hider from 'react-hider';
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import BasicTableRender from "../../../../components/tables/Basic/BasicTableRender";
import { getFichiersChantierColumns } from "../../../../components/tables/Basic/BasicTableColumns";
import SupprimerModalForm from "../../../../components/forms/SupprimerModalForm";
import { useDispatch, useSelector } from "react-redux";
import { getEspaceDocumentaireChantier } from "../../../../store/actions/FichiersAct";
import { setUploadsFiles } from "../../../../store/actions/uploadFilesAct";
import ModifierFichierModal from "../../../../components/ui/ModifierFichierModal";

const ProfilChantierAmiante_EspaceDocumentaire = (props) => {

    const dispatch = useDispatch();
    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearch = (e) => {
        setSearchBarContent(e.target.value);
    };
    const [filterTable, setFilterTable] = useState(false);
    const fichiersChantier = useSelector(state => state.fichiers.fichiersChantier);
    const loading = useSelector(state => state.user.loadingSpinner);

    useEffect(() => {
        dispatch(getEspaceDocumentaireChantier(props.idChantier));
    }, []);

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTable(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTable(true);
        }
    }, [fichiersChantier, searchBarContent]);

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [fichierSupprimerSelected, setFichierSupprimerSelected] = useState({});
    const [fichierModifierSelected, setFichierModifierSelected] = useState({});



    const [currentLayout, setCurrentLayout] = useState("list");

    const openAjouterFichierModal = (filesData) => {
        dispatch(setUploadsFiles(filesData));
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        multiple: true,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': [],
            // 'application/zip': [],
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            if (modalClasses !== "modal modal__active") {
                openAjouterFichierModal(acceptedFiles);
            }
            else {
                dispatch(setUploadsFiles(acceptedFiles));
            }
        }
    }, [acceptedFiles]);

    const openSupprimerFichierModal = (fichier) => {
        setModalContent("supprimer");
        setModalClasses("modal modal__active");
        setFichierSupprimerSelected(fichier);
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierFichierModal = (fichier) => {
        setModalContent("modifier");
        setModalClasses("modal modal__active");
        setFichierModifierSelected(fichier);
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const viewFile = (fichierId) => {
        axiosInstance.get(`/fichiersChantiers/${fichierId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    return <>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>

            <div className='filterContainer'>
                <div style={{ flex: 1, display: "flex" }}>
                    <div className="container-basic filter mgR-s2" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1.2rem" }}>
                        <FontAwesomeIcon icon={faList} style={{ fontSize: "2.2rem", opacity: currentLayout === "list" ? 1 : 0.5, cursor: "pointer" }} onClick={() => setCurrentLayout("list")} />
                        <FontAwesomeIcon icon={faGrid2} style={{ fontSize: "2.2rem", opacity: currentLayout === "grid" ? 1 : 0.5, cursor: "pointer" }} onClick={() => setCurrentLayout("grid")} />
                    </div>
                    <SearchInput style={{ flex: 1 }} placeholder="Rechercher un fichier" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearch} />
                </div>
                <button className='btn btn-primary' onClick={open}>
                    + Ajouter des fichiers
                </button>
            </div>

            {loading ? <></>
                :
                <>
                    {currentLayout === "grid" &&
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            {fichiersChantier.length ?
                                <div className="grid-container">

                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                    <p className='dropzone-text'>Aucun fichier dans l'espace document. <span>Glissez des fichiers dans la zone pour en ajouter</span></p>
                                </div>
                            }
                        </div>
                    }
                    {
                        currentLayout === "list" &&
                        <BasicTableRender
                            columns={getFichiersChantierColumns((fichier) => openSupprimerFichierModal(fichier), (fichier) => openModifierFichierModal(fichier), (fichierId) => viewFile(fichierId))}
                            tableData={fichiersChantier}
                            sortBy={[
                            ]}
                            filterTable={filterTable}
                            setFilterTable={setFilterTable}
                            searchBarContent={searchBarContent}
                        />
                    }
                </>
            }
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter"}>
                    <AjouterFichierModal uploadLink={`/fichiersChantiers/espaceDocumentaire/${props.idChantier}`} initialValues={{}} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer"}>
                    <SupprimerModalForm destination="supprimerFichiersChantierEspaceDocumentaire" fichiersSupprimer={fichierSupprimerSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier"}>
                    <ModifierFichierModal uploadLink={`/fichiersChantiers/espaceDocumentaire/${props.idChantier}`} fichier={fichierModifierSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </div>
    </>;
};

export default ProfilChantierAmiante_EspaceDocumentaire;
