import React, { useEffect, useRef, useState, useMemo } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import { useParams } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import * as yup from "yup";
import { useFormik } from 'formik';
import Breadcrumb from '../../components/ui/Breadcrumb';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import Hider from "react-hider";
import Select from 'react-select/creatable';
import customToast from '../../utils/ToastifyHelper';
import SearchInput from '../../components/ui/SearchInput';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from '../../utils/GeneralHelper';
import Modal from '../../components/Modal';
import { Icon } from '@iconify/react';
import TableRender from '../../components/tables/TableRender';
import { getDocumentsVehiculeColumns } from '../../components/tables/TableColumns';
import AjouterDocumentVehicule from '../../components/forms/Vehicule/AjouterDocumentVehicule';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ModifierDocumentVehicule from '../../components/forms/Vehicule/ModifierDocumentVehicule';

const ProfilVehicule = (props) => {
    let { idVehicule } = useParams();
    const [vehicule, setVehicule] = useState(null);
    const [afficherProfilVehicule, setAfficherProfilVehicule] = useState("informationsGenerales");
    const [showedDocuments, setShowedDocuments] = useState([]);
    const [formOptions, setFormOptions] = useState(null);
    // document search bar
    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchDocument = (e) => {
        setSearchBarContent(e.target.value);
    };

    const progressBarRef = useRef(null);

    // handle form
    const formSchema = yup.object().shape({
        categorie: yup.string("Veuillez sélectionner la catégorie du véhicule"),
        typeVehicule: yup.string().required("Veuillez sélectionner le type du véhicule"),
        marque: yup.string("Veuillez sélectionner le type du véhicule"),
        immatriculation: yup.string().required("Veuillez saisir l'immatriculation du véhicule"),
        numeroParc: yup.number().integer("Veuillez saisir le numéro de parc du véhicule"),
        pav: yup.number("Veuillez sélectionner le pav du véhicule"),
        commentaire: yup.string(),
        particularite: yup.string(),
        pneus: yup.string(),
        jantes: yup.string(),
    });

    const onSubmit = (values, actions) => {
        let patchData = {
            categorie: values.categorie.length ? values.categorie : null,
            typeVehicule: values.typeVehicule.length ? values.typeVehicule : null,
            marque: values.marque.length ? values.marque : null,
            immatriculation: values.immatriculation.length ? values.immatriculation : null,
            numeroParc: values.numeroParc !== "" ? values.numeroParc : null,
            pav: values.pav !== "" ? values.pav : null,
            commentaire: values.commentaire.length ? values.commentaire : null,
            particularite: values.particularite.length ? values.particularite : null,
            pneus: values.pneus.length ? values.pneus : null,
            jantes: values.jantes.length ? values.jantes : null
        };
        setSubmitting(false);
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/vehicules/${vehicule._id}`, patchData)
            .then((result) => {
                progressBarRef.current.complete();
                customToast.success("Le véhicule a été modifié ave succès");
                setVehicule(result.data);
                if (patchData.categorie !== null && !formOptions.categoriesVehiculeOptions.options.includes(patchData.categorie)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${formOptions.categoriesVehiculeOptions._id}`, { option: patchData.categorie }).then(() => {
                        addFormOptions("categoriesVehiculeOptions", patchData.categorie);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                if (patchData.typeVehicule !== null && !formOptions.typesVehiculeOptions.options.includes(patchData.typeVehicule)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${formOptions.typesVehiculeOptions._id}`, { option: patchData.typeVehicule }).then(() => {
                        addFormOptions("typesVehiculeOptions", patchData.typeVehicule);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                if (patchData.pneus !== null && !formOptions.pneusVehiculeOptions.options.includes(patchData.pneus)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${formOptions.pneusVehiculeOptions._id}`, { option: patchData.pneus }).then(() => {
                        addFormOptions("pneusVehiculeOptions", patchData.pneus);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                if (patchData.jantes !== null && !formOptions.jantesVehiculeOptions.options.includes(patchData.jantes)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${formOptions.jantesVehiculeOptions._id}`, { option: patchData.jantes }).then(() => {
                        addFormOptions("jantesVehiculeOptions", patchData.jantes);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
            }).catch((err) => {
                actions.resetForm();
                progressBarRef.current.complete();
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            categorie: "",
            typeVehicule: "",
            marque: "",
            immatriculation: "",
            numeroParc: "",
            pav: "",
            commentaire: "",
            particularite: "",
            pneus: "",
            jantes: "",
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeCategorieVehicule = (opt) => {
        if (opt) {
            setFieldValue("categorie", opt.value);
        }
        else {
            setFieldValue("categorie", "");
        }
    };

    const onChangeTypeVehicule = (opt) => {
        if (opt) {
            setFieldValue("typeVehicule", opt.value);
        }
        else {
            setFieldValue("typeVehicule", "");
        }
    };

    const onChangePneuVehicule = (opt) => {
        if (opt) {
            setFieldValue("pneus", opt.value);
        }
        else {
            setFieldValue("pneus", "");
        }
    };

    const onChangeJanteVehicule = (opt) => {
        if (opt) {
            setFieldValue("jantes", opt.value);
        }
        else {
            setFieldValue("jantes", "");
        }
    };

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/vehicules/${idVehicule}`).then((result) => {
            progressBarRef.current.complete();
            setVehicule(result.data);
            if (result.data.categorie !== null) {
                setFieldValue("categorie", result.data.categorie);
            }
            if (result.data.typeVehicule !== null) {
                setFieldValue("typeVehicule", result.data.typeVehicule);
            }
            if (result.data.marque !== null) {
                setFieldValue("marque", result.data.marque);
            }
            if (result.data.immatriculation !== null) {
                setFieldValue("immatriculation", result.data.immatriculation);
            }
            if (result.data.numeroParc !== null) {
                setFieldValue("numeroParc", result.data.numeroParc);
            }
            if (result.data.pav !== null) {
                setFieldValue("pav", result.data.pav);
            }
            if (result.data.commentaire !== null) {
                setFieldValue("commentaire", result.data.commentaire);
            }
            if (result.data.particularite !== null) {
                setFieldValue("particularite", result.data.particularite);
            }
            if (result.data.pneus !== null) {
                setFieldValue("pneus", result.data.pneus);
            }
            if (result.data.jantes !== null) {
                setFieldValue("jantes", result.data.jantes);
            }
        }).catch((err) => {
            showError(err);
        });
        axiosInstance.get("/vehicules/formOptions").then((result) => {
            let formatOptions = {};
            for (const option of result.data) {
                formatOptions[option.formName] = { options: option.options, _id: option._id };
            }
            setFormOptions(formatOptions);
            console.log(formatOptions);
        }).catch((err) => {
            showError(err);
        });
    }, [idVehicule]);

    // documentSearchInput
    const addFormOptions = (formName, formOption) => {
        setFormOptions({ ...formOptions, [formName]: { ...formOptions[formName], options: formOptions[formName].options.concat(formOption) } });
    };

    // DOCUMENT SEARCH INPUT

    useEffect(() => {
        if (vehicule) {
            if (searchBarContent !== null && searchBarContent !== "") {
                let timer = setTimeout(() => {
                    setShowedDocuments(vehicule.documents.filter(doc => {
                        return doc.titre.toLowerCase().includes(searchBarContent.toLowerCase());
                    }));
                }, 1000);
                return () => {
                    clearTimeout(timer);
                };
            }
            else {
                setShowedDocuments(vehicule.documents);
            }
        }
    }, [vehicule, searchBarContent]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalDocumentVehiculeSelected, setModalDocumentVehiculeSelected] = useState({});

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            openAjouterDocument(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const openAjouterDocument = (documentData) => {
        setModalDocumentVehiculeSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("ajouterDocument");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addDocument = (document) => {
        setVehicule({
            ...vehicule,
            documents: vehicule.documents.concat(document)
        });
    };

    const openSupprimerDocument = (documentData) => {
        setModalDocumentVehiculeSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("supprimerDocument");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerDocument = (documentId) => {
        setVehicule({
            ...vehicule,
            documents: vehicule.documents.filter(item => {
                return documentId !== item._id;
            })
        });
    };

    const editDocumentVehicule = (doc) => {
        setVehicule({
            ...vehicule, documents: vehicule.documents.map((document) => {
                if (document._id === doc._id) {
                    return doc;
                }
                else {
                    return document;
                }
            })
        });
    };

    const openEditDocumentVehicule = (documentData) => {
        setModalDocumentVehiculeSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("modifierDocument");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {vehicule &&
                <div className="section">
                    <h1>{vehicule.categorie} {vehicule.immatriculation}</h1>
                    <Breadcrumb
                        links={[
                            { label: "Transport", link: "/transport/" },
                            { label: "Véhicules", link: "/transport/vehicules/" },
                            { label: "Profil", link: "transport/vehicules/profil/" },
                        ]}
                    />
                    <InsideNavigationBar
                        activeValue={afficherProfilVehicule}
                        setActiveValue={(value) => setAfficherProfilVehicule(value)}
                        links={[
                            { label: "Informations générales", value: "informationsGenerales" },
                            { label: "Documents", value: "documents" },
                            { label: "Entretiens/Suivis", value: "entretiens" },
                        ]}
                    />
                    <Hider state={afficherProfilVehicule === "informationsGenerales"}>
                        <>
                            <div className="profil-salarie-container">
                                <div className='container-basic salarie-info'>
                                    <h4 className='mgB-b'>Informations générales</h4>
                                    <form className='form' onSubmit={handleSubmit}>
                                        <div className='label-and-input'>
                                            <label htmlFor='categorie' className='form-label'>Catégorie du véhicule</label>
                                            {formOptions !== null &&
                                                <>
                                                    <Select
                                                        options={formOptions.categoriesVehiculeOptions.options.map((option) => {
                                                            return (
                                                                { label: option, value: option }
                                                            );
                                                        })}
                                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => onChangeCategorieVehicule(opt)}
                                                        placeholder="Sélectionner la catégorie du véhicule"
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                        isSearchable={true}
                                                        formatCreateLabel={(value) => `Ajouter ${value} à la catégorie du véhicule`}
                                                        isClearable={true}
                                                        defaultValue={vehicule.categorie !== null ? { label: vehicule.categorie, value: vehicule.categorie } : ""}
                                                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                                                    />
                                                    {errors.categorie && touched.categorie && <p className="form-error-message">{errors.categorie}</p>}
                                                </>
                                            }
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='typeVehicule' className='form-label'>Type de véhicule</label>
                                            {formOptions !== null &&
                                                <>
                                                    <Select
                                                        options={formOptions.typesVehiculeOptions.options.map((option) => {
                                                            return (
                                                                { label: option, value: option }
                                                            );
                                                        })}
                                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => onChangeTypeVehicule(opt)}
                                                        placeholder="Sélectionner le type du véhicule"
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        formatCreateLabel={(value) => `Ajouter ${value} au type du véhicule`}
                                                        defaultValue={{ label: vehicule.typeVehicule, value: vehicule.typeVehicule }}
                                                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                                                    />
                                                    {errors.typeVehicule && touched.typeVehicule && <p className="form-error-message">{errors.typeVehicule}</p>}
                                                </>
                                            }
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='marque' className='form-label'>Marque du véhicule</label>
                                            <input name='marque' className={`new-default-input${errors.marque && touched.marque ? " input-error" : ""}`} type="text" placeholder="Marque du véhicule" value={values.marque} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.marque && touched.marque && <p className="form-error-message">{errors.marque}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='immatriculation' className='form-label'>Immatriculation du véhicule</label>
                                            <input name='immatriculation' className={`new-default-input${errors.immatriculation && touched.immatriculation ? " input-error" : ""}`} type="text" placeholder="Immatriculation du véhicule" value={values.immatriculation} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.immatriculation && touched.immatriculation && <p className="form-error-message">{errors.immatriculation}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='numeroParc' className='form-label'>Numéro de parc</label>
                                            <input name='numeroParc' className={`new-default-input${errors.numeroParc && touched.numeroParc ? " input-error" : ""}`} type="number" placeholder="Numéro de parc du véhicule" value={values.numeroParc} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.numeroParc && touched.numeroParc && <p className="form-error-message">{errors.numeroParc}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='pav' className='form-label'>Poids à vide</label>
                                            <input name='pav' className={`new-default-input${errors.pav && touched.pav ? " input-error" : ""}`} type="number" placeholder="Poids à vide du véhicule" value={values.pav} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.pav && touched.pav && <p className="form-error-message">{errors.pav}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='commentaire' className='form-label'>Commentaire</label>
                                            <input name='commentaire' className={`new-default-input${errors.commentaire && touched.commentaire ? " input-error" : ""}`} type="text" placeholder="Commentaire" value={values.commentaire} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.commentaire && touched.commentaire && <p className="form-error-message">{errors.commentaire}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='particularite' className='form-label'>Particularité du véhicule</label>
                                            <input name='particularite' className={`new-default-input${errors.particularite && touched.particularite ? " input-error" : ""}`} type="text" placeholder="Particularite du véhicule" value={values.particularite} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.particularite && touched.particularite && <p className="form-error-message">{errors.particularite}</p>}
                                        </div>
                                        <div className='label-and-input last-input'>
                                            <label htmlFor='pneus' className='form-label'>Pneus</label>
                                            {formOptions !== null &&
                                                <>
                                                    <Select
                                                        options={formOptions.pneusVehiculeOptions.options.map((option) => {
                                                            return (
                                                                { label: option, value: option }
                                                            );
                                                        })}
                                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => onChangePneuVehicule(opt)}
                                                        placeholder="Sélectionner le type de pneu"
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        formatCreateLabel={(value) => `Ajouter ${value} comme type de pneu`}
                                                        defaultValue={vehicule.pneus !== null ? { label: vehicule.pneus, value: vehicule.pneus } : ""}
                                                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                                                    />
                                                    {errors.pneusOptions && touched.pneusOptions && <p className="form-error-message">{errors.pneusOptions}</p>}
                                                </>
                                            }
                                        </div>
                                        <div className='label-and-input last-input'>
                                            <label htmlFor='jantes' className='form-label'>Jantes</label>
                                            {formOptions !== null &&
                                                <>
                                                    <Select
                                                        options={formOptions.jantesVehiculeOptions.options.map((option) => {
                                                            return (
                                                                { label: option, value: option }
                                                            );
                                                        })}
                                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => onChangeJanteVehicule(opt)}
                                                        placeholder="Sélectionner le type de jante"
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                        isSearchable={true}
                                                        formatCreateLabel={(value) => `Ajouter ${value} comme type de jante`}
                                                        isClearable={true}
                                                        defaultValue={vehicule.jantes !== null ? { label: vehicule.jantes, value: vehicule.jantes } : ""}
                                                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                                                    />
                                                    {errors.jantesOptions && touched.jantesOptions && <p className="form-error-message">{errors.jantesOptions}</p>}
                                                </>
                                            }
                                        </div>
                                        <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier le véhicule" />
                                    </form>
                                </div>
                            </div>
                        </>
                    </Hider>
                    <Hider state={afficherProfilVehicule === "documents"}>
                        <>
                            <div className="gestion-documents-wrapper">
                                <div className="filterContainer">
                                    <SearchInput placeholder="Rechercher un document" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={setSearchBarContent} />
                                </div>
                                <div>
                                    <div {...getRootProps({ style })}>
                                        <input {...getInputProps()} />
                                        <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                    </div>
                                </div>
                                <TableRender
                                    columns={getDocumentsVehiculeColumns((document) => openSupprimerDocument(document), (documentData) => openEditDocumentVehicule(documentData))}
                                    tableData={showedDocuments}
                                    sortBy={[

                                    ]}
                                    pageName="ProfilVehiculesDocuments"
                                />
                            </div>
                        </>
                    </Hider>
                </div>
            }
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouterDocument"}>
                    <AjouterDocumentVehicule addDocument={(document) => addDocument(document)} idVehicule={idVehicule} documentSaveRoute={"documentVehicule"} documentData={modalDocumentVehiculeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierDocument"}>
                    <ModifierDocumentVehicule progressBarRef={progressBarRef} editDocumentVehicule={(document) => editDocumentVehicule(document)} vehicule={vehicule} documentData={modalDocumentVehiculeSelected} idVehicule={idVehicule} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimerDocument"}>
                    <SupprimerModalForm destination="supprimerDocumentVehicule" vehicule={vehicule} documentData={modalDocumentVehiculeSelected} idVehicule={idVehicule} progressBarRef={progressBarRef} supprimerDocument={(documentID) => supprimerDocument(documentID)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>
    );
};

export default ProfilVehicule;

// initialValues: {
//     categorie: props.vehiculeData.categorie !== null ? props.vehiculeData.categorie : "",
//     typeVehicule: props.vehiculeData.typeVehicule !== null ? props.vehiculeData.typeVehicule : "",
//     marque: props.vehiculeData.marque !== null ? props.vehiculeData.marque : "",
//     immatriculation: props.vehiculeData.immatriculation !== null ? props.vehiculeData.immatriculation : "",
//     numeroParc: props.vehiculeData.numeroParc !== null ? props.vehiculeData.numeroParc : "",
//     pav: props.vehiculeData.pav !== null ? props.vehiculeData.pav : "",
//     commentaire: props.vehiculeData.commentaire !== null ? props.vehiculeData.commentaire : "",
//     particularite: props.vehiculeData.particularite !== null ? props.vehiculeData.particularite : "",
//     pneus: props.vehiculeData.pneus !== null ? props.vehiculeData.pneus : "",
//     jantes: props.vehiculeData.jantes !== null ? props.vehiculeData.jantes : "",
// }