import axios from 'axios';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { showError } from '../utils/ErrorHelper';
import SearchInput from './ui/SearchInput';
import { getInsensitiveWorld, iconExtension } from '../utils/GeneralHelper';
import Hider from 'react-hider';
import Modal from './Modal';
import { addDays, addMonths, addYears, compareAsc, compareDesc, format, isEqual, subDays, subMonths, subYears } from 'date-fns';
import { Icon } from '@iconify/react';
import TableRender from './tables/TableRender';
import { getFormationsValidees, getFormationsValideesColumns } from './tables/TableColumns';
import SimpleTableRender from './tables/SimpleTableRender';
import SupprimerIcon from './icon/SupprimerIcon';

const FormationsSalarie = (props) => {

    // Formations data
    const [formationsSalarie, setFormationsSalarie] = useState([]);
    const [showedFormationsSalarie, setShowedFormationsSalarie] = useState([]);

    const [historiqueOpenId, setHistoriqueOpenId] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/salarieFormations/${props.salarie._id}`).then((result) => {
            let formations = result.data;
            for (const formation of formations) {
                formation.formationsValidees.sort((a, b) => {
                    return compareDesc(new Date(a.dateFormation), new Date(b.dateFormation));
                });
            }
            setFormationsSalarie(formations);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    // searchBar
    const [searchFormationBarContent, setSearchFormationBarContent] = useState(null);
    const onChangeSearchFormation = (e) => {
        setSearchFormationBarContent(e.target.value);
    };

    const formationMatch = (formation, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(formation.formation.nomFormation).includes(insensitiveSearch);
    };

    useEffect(() => {
        if (searchFormationBarContent !== null && searchFormationBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedFormationsSalarie(formationsSalarie.filter(formation => {
                    return formationMatch(formation, searchFormationBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedFormationsSalarie(formationsSalarie);
        }
    }, [formationsSalarie, searchFormationBarContent]);


    const addFormation = (formationAjouter) => {
        let salarieHasFormation = false;
        let formationRaw = formationAjouter;
        setFormationsSalarie(formationsSalarie.map((formation) => {
            if (formation.formation._id === formationAjouter.formation._id) {
                salarieHasFormation = true;
                formationRaw.formationsValidees.sort((a, b) => {
                    return compareDesc(new Date(a.dateFormation), new Date(b.dateFormation));
                });

                return formationRaw;
            }
            return formation;
        }));

        if (!salarieHasFormation) {
            setFormationsSalarie(formationsSalarie.concat(formationAjouter));
        }
    };

    const getDateExpiration = (dateFormation, renouvellement) => {
        let dateExpiration = new Date(dateFormation);
        if (renouvellement.annee !== null) {
            dateExpiration = addYears(dateExpiration, renouvellement.annee);
        }
        if (renouvellement.mois !== null) {
            dateExpiration = addMonths(dateExpiration, renouvellement.mois);
        }
        if (renouvellement.jour !== null) {
            dateExpiration = addDays(dateExpiration, renouvellement.jour);
        }
        return dateExpiration;
    };

    const getDateSignalementUrgence = (dateExpiration, signalerUrgence) => {
        let dateUrgence = dateExpiration;
        if (signalerUrgence.annee !== null) {
            dateUrgence = subYears(dateUrgence, signalerUrgence.annee);
        }
        if (signalerUrgence.mois !== null) {
            dateUrgence = subMonths(dateUrgence, signalerUrgence.mois);
        }
        if (signalerUrgence.jour !== null) {
            dateUrgence = subDays(dateUrgence, signalerUrgence.jour);
        }
        return dateUrgence;
    };

    const getContainerExpirationClass = (dateFormation, dateExpiration, signalerUrgence) => {
        let currentDate = new Date();
        if (dateExpiration === null) {
            return "formation-valide";
        }
        else {
            let formatDateExpiration = new Date(dateExpiration);
            if (currentDate.getTime() > formatDateExpiration.getTime()) {
                return "formation-expired";
            }
            else {
                let dateUrgence = getDateSignalementUrgence(formatDateExpiration, signalerUrgence);
                if (currentDate.getTime() >= dateUrgence.getTime()) {
                    return "formation-urgent";
                }
                else {
                    return "formation-valide";
                }
            }
        }
    };

    // edit formation
    const editFormation = (formationUpdated) => {
        let formationSort = formationUpdated;
        formationSort.formationsValidees.sort((a, b) => {
            return compareDesc(new Date(a.dateFormation), new Date(b.dateFormation));
        });
        setFormationsSalarie(formationsSalarie.map((formation) => {
            if (formation._id === formationUpdated._id) {
                return formationUpdated;
            }
            return formation;
        }));
    };

    // delete formation

    const getDateExpirationString = (dateFormation, renouvellement) => {
        let dateExpiration = getDateExpiration(dateFormation, renouvellement);

        if (isEqual(new Date(dateFormation), dateExpiration)) {
            return "jamais";
        }
        return format(dateExpiration, "dd/MM/yyyy");
    };

    const viewFile = (documentId) => {
        axiosInstance.get(`uploads/documents/${documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    const deleteFormation = (formationDeleted) => {
        let formations = formationDeleted;
        for (const formation of formations) {
            formation.formationsValidees.sort((a, b) => {
                return compareDesc(new Date(a.dateFormation), new Date(b.dateFormation));
            });
        }
        console.log(formations);
        setFormationsSalarie(formations);

        // comparer les bon id de formation
        // let islastFormationsValideesDeleted = false;
        // for (const formation of formationsSalarie) {
        //     if (formation.formation._id === formationDeleted.formationId) {
        //         console.log(formation)
        //         if (formation.formationsValidees.length === 1) {
        //             islastFormationsValideesDeleted = true
        //         }
        //         break;
        //     }
        // }
        // if (islastFormationsValideesDeleted) {
        //     setFormationsSalarie(formationsSalarie.filter(formation => {
        //         return formation.formation._id !== formationDeleted.formationId
        //     }))
        // }
        // else {
        //     setFormationsSalarie(
        //         formationsSalarie.map((formation) => {
        //             if (formation.formation._id === formationDeleted.formationId) {
        //                 return {...formation, formationsValidees: formation.formationsValidees.filter(formationValidee => {
        //                     return formationValidee._id !== formationDeleted.formationsValideesId
        //                 })}
        //             }
        //             return formation
        //         })
        //     )
        // }
    };

    // useEffect(() => {
    //   console.log(JSON.stringify(formationsSalarie))
    // }, [formationsSalarie])

    const supprimerLaFormation = (formationData, formationId, nomFormation) => {
        // console.log(JSON.stringify({formationsValidees: formationData, formationId: formationId, nomFormation: nomFormation}))
        props.openModalSupprimerFormation({ formationsValidees: formationData, formationId: formationId, nomFormation: nomFormation }, deleteFormation);
    };



    return (
        <>
            <div>
                <div className="new-filter-section">
                    <button className='btn btn-primary' onClick={() => props.openModalAjouterFormation((formation) => addFormation(formation))}>
                        + Ajouter formation
                    </button>
                    <div className='new-filter-container'>
                        <SearchInput style={{ flex: 1 }} placeholder="Rechercher une formation" searchContent={searchFormationBarContent ? searchFormationBarContent : ""} onChangeSearch={onChangeSearchFormation} />
                    </div>
                </div>
                {showedFormationsSalarie.map((formation) => {
                    return (
                        <div key={formation._id} className={'container-basic mgB-m1 ' + getContainerExpirationClass(formation.formationsValidees[0].dateFormation, formation.formationsValidees[0].dateExpiration, formation.formation.signalerUrgence)}>
                            <div className='formationCard' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className='currentFormation-container'>
                                    <h4>{formation.formation.nomFormation}</h4>
                                    <div className='currentFormation'>
                                        <div className='currentFormation-section'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div className='mgR-m2'>
                                                    <p className='labelFormation'>Dernière validation:</p>
                                                    <p className='labelFormation'>Expire le:</p>
                                                </div>
                                                <div>
                                                    <p>{formation.formationsValidees[0].hasOwnProperty("dateFormation") && formation.formationsValidees[0].dateFormation !== null ? format(new Date(formation.formationsValidees[0].dateFormation), "dd/MM/yyyy") : "aucune"}</p>
                                                    <p>{formation.formationsValidees[0].hasOwnProperty("dateExpiration") && formation.formationsValidees[0].dateExpiration !== null ? format(new Date(formation.formationsValidees[0].dateExpiration), "dd/MM/yyyy") : "jamais"}</p>
                                                    {/* <p>{getDateExpirationString(formation.formationsValidees[0].dateFormation, formation.formation.renouvellement)}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='currentFormation-section'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div className='mgR-m2'>
                                                    <p className='labelFormation'>Organisme de formation:</p>
                                                    <p className='labelFormation'>Document:</p>

                                                </div>
                                                <div>
                                                    <p>{formation.formationsValidees[0].organismeFormation ? formation.formationsValidees[0].organismeFormation : "Aucun"}</p>
                                                    {formation.formationsValidees[0].hasOwnProperty("document") ?
                                                        <p onClick={() => viewFile(formation.formationsValidees[0].document._id)} className='documentFormation'>{iconExtension(formation.formationsValidees[0].document.file.filename)} {`${formation.formationsValidees[0].document.titre}.${formation.formationsValidees[0].document.file.filename.split(".")[formation.formationsValidees[0].document.file.filename.split(".").length - 1]}`}</p>
                                                        :
                                                        <p>Pas de document</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                    <button onClick={() => props.openModalFormationSalarie({ formationId: formation.formation._id, formationsValidees: formation.formationsValidees[0], nomFormation: formation.formation.nomFormation }, editFormation)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignSelf: "flex-start" }} className='btn btn-primary'><Icon style={{ width: "2.2rem", height: "2.2rem" }} icon="ic:outline-edit" /><p className="mgL-s1">Éditer</p></button>
                                    <button onClick={() => supprimerLaFormation(formation.formationsValidees[0], formation.formation._id, formation.formation.nomFormation)} className='btn btn-primary mgL-s2 supprimerButton'><SupprimerIcon /></button>
                                </div>
                            </div>
                            <Hider state={formation.formationsValidees.length > 1}>
                                <div>
                                    <div onClick={() => {
                                        if (formation._id === historiqueOpenId) { setHistoriqueOpenId(null); } else {
                                            setHistoriqueOpenId(formation._id);
                                        }
                                    }} className={`historiqueFormation ${formation._id === historiqueOpenId ? "opened" : ""}`}>
                                        <h5>Historique</h5>
                                        <Icon icon="material-symbols:arrow-forward-ios" />
                                    </div>
                                    <div className='historiqueFormation-tableContainer' style={{ height: formation._id === historiqueOpenId ? `${65 * (formation.formationsValidees.length - 1) + 44}px` : 0 }}>
                                        <SimpleTableRender
                                            columns={getFormationsValideesColumns(
                                                (documentId) => viewFile(documentId),
                                                (formation) => deleteFormation(formation),
                                                (formationData, deleteFormation) => { props.openModalSupprimerFormation({ formationsValidees: formationData, formationId: formation.formation._id, nomFormation: formation.formation.nomFormation }, deleteFormation); },
                                                editFormation,
                                                (formationData, editFormation) => { props.openModalFormationSalarie({ formationId: formation.formation._id, formationsValidees: formationData, nomFormation: formation.formation.nomFormation }, editFormation); }
                                            )}
                                            tableData={formation.formationsValidees.slice(1)}
                                            sortBy={[
                                            ]}
                                        />
                                    </div>
                                </div>
                            </Hider>

                        </div>
                    );
                })}
                {/* <div className="search-filter-container mgB-s3">
                    <div className='search-filter-content'>
                        <div className="search-filter-section">
                            <p>Rechercher une formation</p>
                            <SearchInput placeholder="Rechercher une formation" searchContent={searchFormationBarContent ? searchFormationBarContent : ""} onChangeSearch={onChangeSearchFormation} />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default FormationsSalarie;
