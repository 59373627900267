import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import axios from 'axios';
import Select from "react-select";
import Creatable from 'react-select/creatable';
import ClipLoader from "react-spinners/ClipLoader";
import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import { formatGpsString, getInsensitiveWorld, isValidDate } from '../../../../utils/GeneralHelper';
import axiosInstance from '../../../../utils/axiosInstance';
import customToast from '../../../../utils/ToastifyHelper';
import { showError } from '../../../../utils/ErrorHelper';
import Hider from "react-hider";
import { differenceInDays, format } from 'date-fns';




const ProfilChantierAmiante_InformationsGenerales_modifierChantier = (props) => {


    //--------------- Form Schema ------------------
    const formSchema = yup.object().shape({
        libelle_lieu: yup.string().required("Veuillez saisir le nom du chantier"),
        zone: yup.string().required("Veuillez entrer la zone du chantier"),
        codeChantier: yup.string().required("Veuillez saisir le code du chantier"),
        secteur: yup.string().required("Veuillez sélectionner le secteur du chantier"),
        lieuExecution: yup.string(),
        ville: yup.string().optional(),
        dateDebutPrevue: yup.date(),
        dateFinPrevue: yup.date(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        gps: yup.string().nullable().notRequired().when("gps", (gps, schema) => {
            if (gps) {
                return schema.matches(/^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/, "Erreur de format du point gps, la latitude et la longitude doivent être séparé par une virgule");
            }
            return schema;
        }),
    },
        [["codePostal", "codePostal"], ["gps", "gps"]]
    );

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const postData = {
            ...values,
            gps: values.gps !== "" ? formatGpsString(values.gps) : null,
            dateDebutPrevue: values.dateDebutPrevue === "" ? null : values.dateDebutPrevue,
            dateFinPrevue: values.dateFinPrevue === "" ? null : values.dateFinPrevue
        };


        // post chantier
        axiosInstance.patch(`/chantiers/amiante/${props.chantier._id._id}`, postData).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("Le chantier a été modifié ave succès");
            setSubmitting(false);
        }).catch((err) => {
            setSubmitting(false);
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_lieu: props.chantier._id.libelle_lieu,
            zone: props.chantier.zone._id,
            codeChantier: props.chantier.codeChantier,
            secteur: props.chantier.secteur,
            dateDebutPrevue: props.chantier.hasOwnProperty("dateDebutPrevue") && props.chantier.dateDebutPrevue ? format(new Date(props.chantier.dateDebutPrevue), "yyyy-MM-dd") : "",
            dateFinPrevue: props.chantier.hasOwnProperty("dateFinPrevue") && props.chantier.dateFinPrevue ? format(new Date(props.chantier.dateFinPrevue), "yyyy-MM-dd") : "",
            gps: props.chantier._id.adresse.hasOwnProperty("gps") && props.chantier._id.adresse.gps.latitude !== null && props.chantier._id.adresse.gps.longitude !== null ? `${props.chantier._id.adresse.gps.latitude}, ${props.chantier._id.adresse.gps.longitude}` : "",
            codePostal: props.chantier._id.adresse.codePostal ? props.chantier._id.adresse.codePostal : "",
            ville: props.chantier._id.adresse.ville ? props.chantier._id.adresse.ville : "",
            lieuExecution: props.chantier._id.adresse.lieuExecution ? props.chantier._id.adresse.lieuExecution : ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeZoneChantiers = (opt) => {
        setFieldValue("zone", opt.value);
    };

    const secteurOptions = [
        { label: "Gros oeuvres", value: "Gros oeuvres" },
        { label: "Travaux publics", value: "Travaux publics" }
    ];

    const onChangeSecteurChantiers = (opt) => {
        setFieldValue("secteur", opt.value);
    };

    //--------------- compose adresse ------------------
    const mapRef = useRef();
    const onMapLoad = useCallback(() => {
        mapRef.current.on('click', (e) => {
            setFieldValue("gps", `${e.lngLat.lat}, ${e.lngLat.lng}`);
        });
    }, []);
    const [communesList, setCommunesList] = useState([]);
    const [loadingCommuneInput, setLoadingCommuneInput] = useState(false);

    const onBlurPostalCode = () => {
        tryUpdateGps();
        if (values.codePostal.length === 5) {
            setLoadingCommuneInput(true);
            axios.get(`https://geo.api.gouv.fr/communes?codePostal=${values.codePostal}`).then((result) => {
                setLoadingCommuneInput(false);
                setCommunesList(result.data.map((commune) => {
                    return {
                        value: commune.nom,
                        label: commune.nom
                    };
                }));
            }).catch((err) => {
                setLoadingCommuneInput(false);
            });
        }
        else {
            if (communesList.length) {
                setCommunesList([]);
            }
        }
    };

    const onChangeVille = (opt) => {
        setFieldValue("ville", opt.label);
        tryUpdateGps();
    };

    const canCreateNewVilleOption = (opt) => {
        if (opt.length) {
            if (communesList.length) {
                for (const commune of communesList) {
                    if (getInsensitiveWorld(commune.label).includes(getInsensitiveWorld(opt))) {
                        return false;
                    }
                }
            }
            return true;
        }
        return false;
    };

    const onInputChangeVille = (opt, meta) => {
        if (meta.action === "input-change" || meta.action === "set-value") {
            setFieldValue("ville", opt);
        }
    };

    const tryUpdateGps = () => {
        if (values.lieuExecution.length && values.codePostal.length === 5 && values.ville.length) {
            const searchQUery = `${values.lieuExecution} ${values.codePostal} ${values.ville}`;
            axios.get(`https://api-adresse.data.gouv.fr/search/?q=${searchQUery}&type=housenumber&autocomplete=1&limit=15`).then((result) => {
                if (result.data.features.length === 1) {
                    setFieldValue("gps", `${result.data.features[0].geometry.coordinates[1]}, ${result.data.features[0].geometry.coordinates[0]}`);
                    try {
                        mapRef.current.flyTo({
                            center: [result.data.features[0].geometry.coordinates[0], result.data.features[0].geometry.coordinates[1]],
                            essential: true,
                            duration: 1000,
                            zoom: 12
                        });
                    } catch (error) {
                    }
                }
            });
        }
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            tryUpdateGps();
        }, 1000);
        return () => clearTimeout(timer);
    }, [values.lieuExecution]);

    const [voirCarte, setVoirCarte] = useState(false);

    const fieldEdited = () => {
        return true;
    };

    const [dureeTravaux, setDureeTravaux] = useState("");

    useEffect(() => {
        if (isValidDate(values.dateDebutPrevue) && isValidDate(values.dateFinPrevue)) {
            const calculateDureeTravaux = differenceInDays(new Date(values.dateFinPrevue), new Date(values.dateDebutPrevue));
            setDureeTravaux(`${calculateDureeTravaux} jour${calculateDureeTravaux > 1 ? "s" : ""}`);
        }
        else {
            if (dureeTravaux !== "") {
                setDureeTravaux("");
            }
        }
    }, [values.dateDebutPrevue, values.dateFinPrevue]);

    return (
        <form className='form' onSubmit={handleSubmit}>
            <div className='label-and-input'>
                <label htmlFor='codeChantier' className='form-label'>Code du chantier<span className='required-field'>*</span></label>
                <input name='codeChantier' className={`new-default-input${errors.codeChantier && touched.codeChantier ? " input-error" : ""}`} type="text" placeholder="Code du chantier" value={values.codeChantier} onChange={handleChange} onBlur={handleBlur} />
                {errors.codeChantier && touched.codeChantier && <p className="form-error-message">{errors.codeChantier}</p>}
            </div>
            <div className='label-and-input'>
                <label htmlFor='libelle_lieu' className='form-label'>Nom du chantier<span className='required-field'>*</span></label>
                <input name='libelle_lieu' className={`new-default-input${errors.libelle_lieu && touched.libelle_lieu ? " input-error" : ""}`} type="text" placeholder="Nom du chantier" value={values.libelle_lieu} onChange={handleChange} onBlur={handleBlur} />
                {errors.libelle_lieu && touched.libelle_lieu && <p className="form-error-message">{errors.libelle_lieu}</p>}
            </div>
            <div className='label-and-input'>
                <label htmlFor='zone' className='form-label'>Zone</label>
                <Select
                    options={props.zoneChantiers}
                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => onChangeZoneChantiers(opt)}
                    placeholder={"Zone"}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            fontFamily: "DM Sans",
                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                            borderRadius: "0.6rem",
                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                            '&:hover': {
                                border: '1px solid black',
                            },
                        })
                    }}
                    isSearchable={true}
                    value={values.zone ? props.zoneChantiers.find(zone => {
                        return zone.value === values.zone;
                    }) : ""}
                />
                {errors.zone && touched.zone && <p className="form-error-message">{errors.zone}</p>}
            </div>
            <div className='label-and-input'>
                <label htmlFor='secteur' className='form-label'>Secteur</label>
                <Select
                    options={secteurOptions}
                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => onChangeSecteurChantiers(opt)}
                    placeholder={"Secteur"}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            fontFamily: "DM Sans",
                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                            borderRadius: "0.6rem",
                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                            '&:hover': {
                                border: '1px solid black',
                            },
                        })
                    }}
                    isSearchable={true}
                    value={values.secteur ? { label: values.secteur, value: values.secteur } : ""}
                />
                {errors.secteur && touched.secteur && <p className="form-error-message">{errors.secteur}</p>}
            </div>
            <div className='horizontal-form-fields-container'>
                <div className='label-and-input'>
                    <label htmlFor='codePostal' className='form-label'>Code postal</label>
                    <input onBlurCapture={() => onBlurPostalCode()} name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='ville' className='form-label'>Ville</label>
                    <Creatable
                        isLoading={loadingCommuneInput}
                        options={communesList}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeVille(opt)}
                        createOptionPosition='first'
                        isValidNewOption={(opt) => canCreateNewVilleOption(opt)}
                        placeholder={"Ville"}
                        onInputChange={onInputChangeVille}
                        defaultValue={{ label: values.ville, value: values.ville }}
                        blurInputOnSelect={false}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={false}
                        noOptionsMessage={() => false}
                        formatCreateLabel={value => value}
                    />
                    {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                </div>
            </div>
            {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
            <div className='horizontal-form-fields-container'>
                <div className='label-and-input'>
                    <label htmlFor='lieuExecution' className='form-label'>Adresse</label>
                    <input name='lieuExecution' className={`new-default-input${errors.lieuExecution && touched.lieuExecution ? " input-error" : ""}`} type="text" placeholder="Adresse du chantier" value={values.lieuExecution} onChange={handleChange} onBlur={handleBlur} />
                </div>
            </div>
            <Hider state={voirCarte}>
                <>
                    <div className='label-and-input'>
                        <label htmlFor='gps' className='form-label'>Point gps</label>
                        <input name='gps' className={`new-default-input${errors.gps && touched.gps ? " input-error" : ""}`} type="text" placeholder={`longitude,latitude`} value={values.gps} onChange={handleChange} onBlur={handleBlur} />
                        {errors.gps && touched.gps && <p className="form-error-message">{errors.gps}</p>}
                    </div>
                    <Map
                        ref={mapRef}
                        initialViewState={values.gps.match(/^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/) ? { longitude: formatGpsString(values.gps)[1], latitude: formatGpsString(values.gps)[0], zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                        style={{ width: "100%", flex: 1, borderRadius: ".5rem", marginBottom: "2rem", minHeight: "27rem" }}
                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                        onLoad={onMapLoad}
                        attributionControl={false}
                    >
                        {values.gps.match(/^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/) &&
                            <Marker longitude={formatGpsString(values.gps)[1]} latitude={formatGpsString(values.gps)[0]} anchor="bottom">

                            </Marker>
                        }
                    </Map>
                </>
                <div className='label-and-input'>
                    <button onClick={() => setVoirCarte(true)} className="btn btn-secondary">Afficher la carte</button>
                </div>
            </Hider>
            <div className='horizontal-form-fields-container'>
                <div className='label-and-input'>
                    <label htmlFor='dateDebutPrevue' className='form-label'>Date de début prévue</label>
                    <input name='dateDebutPrevue' className={`new-default-input${errors.dateDebutPrevue && touched.dateDebutPrevue ? " input-error" : ""}`} type="date" value={values.dateDebutPrevue} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateFinPrevue' className='form-label'>Date de fin prévue</label>
                    <input name='dateFinPrevue' className={`new-default-input${errors.dateFinPrevue && touched.dateFinPrevue ? " input-error" : ""}`} type="date" value={values.dateFinPrevue} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input' style={{ flex: 1 }}>
                    <label htmlFor='dureeTravaux' className='form-label'>Durée prévue des travaux</label>
                    <input disabled name='dureeTravaux' className='new-default-input' type="text" placeholder="nombre de jours" value={dureeTravaux} />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <input disabled={isSubmitting || !fieldEdited()} style={{ opacity: !isSubmitting && fieldEdited() ? 1 : 0.4 }} className="btn btn-primary" type="submit" value="Sauvegarder" />
            </div>
        </form>
    );
};

export default ProfilChantierAmiante_InformationsGenerales_modifierChantier;
