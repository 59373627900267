import React, { useEffect, useMemo, useRef, useState } from 'react';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../../components/ui/Breadcrumb';
import SearchInput from '../../../components/ui/SearchInput';
import { getDocumentsAmianteColumns, getDocumentsAmianteColumnsChef } from '../../../components/tables/TableColumns';
import TableRender from '../../../components/tables/TableRender';
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, getInsensitiveWorld, rejectStyle } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import Modal from '../../../components/Modal';
import Hider from 'react-hider';
import AjouterDocumentAmiante from '../../../components/forms/AmianteForms/AjouterDocumentAmiante';
import SupprimerModalForm from '../../../components/forms/SupprimerModalForm';
import ModifierDocumentAmiante from '../../../components/forms/AmianteForms/ModifierDocumentAmiante';



const DocumentAmianteEncadrantTechnique = () => {
    const [documentsAmiante, setDocumentsAmiante] = useState([]);
    const [showedItems, setShowedItems] = useState([]);
    const [typeDocumentsOptions, setTypeDocumentsOptions] = useState({});

    const progressBarRef = useRef(null);

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/uploads/documentsAmiante/me").then((result) => {
            setDocumentsAmiante(result.data);
            progressBarRef.current.complete();
        }).catch((err) => {
            showError(err);
        });

        axiosInstance.get("/formOptions?formName=typeDocumentsAmiante")
            .then((result) => {
                if (result.data.length) {
                    setTypeDocumentsOptions({ options: result.data[0].options.sort((a, b) => a.localeCompare(b)), id: result.data[0]._id });
                }
            }).catch((err) => {
                showError(err);
            });
    }, []);

    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchDocumentAmiante = (e) => {
        setSearchBarContent(e.target.value);
    };
    const documentsAmianteMatch = (document, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(document._id.titre).includes(insensitiveSearch);
    };

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalDocumentAmianteSelected, setModalDocumentAmianteSelected] = useState([]);

    const openAjouterDocumentAmianteModal = (documentData) => {
        setModalDocumentAmianteSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("ajouter doc amiante");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const ajouterDocumentAmiante = (document) => {
        setDocumentsAmiante(documentsAmiante.concat(document));
    };

    const openSupprimerDocumentAmianteModal = (documentData) => {
        setModalDocumentAmianteSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer doc amiante");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerDocumentAmiante = (documentId) => {
        setDocumentsAmiante(documentsAmiante.filter(doc => {
            return doc._id._id !== documentId;
        }));
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(documentsAmiante.filter(doc => {
                    return documentsAmianteMatch(doc, searchBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(documentsAmiante);
        }
    }, [documentsAmiante, searchBarContent]);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            openAjouterDocumentAmianteModal(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const ajouterTypeDocumentsOptions = (option) => {
        setTypeDocumentsOptions({ ...typeDocumentsOptions, options: typeDocumentsOptions.options.concat(option) });
    };

    const openModifierDocumentAmiante = (documentData) => {
        setModalDocumentAmianteSelected(documentData);
        setModalClasses("modal modal__active");
        setModalContent("modifier doc amiante");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const modifierDocumentAmiante = (document) => {
        setDocumentsAmiante(documentsAmiante.map((doc) => {
            if (document._id._id === doc._id._id) {
                return document;
            }
            return doc;
        }));
    };

    const viewFile = (documentId) => {
        axiosInstance.get(`uploads/documentsAmiante/${documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div>
                <div>
                    <h1>Document amiante</h1>
                </div>
                <Breadcrumb
                    links={[
                        { label: "amiante", link: "/amiante" },
                        { label: "Documents", link: "/amiante/documents-amiante" },
                    ]}
                />
                <div className="filterContainer mgT-s3 mgB-s3">
                    <SearchInput fullwidth={true} placeholder="Rechercher un document" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchDocumentAmiante} />
                </div>
                <div>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                    </div>
                </div>
                <TableRender
                    columns={getDocumentsAmianteColumns((documentData) => openSupprimerDocumentAmianteModal(documentData), (documentId) => viewFile(documentId), (doc) => openModifierDocumentAmiante(doc))}
                    tableData={showedItems}
                    sortBy={[
                        {
                            id: 'libelleDocument',
                            desc: false
                        }
                    ]}
                    pageName="GestionDocumentsAmiante"
                />
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouter doc amiante"}>
                    <AjouterDocumentAmiante progressBarRef={progressBarRef} ajouterDocumentAmiante={(doc) => ajouterDocumentAmiante(doc)} ajouterTypeDocumentsOptions={(opt) => ajouterTypeDocumentsOptions(opt)} documentData={modalDocumentAmianteSelected} typeDocumentsOptions={typeDocumentsOptions} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimer doc amiante"}>
                    <SupprimerModalForm destination="supprimerDocumentAmiante" documentData={modalDocumentAmianteSelected} supprimerDocumentAmiante={(documentId) => supprimerDocumentAmiante(documentId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifier doc amiante"}>
                    <ModifierDocumentAmiante progressBarRef={progressBarRef} modifierDocumentAmiante={(doc) => modifierDocumentAmiante(doc)} documentData={modalDocumentAmianteSelected} ajouterTypeDocumentsOptions={(opt) => ajouterTypeDocumentsOptions(opt)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} typeDocumentsOptions={typeDocumentsOptions} />
                </Hider>
            </Modal>
        </>
    );
};

export default DocumentAmianteEncadrantTechnique;