import React, { useRef, useState } from "react";
import ProfilePicture from '../../ui/ProfilePicture';
import DefaultProfilePicture from '../../ui/DefaultProfilePicture';
import Hider from 'react-hider';
import { getNumeroTransportDemande, getNumeroTransportType } from "../../../utils/GeneralHelper";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import Map, { Marker } from 'react-map-gl/maplibre';
import axiosInstance from "../../../utils/axiosInstance";
import { showError } from "../../../utils/ErrorHelper";
import customToast from "../../../utils/ToastifyHelper";
import { socket } from '../../../utils/Socket';
const AvancementTransportSimple = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [isSubmitting, setSubmitting] = useState(false);
    const [informationsChauffeurText, setInformationsChauffeurText] = useState(props.transportData.informationsTransport ? props.transportData.informationsTransport : "");

    //--------------- Map ------------------
    const [afficherCarteDepart, setAfficherCarteDepart] = useState(false);
    const [afficherCarteArriver, setAfficherCarteArriver] = useState(false);

    const lieuDepartMapRef = useRef();
    const lieuArriverMapRef = useRef();
    const mapTransportRef = useRef();

    const [lieuDepartMarker, setLieuDepartMarker] = useState(props.transportData.demande.hasOwnProperty("lieuDepart") && props.transportData.demande.lieuDepart._id.adresse !== null && props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude } : null);
    const [lieuArriverMarker, setLieuArriverMarker] = useState(props.transportData.demande.hasOwnProperty("lieuArriver") && props.transportData.demande.lieuArriver._id.adresse !== null && props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude } : null);

    const onModifierTransport = () => {
        setSubmitting(true);
        props.progressBarRef.current.continuousStart();
        axiosInstance.patch(`/transports/modifierInformationsTransport/${props.transportData._id}`, { informationsTransport: informationsChauffeurText }).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success('Un transport a été modifié avec succès');
            props.onEditTransport(result.data);
            socket.emit("transport_chauffeur:edit", { room: result.data.chauffeur._id._id, transport: result.data });
            close();
        }).catch((err) => {
            props.progressBarRef.current.complete();
            setSubmitting(false);
            showError(err);
        });
    };

    return (
        <div className="new-modal">
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="new-modal-topSection">
                <h3 className='modal-title'>{getNumeroTransportType(props.transportData.demande.type)}{getNumeroTransportDemande(props.transportData.demande.typeDemande)}-{props.transportData.demande.numeroTransport}</h3>
                <p>Demande de <span style={{ textDecoration: "underline" }}>{props.transportData.demande.creator.nom} {props.transportData.demande.creator.prenom}</span> le {format(new Date(props.transportData.demande.dateDemande), "dd/MM/yyyy")} à {props.transportData.demande.dateDemande.substring(11, 16)}, pour le {format(new Date(props.transportData.demande.dateLivraison), "dd/MM/yyyy")} {props.transportData.demande.horaire.heure !== null && props.transportData.demande.horaire.minute !== null ? `à ${props.transportData.demande.horaire.heure}:${props.transportData.demande.horaire.minute}` : ""} </p>
                <p>Transport débuté le {format(new Date(props.transportData.dateDebutTransport), "dd/MM/yyyy")} à {props.transportData.dateDebutTransport.substring(11, 16)}</p>
            </div>
            <div className="new-modal-mainSection">
                <div className='formSection mgB-m1'>
                    <h4 className='mgB-s3'>Demande</h4>
                    {props.transportData.type === "TransportAmpiroll" &&
                        <div className='label-and-input' style={{ display: "flex", marginBottom: 0 }}>
                            <label htmlFor='documentTitle' className='form-label  mgR-s2'>Matériaux:</label>
                            <p>{props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? `${props.transportData.demande.materiau.libelle_mat} (${props.transportData.demande.provenanceMateriau.libelle_lieu})` : props.transportData.demande.materiau.libelle_mat}</p>
                        </div>
                    }
                    {props.transportData.type === "TransportPorteEngin" &&
                        <div className='label-and-input' style={{ display: "flex", marginBottom: 0 }}>
                            <p>Transfert d'un {props.transportData.demande.typeVehicule}</p>
                        </div>
                    }
                    <div className="mgB-s3" style={{ display: "flex", alignItems: "baseline" }}>
                        <div style={{ flex: 1, marginRight: "1.4rem" }}>
                            <div className='label-and-input horizontal' style={{ marginBottom: 0 }}>
                                <label htmlFor='documentTitle' className='form-label'>De</label>
                                <p style={{ flex: 1 }}>{props.transportData.demande.lieuDepart._id.libelle_lieu}</p>
                            </div>
                            {afficherCarteDepart &&
                                <div style={{ minHeight: "180px", marginTop: "0.8rem" }}>
                                    <Map
                                        attributionControl={false}
                                        ref={lieuDepartMapRef}
                                        initialViewState={props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                        style={{ width: "100%", minHeight: "180px", borderRadius: ".5rem" }}
                                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                    >
                                        {lieuDepartMarker !== null &&
                                            <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                                            </Marker>
                                        }
                                    </Map>
                                </div>
                            }
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className='label-and-input horizontal' style={{ marginBottom: 0 }}>
                                <label htmlFor='documentTitle' className='form-label'>Vers</label>
                                <p style={{ flex: 1 }}>{props.transportData.demande.lieuArriver._id.libelle_lieu}</p>
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapDe-" + props.transportData.demande.lieuArriver._id._id}
                                        onClick={() => { setAfficherCarteArriver(!afficherCarteArriver); setAfficherCarteDepart(!afficherCarteDepart); }}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapDe-" + props.transportData.demande.lieuArriver._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteArriver ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {
                                afficherCarteArriver &&
                                <div style={{ minHeight: "180px", marginTop: "0.8rem" }}>
                                    <Map
                                        attributionControl={false}
                                        ref={lieuArriverMapRef}
                                        initialViewState={props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                        style={{ width: "100%", minHeight: "180px", borderRadius: ".5rem" }}
                                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                    >
                                        {lieuArriverMarker !== null &&
                                            <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                                            </Marker>
                                        }
                                    </Map>
                                </div>
                            }
                        </div>
                    </div>
                    <h4 className='mgB-s1'>Chauffeur</h4>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div className="salarieBasicInfo mgT-s2 mgB-s3">
                            <Hider state={props.transportData.chauffeur._id.profilePicture !== null}>
                                <ProfilePicture width="5rem" height="5rem" edit={false} profilePicture={props.transportData.chauffeur._id.profilePicture} />
                                <DefaultProfilePicture additionalClassname={"form"} width="5rem" height="5rem" edit={false} />
                            </Hider>
                            <div className="salarieNomStatut-container" style={{ cursor: !props.editable ? "pointer" : "auto" }}>
                                <p><span className="salarieNom">{props.transportData.chauffeur._id.nom} {props.transportData.chauffeur._id.prenom}</span></p>
                                <p className="salarieStatut">{props.transportData.chauffeur._id.login_matricule}</p>
                            </div>
                        </div>
                        <div className="mgB-s2" style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <div className='label-and-input' style={{ flex: 1, marginRight: "1.4rem", marginBottom: 0, display: "flex" }}>
                                <label htmlFor='documentTitle' className='form-label mgR-s2'>Tracteur/Porteur:</label>
                                <p>{props.transportData.tracteurPorteur.numeroParc ? `n°${props.transportData.tracteurPorteur.numeroParc} - ${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}` : `${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`}</p>
                            </div>
                            <div className='label-and-input' style={{ flex: 1, display: "flex", marginBottom: 0 }}>
                                <label htmlFor='documentTitle' className='form-label  mgR-s2'>Remorque:</label>
                                <p>{props.transportData.remorque.numeroParc ? `n°${props.transportData.remorque.numeroParc} - ${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}` : `${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className='label-and-input' style={{ marginBottom: "0.8rem" }}>
                        <label htmlFor='informationsTransport' className='form-label'>Informations pour le chauffeur</label>
                        <textarea style={{ minHeight: "7.8rem" }} name='informationsTransport' className={`new-default-input`} type="text" placeholder="Informations pour le chauffeur" value={informationsChauffeurText} onChange={(text) => { setInformationsChauffeurText(text.target.value); }} />
                    </div>
                    <button disabled={isSubmitting} onClick={() => onModifierTransport()} className={`btn btn-primary fw ${isSubmitting ? " disabled-button" : ""}`}>Modifier</button> </div>

                <h4 className='mgB-s3'>Avancement du transport</h4>
                <div style={{ flex: 1, minHeight: "329px" }}>
                    <Map
                        attributionControl={false}
                        ref={mapTransportRef}
                        initialViewState={props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                        style={{ width: "100%", borderRadius: ".5rem" }}
                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                    >
                        {lieuDepartMarker !== null &&
                            <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                            </Marker>
                        }
                        {lieuArriverMarker !== null &&
                            <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                            </Marker>
                        }
                    </Map>
                </div>
            </div>

        </div>
    );
};

export default AvancementTransportSimple;
