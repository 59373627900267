import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import Breadcrumb from "../../components/ui/Breadcrumb";
import SearchInput from "../../components/ui/SearchInput";
import { getGestionDechetColumns } from "../../components/tables/Basic/BasicTableColumns";
import BasicTableRender from "../../components/tables/Basic/BasicTableRender";
import Modal from "../../components/Modal";
import Hider from 'react-hider';
import AjouterDechet from "./AjouterDechet";
import ModifierDechet from "./ModifierDechet";
import SupprimerModalForm from "../../components/forms/SupprimerModalForm";

const GestionDechets = () => {
    const progressBarRef = useRef(null);
    const [dechets, setDechets] = useState([]);

    const getDechets = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/dechets").then((result) => {
            progressBarRef.current.complete();
            setDechets(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    useEffect(() => {
        getDechets();
    }, []);

    const [searchDechetBarContent, setSearchDechetBarContent] = useState(null);

    const onChangeSearchDechet = (e) => {
        setSearchDechetBarContent(e.target.value);
    };

    const [filterTableDechet, setFilterTableDechet] = useState(false);

    useEffect(() => {
        if (searchDechetBarContent !== null && searchDechetBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTableDechet(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTableDechet(true);
        }
    }, [dechets, searchDechetBarContent]);

    //--------------- MODAL ------------------
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalDechetSelected, setModalCodeDechetSelected] = useState({});

    const openAjouterDechetModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouterDechet");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const ajouterDechet = (dechet) => {
        setDechets(dechets.concat(dechet));
    };

    const openModifierDechetModal = (dechet) => {
        setModalCodeDechetSelected(dechet);
        setModalClasses("modal modal__active");
        setModalContent("modifierDechet");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const modifierCodeDechet = (nouveauDechet) => {
        setDechets(dechets.map((dechet) => {
            if (dechet._id === nouveauDechet._id) {
                return nouveauDechet;
            }
            return dechet;
        }));
    };

    const openSupprimerDechetModal = (dechet) => {
        setModalCodeDechetSelected(dechet);
        setModalClasses("modal modal__active");
        setModalContent("supprimerDechet");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerDechet = (dechetSupprimer) => {
        setDechets(dechets.filter((dechet) => dechet._id !== dechetSupprimer._id));
    };

    // OPTIONS LOADING
    const [codeFamilleOptions, setCodeFamilleOptions] = useState([]);
    const [codeCedOptions, setCodeCedOptions] = useState([]);
    const [conditionnementOptions, setConditionnementOptions] = useState([]);

    const ajouterConditionnement = (nouveauConditionnement) => {
        setConditionnementOptions(conditionnementOptions.concat(nouveauConditionnement));
    };

    useEffect(() => {
        axiosInstance.get("/formOptions/options/codeFamilleDechet").then((result) => {
            setCodeFamilleOptions(result.data.map((codeFamille) => {
                return {
                    value: codeFamille.value,
                    label: `${codeFamille.value} - ${codeFamille.label}`
                };
            }));
        }).catch((err) => {
            showError(err);
        });
        axiosInstance.get("/formOptions/options/codeCedDechet").then((result) => {
            setCodeCedOptions(result.data.map((codeCed) => {
                return {
                    value: codeCed.value,
                    label: `${codeCed.value} - ${codeCed.label}`
                };
            }));
        }).catch((err) => {
            showError(err);
        });
        axiosInstance.get("/formOptions/options/conditionnementDechet").then((result) => {
            setConditionnementOptions(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Liste des déchets</h1>
                    <button onClick={() => openAjouterDechetModal()} className='btn btn-primary medium-btn'>+ Déchets</button>
                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Déchets", link: "/gestion-de-donnees/dechets" },
                    ]}
                />
                <div className="filterContainer mgT-m1">
                    <SearchInput placeholder="Rechercher un déchet" searchContent={searchDechetBarContent ? searchDechetBarContent : ""} onChangeSearch={onChangeSearchDechet} />
                </div>
                <BasicTableRender
                    columns={getGestionDechetColumns((dechet) => openModifierDechetModal(dechet), (dechet) => openSupprimerDechetModal(dechet))}
                    tableData={dechets}
                    sortBy={[
                    ]}
                    filterTable={filterTableDechet}
                    setFilterTable={setFilterTableDechet}
                    searchBarContent={searchDechetBarContent}
                />
            </div>
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "ajouterDechet"}>
                    <AjouterDechet ajouterDechet={(dechet) => ajouterDechet(dechet)} ajouterConditionnement={ajouterConditionnement} codeFamilleOptions={codeFamilleOptions} codeCedOptions={codeCedOptions} conditionnementOptions={conditionnementOptions} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierDechet"}>
                    <ModifierDechet ajouterConditionnement={ajouterConditionnement} modifierCodeDechet={(dechet) => modifierCodeDechet(dechet)} dechetSelected={modalDechetSelected} progressBarRef={progressBarRef} codeFamilleOptions={codeFamilleOptions} codeCedOptions={codeCedOptions} conditionnementOptions={conditionnementOptions} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimerDechet"}>
                    <SupprimerModalForm supprimerDechet={(dechet) => supprimerDechet(dechet)} destination="supprimerGestionDechet" dechetSelected={modalDechetSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>
    );
};

export default GestionDechets;
