import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from "yup";
import axiosInstance from '../../utils/axiosInstance';
import customToast from "../../utils/ToastifyHelper";
import { showError } from '../../utils/ErrorHelper';
import { useFormik } from 'formik';
import { capitalizeFirstLetter, formatBytes, formatIntTwoDigitString, formatMateriaux, formatTechniqueModeOperatoire, getCoordinatesFromDD, getHeureParSalarie, getLastModified, getTotalHeure, iconExtension, normalizeTime } from '../../utils/GeneralHelper';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import Hider from "react-hider";
import Select from "react-select";
import TableRender from '../../components/tables/TableRender';
import { geNewProfilChantierProcessusColumns } from '../../components/tables/TableColumns';
import Modal from '../../components/Modal';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import SearchInput from '../../components/ui/SearchInput';
import EditIcon from '../../components/icon/EditIcon';
import fileDownload from 'js-file-download';
import { Icon } from '@iconify/react';
import AjouterModifierDocumentChantier from '../../components/forms/GestionsForms/AjouterModifierDocumentChantier';
import SupprimerIcon from '../../components/icon/SupprimerIcon';
import UserAccountIcon from '../../components/icon/UserAccountIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';

const ProfilChantierNew = () => {
    let { idChantier } = useParams();
    const navigate = useNavigate();
    const progressBarRef = useRef(null);
    const [chantier, setChantier] = useState(null);
    const [insideNavigationPage, setInsideNavigationPage] = useState("informationsGenerales");
    const [zoneChantiers, setZoneChantiers] = useState([]);
    const [releveHeure, setReleveHeure] = useState({ total: "0h", salaries: [] });


    const [groupedSalarieContactOptions, setGroupedSalarieContactOptions] = useState([]);
    const [salarieContacts, setSalarieContacts] = useState([]);

    useEffect(() => {
        axiosInstance.get("/utilisateurs/contacts").then((result) => {
            let groupedOptions = { "Administration": [], "Chef de chantier": [], "Ouvrier": [], "Chauffeur": [], "Intérimaire": [], "Encadrant de chantier": [], "Opérateur": [], "Encadrant technique": [], "Dispatcher": [] };
            for (const utilisateur of result.data) {
                if (utilisateur.userRole.statut !== "Archiver") {
                    groupedOptions[utilisateur.userRole.statut].push({ label: `${utilisateur.nom} ${utilisateur.prenom} - ${utilisateur.telephone.replace(/\s/g, '').replace(/(.{2})/g, '$1 ')}`, value: utilisateur._id });
                }
            }
            let groupedOptionsFormat = [];
            for (const utilisateurOptions of Object.keys(groupedOptions)) {
                groupedOptions[utilisateurOptions].sort((a, b) => a.label.localeCompare(b.label));
                groupedOptionsFormat.push({ label: utilisateurOptions, options: groupedOptions[utilisateurOptions] });
            }
            setGroupedSalarieContactOptions(groupedOptionsFormat);
        }).catch((err) => {
            showError(err);
        });
    }, []);



    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchDocument = (e) => {
        setSearchBarContent(e.target.value);
    };

    const [allGestionsDocumentsChantiers, setAllGestionsDocumentsChantiers] = useState([]);
    const getCurrentDimension = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    };
    const [screenSize, setScreenSize] = useState(getCurrentDimension());


    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        });
    }, [screenSize]);
    // handle form
    const formSchema = yup.object().shape({
        libelle_lieu: yup.string().required("Veuillez saisir le nom du chantier"),
        zone: yup.string().required("Veuillez entrer la zone du chantier"),
        codeChantier: yup.string().required("Veuillez saisir le code du chantier"),
        secteur: yup.string().required("Veuillez sélectionner le secteur du chantier"),
        ville: yup.string().optional(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        lieuExecution: yup.string().optional(),
        gps: yup.string().nullable().notRequired().when("gps", (gps, schema) => {
            if (gps) {
                return schema.matches(/^([0-9]{1,2})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([NS]) ([0-9]{1,3})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([EW])$/, "Erreur de format du point gps, la latitude et la longitude doivent être séparé par un espace");
            }
            return schema;
        }),
    },
        [["codePostal", "codePostal"], ["gps", "gps"]]
    );

    const onSubmit = (values, actions) => {
        progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            codePostal: values.codePostal ? values.codePostal : null,
            gps: values.gps.length ? values.gps : null
        };
        // post chantier
        axiosInstance.patch(`/chantiers/${chantier._id._id}`, patchData)
            .then((result) => {
                progressBarRef.current.complete();
                customToast.success("Le chantier a été modifié ave succès");
                setChantier(result.data);
            }).catch((err) => {
                actions.resetForm();
                progressBarRef.current.complete();
                showError(err);
            });
        // Documents Chantiers
        axiosInstance.get("/uploads/gestionDocumentsChantiers")
            .then((result) => {
                setAllGestionsDocumentsChantiers(result.data);
            })
            .catch((err) => {
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_lieu: "",
            zone: "",
            codeChantier: "",
            secteur: "",
            ville: "",
            codePostal: "",
            lieuExecution: "",
            gps: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    // PROCESSUS
    const [allProcessus, setAllProcessus] = useState([]);
    const [selectedProcessus, setSelectedProcessus] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/chantiers/${idChantier}`)
            .then((result) => {
                setChantier(result.data);
                setSalarieContacts(result.data._id.contacts);
                setFieldValue("libelle_lieu", result.data._id.libelle_lieu);
                setFieldValue("zone", result.data.zone._id);
                setFieldValue("codeChantier", result.data.codeChantier);
                setFieldValue("secteur", result.data.secteur);
                setFieldValue("ville", result.data._id.adresse.ville);
                setFieldValue("codePostal", result.data._id.adresse.codePostal);
                setFieldValue("lieuExecution", result.data._id.adresse.lieuExecution);
                if (result.data._id.adresse.hasOwnProperty("gps") && result.data._id.adresse.gps.latitude !== null && result.data._id.adresse.gps.longitude !== null) {
                    setFieldValue("gps", getCoordinatesFromDD(result.data._id.adresse.gps.latitude, result.data._id.adresse.gps.longitude));
                }
                // axiosInstance.get("/processusAmiante").then((res) => {
                //     let dataSelectFormat = [];
                //     for (const processus of res.data) {
                //         dataSelectFormat.push({
                //             value: processus._id, label: `${processus.codeProcessus} - ${formatMateriaux(processus.materiaux)} - Niveau ${processus.niveauEmpoussierement} - ${formatTechniqueModeOperatoire(processus.techniqueModesOperatoires)} - ${processus.protectionCollective}`,
                //             isDisabled: result.data.processus.findIndex(process => process._id === processus._id) >= 0
                //         });
                //     }
                //     setAllProcessus(dataSelectFormat);
                // }).catch((error) => {
                //     showError(error);
                // });
            })
            .catch((err) => {
                showError(err);
            });
        axiosInstance.get("/zones").then((res) => {
            setZoneChantiers(res.data.map((zone) => {
                return {
                    value: zone._id, label: `${zone.libelle_zone} - ${zone.portee_zone}km`
                };
            }));
        }).catch((error) => {
            showError(error);
        });
        axiosInstance.get("/uploads/gestionDocumentsChantiers")
            .then((result) => {
                setAllGestionsDocumentsChantiers(result.data);
            })
            .catch((err) => {
                showError(err);
            });
        // axiosInstance.get(`/releveHeure/travail/${idChantier}`)
        // .then((result) => {
        //     setReleveHeure(getHeureParSalarie(result.data.travailOuvrier, result.data.travailInterimaire, result.data.travailChefChantier));
        // })
        // .catch((err) => {
        //     showError(err);
        // });
        axiosInstance.get(`/releve-heure-v2/resumerHeureChantier/${idChantier}`)
            .then((result) => {
                setReleveHeure(result.data);
            })
            .catch((err) => {
                showError(err);
            });
    }, []);



    const onChangeZoneChantiers = (opt) => {
        setFieldValue("zone", opt.value);
    };

    const secteurOptions = [
        { label: "Gros oeuvres", value: "Gros oeuvres" },
        { label: "Travaux publics", value: "Travaux publics" }
    ];

    const onChangeSecteurChantiers = (opt) => {
        setFieldValue("secteur", opt.value);
    };

    const fieldEdited = () => {
        return values.libelle_lieu !== chantier._id.libelle_lieu ||
            values.zone !== chantier.zone._id ||
            values.codeChantier !== chantier.codeChantier ||
            values.secteur !== chantier.secteur ||
            values.ville !== chantier._id.adresse.ville ||
            values.codePostal !== chantier._id.adresse.codePostal ||
            values.lieuExecution !== chantier._id.adresse.lieuExecution ||
            values.gps !== getCoordinatesFromDD(chantier._id.adresse.gps.latitude, chantier._id.adresse.gps.longitude);
    };

    const ajouterProcessus = () => {
        axiosInstance.patch(`/chantiers/ajouterProcessus/${chantier._id._id}`, { processusId: selectedProcessus.value }).then((result) => {
            setSelectedProcessus(null);
            setChantier({ ...chantier, processus: result.data.processus });
            setAllProcessus(allProcessus.map((process) => {
                if (process.value === selectedProcessus.value) {
                    return { ...process, isDisabled: true };
                }
                return process;
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalProcessusSelected, setModalProcessusSelected] = useState({});
    const [modalDocumentDoeSelected, setModalDocumentDoeSelected] = useState({});
    const [modalChantierIdselected, setModalChantierIdselected] = useState({});

    const openSupprimerProcessusChantiers = (processusId) => {
        setModalProcessusSelected(processusId);
        setModalClasses("modal modal__active");
        setModalContent("supprimer processus");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerProcessusChantiers = (processusId) => {
        setChantier({
            ...chantier,
            processus: chantier.processus.filter(process => {
                return process._id !== processusId;
            })
        });
    };

    const [searchBarDOE, setSearchBarDOE] = useState(null);
    const onChangeSearchDOE = (e) => {
        setSearchBarDOE(e.target.value);
    };

    const getDocumentsByType = (typeDocId) => {
        for (let i = 0; i < chantier.documents.length; i++) {
            if (chantier.documents[i].type !== null && chantier.documents[i].type._id === typeDocId) {
                return i;
            }
        }
        return -1;
    };

    const viewFile = (docId) => {
        axiosInstance.get(`uploads/documents/${docId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    const downloadFile = (doc) => {
        axiosInstance.get(`uploads/documents/${doc._id}`, {
            responseType: 'blob',
        }).then((result) => {
            fileDownload(result.data, doc.file.filename);
        }).catch((err) => {
            showError(err);
        });
    };

    const openModifierDocumentChantier = (documentTypeId) => {
        setModalDocumentDoeSelected(documentTypeId);
        setModalClasses("modal modal__active");
        setModalContent("modifierDocumentChantier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const updateDocuments = (documents) => {
        setChantier({ ...chantier, documents: documents });
    };

    const ajouterDocument = (document) => {
        setChantier({ ...chantier, documents: chantier.documents.concat(document) });
    };

    const openAjouterDocumentChantierSansType = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouterDocumentChantierSansType");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        // setModalContent(<AjouterModifierDocumentChantier documentSaveRoute={"ajouterDocumentChantierSansType"} updateDocuments={(documents) => updateDocuments(documents)} idChantier={idChantier} ajouterDocument={(document) => ajouterDocument(document)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
    };

    const onSuppressionDocument = (documentId) => {
        setChantier({
            ...chantier, documents: chantier.documents.filter(doc => {
                return doc.document._id !== documentId;
            })
        });
    };

    const supprimerDocument = (documentData, idChantier) => {
        setModalDocumentDoeSelected(documentData);
        setModalChantierIdselected(idChantier);
        setModalContent("supprimerDocumentChantier");
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierDocumentChantierSansType = (documentId, title) => {
        setModalDocumentDoeSelected(documentId);
        setModalChantierIdselected(title);
        setModalContent("modifierDocumentChantierSansType");
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const getSubNavigation = () => {
        let links = [
            { label: "Informations générales", value: "informationsGenerales" },
            { label: "Documents", value: "documents" },
            { label: "Export", value: "export" }
        ];
        if (chantier.type === "ChantierAmiante") {
            links.push({ label: "Processus", value: "processus" });
        }
        return links;
    };

    const [salarieContactSelected, setSalarieContactSelected] = useState(null);
    const onAjouterContact = () => {
        if (salarieContactSelected) {
            progressBarRef.current.continuousStart();
            axiosInstance.patch(`/lieux/ajouterContact/${idChantier}`, { contact: salarieContactSelected.value }).then((result) => {
                progressBarRef.current.complete();
                setSalarieContacts(salarieContacts.concat(result.data));
                setSalarieContactSelected(null);
            }).catch((err) => {
                progressBarRef.current.complete();
                showError(err);
            });
        }
        else {
            customToast.error("Aucun salarié n'a été sélectionné pour être ajouté au contact");
        }
    };

    const supprimerContact = (contactId) => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/lieux/supprimerContact/${idChantier}`, { contact_id: contactId }).then((result) => {
            progressBarRef.current.complete();
            setSalarieContacts(salarieContacts.filter(contact => {
                return (
                    contact._id._id !== contactId
                );
            }));
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    const isSalarieAlreadyInContacts = (userId) => {
        for (const contact of salarieContacts) {
            if (contact._id._id === userId) {
                return true;
            }
        }
        return false;
    };



    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {chantier &&
                <div className="section">
                    <h1>
                        {`(${chantier.codeChantier})`}{" "}
                        {capitalizeFirstLetter(chantier._id.libelle_lieu)}
                    </h1>
                    <Breadcrumb
                        links={[
                            { label: "Gestion des données", link: "/gestion-de-donnees" },
                            { label: "Chantiers", link: "/gestion-de-donnees/chantiers" },
                            { label: chantier._id._id, link: "/gestion-de-donnees/chantiers/profil/" },
                        ]}
                    />
                    <InsideNavigationBar
                        activeValue={insideNavigationPage}
                        setActiveValue={(value) => setInsideNavigationPage(value)}
                        links={getSubNavigation()}
                    />
                    <Hider state={insideNavigationPage === "informationsGenerales"}>
                        <>
                            <div className="profil-salarie-container">
                                <div className='container-basic salarie-info'>
                                    <h4 className='mgB-b'>Informations générales</h4>
                                    <form className='form' onSubmit={handleSubmit}>
                                        <div className='label-and-input'>
                                            <label htmlFor='codeChantier' className='form-label'>Code du chantier<span className='required-field'>*</span></label>
                                            <input name='codeChantier' className={`new-default-input${errors.codeChantier && touched.codeChantier ? " input-error" : ""}`} type="text" placeholder="Code du chantier" value={values.codeChantier} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.codeChantier && touched.codeChantier && <p className="form-error-message">{errors.codeChantier}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='libelle_lieu' className='form-label'>Nom du chantier<span className='required-field'>*</span></label>
                                            <input name='libelle_lieu' className={`new-default-input${errors.libelle_lieu && touched.libelle_lieu ? " input-error" : ""}`} type="text" placeholder="Nom du chantier" value={values.libelle_lieu} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.libelle_lieu && touched.libelle_lieu && <p className="form-error-message">{errors.libelle_lieu}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='zone' className='form-label'>Zone<span className='required-field'>*</span></label>
                                            <Select
                                                options={zoneChantiers}
                                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                classNamePrefix="formSelect-search"
                                                onChange={(opt) => onChangeZoneChantiers(opt)}
                                                placeholder={"Zone"}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        fontFamily: "DM Sans",
                                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                        borderRadius: "0.6rem",
                                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                        '&:hover': {
                                                            border: '1px solid black',
                                                        },
                                                    })
                                                }}
                                                isSearchable={true}
                                                value={values.zone ? zoneChantiers.find(zone => {
                                                    return zone.value === values.zone;
                                                }) : ""}
                                            />
                                            {errors.zone && touched.zone && <p className="form-error-message">{errors.zone}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='secteur' className='form-label'>Secteur<span className='required-field'>*</span></label>
                                            <Select
                                                options={secteurOptions}
                                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                classNamePrefix="formSelect-search"
                                                onChange={(opt) => onChangeSecteurChantiers(opt)}
                                                placeholder={"Secteur"}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        fontFamily: "DM Sans",
                                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                        borderRadius: "0.6rem",
                                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                        '&:hover': {
                                                            border: '1px solid black',
                                                        },
                                                    })
                                                }}
                                                isSearchable={true}
                                                value={values.secteur ? { label: values.secteur, value: values.secteur } : ""}
                                            />
                                            {errors.secteur && touched.secteur && <p className="form-error-message">{errors.secteur}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='lieuExecution' className='form-label'>Lieu d'éxecution</label>
                                            <input name='lieuExecution' className={`new-default-input${errors.lieuExecution && touched.lieuExecution ? " input-error" : ""}`} type="text" placeholder="Lieu d'éxecution" value={values.lieuExecution} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.lieuExecution && touched.lieuExecution && <p className="form-error-message">{errors.lieuExecution}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='ville' className='form-label'>Ville</label>
                                            <input name='ville' className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} type="text" placeholder="Ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                                        </div>
                                        <div className='label-and-input last-input'>
                                            <label htmlFor='codePostal' className='form-label'>Code postal</label>
                                            <input name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                                        </div>
                                        <div className='label-and-input last-input'>
                                            <label htmlFor='gps' className='form-label'>Point gps</label>
                                            <input name='gps' className={`new-default-input${errors.gps && touched.gps ? " input-error" : ""}`} type="text" placeholder={`exemple: 47°27'43.0"N 0°30'57.2"W`} value={values.gps} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.gps && touched.gps && <p className="form-error-message">{errors.gps}</p>}
                                        </div>
                                        <input disabled={isSubmitting || !fieldEdited()} style={{ opacity: !isSubmitting && fieldEdited() ? 1 : 0.4 }} className="btn btn-primary" type="submit" value="Sauvegarder" />
                                    </form>
                                </div>
                                <div className='salarie-stats'>
                                    <div className="container-basic">
                                        <h4 className='mgB-s2'>Contacts</h4>
                                        <div className='mgB-s3' style={{ display: 'flex' }}>
                                            <Select
                                                options={groupedSalarieContactOptions}
                                                className="formSelect-search-container formSelect-search-container-full"
                                                classNamePrefix="formSelect-search"
                                                onChange={(opt) => setSalarieContactSelected(opt)}
                                                placeholder={""}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        fontFamily: "DM Sans",
                                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                        borderRadius: "0.6rem",
                                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                        '&:hover': {
                                                            border: '1px solid black',
                                                        },
                                                    })
                                                }}
                                                isOptionDisabled={(option) => isSalarieAlreadyInContacts(option.value)}
                                                isSearchable={true}
                                                isClearable={true}
                                            />
                                            <button onClick={() => onAjouterContact()} disabled={salarieContactSelected === null} style={{ opacity: salarieContactSelected !== null ? 1 : 0.4 }} className="btn btn-primary mgL-s3">Ajouter</button>
                                        </div>
                                        <hr />
                                        <div style={{ maxHeight: "200px", overflowY: "auto" }} className='mgT-s2'>
                                            {salarieContacts.length ? salarieContacts.map((contact, index) => {
                                                return <div key={contact._id._id} style={{ justifyContent: "flex-start", backgroundColor: index % 2 !== 0 ? "#f1f1f1f1" : "#fff", padding: "1.2rem 1.2rem", cursor: "default" }} className="dashboard-header-userAccount-container">
                                                    <UserAccountIcon />
                                                    <div style={{ flex: 1 }} className="mgL-s2">
                                                        <p style={{ color: "#d8556b", fontWeight: 500, lineHeight: "1.1rem", fontSize: "1.2rem", paddingBottom: "0.4rem" }}>Administration</p>
                                                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", alignItems: "center", gap: "1rem" }}>
                                                            <p style={{ flex: 1, fontSize: "1.3rem", fontWeight: 700, color: "#1E1E1E", lineHeight: "1.6rem", whiteSpace: "nowrap" }}>{contact._id.nom + " " + contact._id.prenom}</p>
                                                            <p style={{ flex: 1, lineHeight: "1rem", whiteSpace: "nowrap" }}>{contact._id.telephone}</p>
                                                        </div>
                                                    </div>
                                                    <FontAwesomeIcon onClick={() => supprimerContact(contact._id._id)} icon={faXmarkLarge} size='lg' />
                                                </div>;
                                            }) :
                                                <p>Aucun salarié dans la liste des contacts</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="container-basic mgT-s3">
                                        <div className={releveHeure.total !== "0h" ? "mgB-s2" : ""} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <h4 >Relevé d'heures</h4>
                                            <p>{releveHeure.total}</p>
                                        </div>
                                        {releveHeure.total !== "0h" &&
                                            <>
                                                <hr />
                                                <div className="mgT-s2">
                                                    {releveHeure.salaries.map((salarie) => {
                                                        return <div key={salarie.salarie._id} style={{ display: "flex", alignItems: "center" }}>
                                                            <p style={{ flex: 1 }}>({salarie.salarie.login_matricule}) - {salarie.salarie.nom} {salarie.salarie.prenom}</p>
                                                            <p>{salarie.total}</p>
                                                        </div>;
                                                    })}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    </Hider>
                    <Hider state={insideNavigationPage === "processus"}>
                        <>
                            <div className='mgB-s3' style={{ display: "flex", alignItems: "center" }}>
                                <Select
                                    options={allProcessus}
                                    className="formSelect-search-container formSelect-search-container-full"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => setSelectedProcessus(opt)}
                                    placeholder="Sélectionner un procesus"
                                    isSearchable={true}
                                    value={selectedProcessus || ''}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        }),
                                        menu: (base) => ({
                                            ...base
                                        }),
                                        menuList: (base) => ({
                                            ...base,
                                            minHeight: `${screenSize.height - screenSize.height / 3}px` // your desired height
                                        })
                                    }}
                                />
                                <button disabled={selectedProcessus === null} onClick={() => ajouterProcessus()} className='mgL-s3 btn btn-primary'>+ Ajouter processus</button>
                            </div>
                            <TableRender
                                columns={geNewProfilChantierProcessusColumns((link) => navigate(link), (processusId) => openSupprimerProcessusChantiers(processusId))}
                                tableData={chantier.processus}
                                sortBy={[
                                    {
                                        id: 'codeProcessus',
                                        desc: false
                                    }
                                ]}
                                pageName="newProfilChantierProcessus"
                            />
                        </>
                    </Hider>
                    <Hider state={insideNavigationPage === "documents"}>
                        <>
                            <div className="filterContainer mgT-s3 mgB-s3">
                                <SearchInput fullwidth={true} placeholder="Rechercher un document" searchContent={searchBarDOE ? searchBarDOE : ""} onChangeSearch={onChangeSearchDOE} />
                                <button onClick={() => openAjouterDocumentChantierSansType()} className='btn btn-primary'>Ajouter un document</button>
                            </div>
                            <div className='container-basic'>
                                {allGestionsDocumentsChantiers.map((doc) => {
                                    const documentIndex = getDocumentsByType(doc._id);
                                    return (
                                        <div className="mgB-s1" key={doc._id}>
                                            {
                                                documentIndex >= 0 ?
                                                    <>
                                                        <div className="container-flex pdT-s2 pdB-s2 rvc" style={{ borderBottom: "1px solid #000", justifyContent: "space-between" }}>
                                                            <div className="container-flex rvc">
                                                                {iconExtension(chantier.documents[documentIndex].document.file.filename)}
                                                                <div className="mgL-s2">
                                                                    <p className="small-title">{chantier.documents[documentIndex].type.libelleDocument}</p>
                                                                    <div className="container-flex rvc">
                                                                        <p className="small-text">Taille: {formatBytes(chantier.documents[documentIndex].document.file.length, 0)}</p>
                                                                        <p className="small-text mgL-s2">Dernière modification: Il y a {getLastModified(chantier.documents[documentIndex].document.file.uploadDate)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-buton-container mgL-s2">
                                                                <button
                                                                    onClick={() => openModifierDocumentChantier(doc._id)}
                                                                    className="table-modify-button"
                                                                >
                                                                    <EditIcon />
                                                                </button>
                                                                <button
                                                                    onClick={() => viewFile(chantier.documents[documentIndex].document._id)}
                                                                    className="select-semaine-button default-radius"
                                                                >
                                                                    <Icon className="eyeDocumentSvg" icon="akar-icons:eye" />
                                                                </button>
                                                                <button
                                                                    onClick={() => downloadFile(chantier.documents[documentIndex].document)}
                                                                    className="table-archive-button default-radius mgL-s2"
                                                                >
                                                                    <Icon className="telechargerDocumentSvg" icon="eva:download-fill" />
                                                                </button>
                                                                <button
                                                                    onClick={() => supprimerDocument(chantier.documents[documentIndex].document._id, chantier._id._id)}
                                                                    className="table-delete-button"
                                                                >
                                                                    <SupprimerIcon />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="container-flex pdT-s2 pdB-s2 rvc" style={{ borderBottom: "1px solid #000", justifyContent: "space-between" }}>
                                                            <div className="container-flex rvc">
                                                                {iconExtension(doc.baseDocument.file.filename)}
                                                                <div className="mgL-s2">
                                                                    <p className="small-title">{doc.libelleDocument}</p>
                                                                    <div className="container-flex rvc">
                                                                        <p className="small-text">Taille: {formatBytes(doc.baseDocument.file.length, 0)}</p>
                                                                        <p className="small-text mgL-s2">Dernière modification: Il y a {getLastModified(doc.baseDocument.file.uploadDate)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-buton-container mgL-s2">
                                                                <button
                                                                    onClick={() => openModifierDocumentChantier(doc._id)}
                                                                    className="table-modify-button"
                                                                >
                                                                    <EditIcon />
                                                                </button>
                                                                <button
                                                                    onClick={() => viewFile(doc.baseDocument._id)}
                                                                    className="select-semaine-button default-radius"
                                                                >
                                                                    <Icon className="eyeDocumentSvg" icon="akar-icons:eye" />
                                                                </button>
                                                                <button
                                                                    onClick={() => downloadFile(doc.baseDocument)}
                                                                    className="table-archive-button default-radius mgL-s2"
                                                                >
                                                                    <Icon className="telechargerDocumentSvg" icon="eva:download-fill" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    );
                                })}
                                {chantier.hasOwnProperty("documents") &&
                                    chantier.documents.map((document) => {
                                        if (document.type === null) {
                                            return (
                                                <div className="mgB-s1" key={document._id}>
                                                    <div className="container-flex pdT-s2 pdB-s2 rvc" style={{ borderBottom: "1px solid #000", justifyContent: "space-between" }}>
                                                        <div className="container-flex rvc">
                                                            {iconExtension(document.document.file.filename)}
                                                            <div className="mgL-s2">
                                                                <p className="small-title">{document.document.titre}</p>
                                                                <div className="container-flex rvc">
                                                                    <p className="small-text">Taille: {formatBytes(document.document.file.length, 0)}</p>
                                                                    <p className="small-text mgL-s2">Dernière modification: Il y a {getLastModified(document.document.file.uploadDate)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-buton-container mgL-s2">
                                                            <button
                                                                onClick={() => openModifierDocumentChantierSansType(document._id, document.document.titre)}
                                                                className="table-modify-button"
                                                            >
                                                                <EditIcon />
                                                            </button>
                                                            <button
                                                                onClick={() => viewFile(document.document._id)}
                                                                className="select-semaine-button default-radius"
                                                            >
                                                                <Icon className="eyeDocumentSvg" icon="akar-icons:eye" />
                                                            </button>
                                                            <button
                                                                onClick={() => downloadFile(document.document)}
                                                                className="table-archive-button default-radius mgL-s2"
                                                            >
                                                                <Icon className="telechargerDocumentSvg" icon="eva:download-fill" />
                                                            </button>
                                                            <button
                                                                onClick={() => supprimerDocument(document.document._id, chantier._id._id)}
                                                                className="table-delete-button"
                                                            >
                                                                <SupprimerIcon />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return;
                                    })
                                }
                            </div>
                        </>
                    </Hider>

                </div>
            }
            <Modal modalClass={modalClasses}>
                <Hider state={modalContent === "supprimer processus"}>
                    <SupprimerModalForm destination="supprimerProcessusDuChantier" setAllProcessus={(processus) => setAllProcessus(processus)} chantier={chantier} setChantier={(chantier) => setChantier(chantier)} modalProcessusSelected={modalProcessusSelected} supprimerProcessusChantiers={(processusId) => supprimerProcessusChantiers(processusId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierDocumentChantier"}>
                    <AjouterModifierDocumentChantier documentSaveRoute={"documentChantier"} updateDocuments={(documents) => updateDocuments(documents)} idChantier={idChantier} ajouterDocument={(document) => ajouterDocument(document)} documentTypeId={modalDocumentDoeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "ajouterDocumentChantierSansType"}>
                    <AjouterModifierDocumentChantier documentSaveRoute={"ajouterDocumentChantierSansType"} updateDocuments={(documents) => updateDocuments(documents)} idChantier={idChantier} ajouterDocument={(document) => ajouterDocument(document)} documentTypeId={modalDocumentDoeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "supprimerDocumentChantier"}>
                    <SupprimerModalForm destination="supprimerDocumentChantier" onSuppressionDocument={(documentId) => onSuppressionDocument(documentId)} idChantier={modalChantierIdselected} documentData={modalDocumentDoeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
                <Hider state={modalContent === "modifierDocumentChantierSansType"}>
                    <AjouterModifierDocumentChantier documentSaveRoute={"modifierDocumentChantierSansType"} title={modalChantierIdselected} documentId={modalDocumentDoeSelected} updateDocuments={(documents) => updateDocuments(documents)} idChantier={idChantier} ajouterDocument={(document) => ajouterDocument(document)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                </Hider>
            </Modal>
        </>
    );
};
// setModalContent()

export default ProfilChantierNew;
