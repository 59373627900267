import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../utils/axiosInstance';
import ReactTooltip from "react-tooltip";
import { Icon } from '@iconify/react';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';
import { useFormik } from 'formik';
import * as yup from "yup";
import Hider from "react-hider";

const ModifySalarieForm = (props) => {
    // const { register, handleSubmit, formState: { errors } } = useForm();
    // const [isButtonDisabled, setButtonDisabled] = useState(false)

    // const onSubmit = data => {
    //     setButtonDisabled(true)
    //     const modifyUrl = "/utilisateurs/" + props.salarie.user._id;
    //     let modifiedValues = {};
    //     if (data.Nom !== props.salarie.user.nom) {
    //         modifiedValues['nom'] = data.Nom;
    //     }
    //     if (data['Prénom'] !== props.salarie.user.prenom) {
    //         modifiedValues['prenom'] = data['Prénom'];
    //     }
    //     if (data['Téléphone'] !== props.salarie.user.telephone) {
    //         modifiedValues['telephone'] = data['Téléphone'];
    //     }
    //     if (data['Secteur'] !== props.salarie.user.secteur) {
    //         modifiedValues['secteur'] = data['Secteur'];
    //     }
    //     if (data['Statut'] !== props.salarie.userRole) {
    //         modifiedValues['statut'] = data['Statut'];
    //     }
    //     if (data.Matricule !== props.salarie.user.login_matricule) {
    //         modifiedValues['login_matricule'] = data.Matricule;
    //     }
    //     if (mdpValue !== "") {
    //         modifiedValues["mdp"] = mdpValue;
    //     }
    //     if (modifiedValues["mdp"] !== undefined && modifiedValues["mdp"].length < 8 && modifiedValues["mdp"].length > 15) {
    //         setErrorMdp("Veuillez saisir un mot de passe contenant de 8 à 15 caractères")
    //         setButtonDisabled(false)
    //     }
    //     else if (Object.keys(modifiedValues).length === 0) {
    //         alert("Aucun élement modifier")
    //         setButtonDisabled(false)
    //     }
    //     else if (modifiedValues.hasOwnProperty('telephone') && modifiedValues['telephone'] !== null &&   modifiedValues["telephone"].length > 0 && !modifiedValues["telephone"].match(/^(?:(?:(?:\+|00)33\D?(?:\D?\(0\)\D?)?)|0){1}[1-9]{1}(?:\D?\d{2}){4}$/, "i")){
    //         setErrorMdp("Veuillez saisir un numero de telepone correct")
    //         setButtonDisabled(false)
    //     }
    //     else {
    //         axiosInstance.patch(modifyUrl, modifiedValues)
    //         .then((result) => {
    //             customToast.success("Le salarié a été modifié avec succès")
    //             if (props.editFromProfile) {
    //                 console.log(result.data)
    //                 props.modifierProfilSalarie(result.data)
    //             }
    //             else {
    //                 props.modifierSalarie()
    //             }
    //             close()
    //         }).catch((err) => {
    //             setButtonDisabled(false)
    //             showError(err) 
    //         });
    //     }
    // }

    // const [mdpValue, setMdpValue] = useState("");
    // const [errorMdp, setErrorMdp] = useState(null);

    // const generatePassword = () => {
    //     const list = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
    //     var res = "";
    //     for(var i = 0; i < 8; i++) {
    //         var rnd = Math.floor(Math.random() * list.length);
    //         res = res + list.charAt(rnd);
    //     }
    //     return res;        
    // }

    // const getGeneratePassword = () => {
    //     let password = generatePassword()
    //     while (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, "i")) {
    //         password = generatePassword();
    //     }
    //     setMdpValue(password);
    //     setErrorMdp("")
    // }

    // const onChangeHandler = event => {
    //     if (errorMdp !== null) {
    //         if ((event.target.value.length >= 8 && event.target.value.length <= 15) || event.target.value.length === 0) {
    //             setErrorMdp("")
    //         }
    //         else {
    //             setErrorMdp("Veuillez saisir un mot de passe contenant de 8 à 15 caractères")
    //         }
    //     }
    //     setMdpValue(event.target.value);
    // };

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // form schema
    const formSchema = yup.object().shape({
        nom: yup.string().required("Veuillez saisir le nom du salarié"),
        prenom: yup.string().required("Veuillez saisir le prénom du salarié"),
        statut: yup.string().required("Aucun statut sélectionné pour le salarié"),
        secteur: yup.string().required("Aucun secteur sélectionné pour le salarié"),
        telephone: yup.string().matches(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/, "Veuillez saisir un numéro de téléphone correct "),
        residence: yup.string().nullable().notRequired(),
        ville: yup.string().nullable().notRequired(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        matricule: yup.string().required("Veuillez saisir le matricule du salarié"),
        motDePasse: yup
            .string()
            .optional()
            .min(8, 'Votre mot de passe doit faire au minimum 8 caractères')
            .max(20, 'Votre mot de passe ne peut pas excedé 20 caractères')
            .matches(
                /^(?=.*[A-Z])/,
                'Votre mot de passe doit contenir au moins une lettre majuscule'
            )
            .matches(
                /^(?=.*\d)/,
                'Votre mot de passe doit contenir au moins un chiffre'
            ),
        numeroPermis: yup.string().nullable().notRequired()
    },
        ["codePostal", "codePostal"]
    );

    // handle submit
    const onSubmit = (values, actions) => {
        const modifyUrl = "/utilisateurs/" + props.salarie.user._id;
        let patchData = {
            nom: values.nom,
            prenom: values.prenom,
            statut: values.statut,
            secteur: values.secteur,
            telephone: values.telephone,
            login_matricule: values.matricule,
            adresse: values.residence,
            codePostal: values.codePostal.length ? values.codePostal : null,
            ville: values.ville,
            numeroPermis: values.numeroPermis
        };

        if (values.motDePasse) {
            patchData.mdp = values.motDePasse;
        }

        axiosInstance.patch(modifyUrl, patchData)
            .then((result) => {
                customToast.success("Le salarié a été modifié avec succès");
                if (props.editFromProfile) {
                    props.modifierProfilSalarie(result.data);
                }
                else {
                    props.modifierSalarie();
                }
                close();
            }).catch((err) => {
                setSubmitting(false);
                showError(err);
            });
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nom: props.salarie.user.nom,
            prenom: props.salarie.user.prenom,
            statut: props.salarie.userRole,
            secteur: props.salarie.user.secteur,
            telephone: props.salarie.user.telephone,
            matricule: props.salarie.user.login_matricule,
            motDePasse: "",
            residence: props.salarie.user.residence.adresse ? props.salarie.user.residence.adresse : "",
            ville: props.salarie.user.residence.ville ? props.salarie.user.residence.ville : "",
            codePostal: props.salarie.user.residence.codePostal ? props.salarie.user.residence.codePostal : "",
            numeroPermis: props.salarie.user.hasOwnProperty("numeroPermis") ? props.salarie.user.numeroPermis : ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const generatePassword = () => {
        const list = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
        var res = "";
        for (var i = 0; i < 8; i++) {
            var rnd = Math.floor(Math.random() * list.length);
            res = res + list.charAt(rnd);
        }
        return res;
    };

    const getGeneratePassword = () => {
        let password = generatePassword();
        while (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, "i")) {
            password = generatePassword();
        }
        setFieldValue("motDePasse", password);
    };


    return (
        <div className="modal-content default-form">
            <h2 className="new-modal-title">Modifier un salarié</h2>
            <button className='modal-close-button' onClick={() => close()}><Icon className='close-icon' icon="ic:round-close" /></button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div className="two-column-form">
                    <div className='label-and-input'>
                        <label htmlFor='nom' className='form-label'>Nom<span className='required-field'>*</span></label>
                        <input name='nom' className={`new-default-input${errors.nom && touched.nom ? " input-error" : ""}`} type="text" placeholder="Nom" value={values.nom} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nom && touched.nom && <p className="form-error-message">{errors.nom}</p>}
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='prenom' className='form-label'>Prénom<span className='required-field'>*</span></label>
                        <input name='prenom' className={`new-default-input${errors.prenom && touched.prenom ? " input-error" : ""}`} type="text" placeholder="Prénom" value={values.prenom} onChange={handleChange} onBlur={handleBlur} />
                        {errors.prenom && touched.prenom && <p className="form-error-message">{errors.prenom}</p>}
                    </div>
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor='statut' >Statut<span className='required-field'>*</span></label>
                    <select name="statut" className={`new-default-input${errors.statut && touched.statut ? " input-error" : ""}`} value={values.statut} onChange={handleChange} onBlur={handleBlur} >
                        <option value="Administration">Administration</option>
                        <option value="Chauffeur">Chauffeur</option>
                        <option value="Chef de chantier">Chef de chantier</option>
                        <option value="Conducteur de travaux">Conducteur de travaux</option>
                        <option value="Dispatcher">Dispatcher</option>
                        <option value="Encadrant de chantier">Encadrant de chantier</option>
                        <option value="Encadrant technique">Encadrant technique</option>
                        <option value="Intérimaire">Intérimaire</option>
                        <option value="Opérateur">Opérateur</option>
                        <option value="Ouvrier">Ouvrier</option>
                    </select>
                    {errors.statut && touched.statut && <p className="form-error-message">{errors.statut}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='secteur' className='form-label'>Secteur<span className='required-field'>*</span></label>
                    <select name="secteur" className={`new-default-input${errors.secteur && touched.secteur ? " input-error" : ""}`} value={values.secteur} onChange={handleChange} onBlur={handleBlur} >
                        <option value="Travaux publics">Travaux publics</option>
                        <option value="Gros oeuvres">Gros oeuvres</option>
                        <option value="3D">3D</option>
                        <option value="Transport">Transport</option>
                        <option value="Atelier">Atelier</option>
                    </select>
                    {errors.secteur && touched.secteur && <p className="form-error-message">{errors.secteur}</p>}
                </div>
                <Hider state={values.statut === "Chauffeur"}>
                    <div className='label-and-input'>
                        <label htmlFor='numeroPermis' className='form-label'>Numéro de permis</label>
                        <input name='numeroPermis' className={`new-default-input${errors.numeroPermis && touched.numeroPermis ? " input-error" : ""}`} type="text" placeholder="Numéro de permis" value={values.numeroPermis} onChange={handleChange} onBlur={handleBlur} />
                        {errors.numeroPermis && touched.numeroPermis && <p className="form-error-message">{errors.numeroPermis}</p>}
                    </div>
                </Hider>
                <div className='label-and-input'>
                    <label htmlFor='telephone' className='form-label'>Téléphone</label>
                    <input name='telephone' className={`new-default-input${errors.telephone && touched.telephone ? " input-error" : ""}`} type="text" placeholder="Téléphone" value={values.telephone} onChange={handleChange} onBlur={handleBlur} />
                    {errors.telephone && touched.telephone && <p className="form-error-message">{errors.telephone}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="residence">Adresse</label>
                    <input className={`new-default-input${errors.residence && touched.residence ? " input-error" : ""}`} name="residence" type="text" placeholder="residence" value={values.residence} onChange={handleChange} onBlur={handleBlur} />
                    {errors.residence && touched.residence && <p className="form-error-message">{errors.residence}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="codePostal">Code postal</label>
                    <input className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} name="codePostal" type="text" placeholder="codePostal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="ville">Ville</label>
                    <input className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} name="ville" type="text" placeholder="ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                    {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="matricule">Matricule<span className='required-field'>*</span></label>
                    <input className={`new-default-input${errors.matricule && touched.matricule ? " input-error" : ""}`} name="matricule" type="text" placeholder="Matricule" value={values.matricule} onChange={handleChange} onBlur={handleBlur} />
                    {errors.matricule && touched.matricule && <p className="form-error-message">{errors.matricule}</p>}
                </div>
                <div className='label-and-input last-form-container'>
                    <div className="motDePasse-iconAndText">
                        <label className='form-label' htmlFor="motDePasse">Mot de passe<span className='required-field'>*</span></label>
                        <Icon icon="ep:question-filled" data-tip data-for="newSalariePassword" />
                    </div>
                    <ReactTooltip id="newSalariePassword" place="bottom" effect="solid">
                        Le mot de passe doit contenir de 8 a 15 caractères, au moins 1 lettre en majuscule et au moins 1 chiffre
                    </ReactTooltip>
                    <div className="mdpInput-button-container">
                        <input value={values.motDePasse} className={`new-default-input${errors.motDePasse && touched.motDePasse ? " input-error" : ""}`} name="motDePasse" type="text" placeholder="Mot de passe" onChange={handleChange} onBlur={handleBlur} />
                        <button type="button" onClick={() => getGeneratePassword()} disabled={isSubmitting} className='generate-mdp-button mgT-s2' data-tip data-for="genertateBtn" ><Icon icon="si-glyph:arrow-reload" /></button>
                        <ReactTooltip id="genertateBtn" place="bottom" effect="solid">
                            Génerer un mot de passe
                        </ReactTooltip>
                    </div>
                    {errors.motDePasse && touched.motDePasse && <p className="form-error-message">{errors.motDePasse}</p>}
                </div>
                <div className="button-container-flex">
                    <button type="button" disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
                    <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} type="submit" className='btn btn-primary medium-btn fw' value="Modifier" />
                </div>
            </form>
        </div>
        // <div className="modal-content modifier-form">
        //     <h3 className='modal-title'>Modifier un salarié</h3>
        //     <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
        //     <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
        //         <label className='modifier-form-label default-label' htmlFor="nom">Nom</label>
        //         <input className="modifier-form-input default-input" name="nom" type="text" placeholder="Nom" defaultValue={props.salarie.user.nom} {...register("Nom", {required: true})} />
        //         {errors['Nom'] && errors['Nom'].type === "required" && <p className="login-error-message">Veuillez saisir un nom</p>}
        //         <label className='modifier-form-label default-label' htmlFor="prenom">Prénom</label>
        //         <input className="modifier-form-input default-input" name="prenom" type="text" defaultValue={props.salarie.user.prenom} placeholder="Prénom" {...register("Prénom", {required: true})} />
        //         {errors['Prénom'] && errors['Prénom'].type === "required" && <p className="login-error-message">Veuillez saisir un prénom</p>}
        //         <label className='ajouter-form-label default-label' htmlFor="statut">Statut</label>
        //         <select className="new-default-input" name="statut" defaultValue={props.salarie.userRole} {...register("Statut", { required: true })}>
        //             <option value="Administration">Administration</option>
        //             <option value="Chauffeur">Chauffeur</option>
        //             <option value="Chef de chantier">Chef de chantier</option>
        //             <option value="Encadrant de chantier">Encadrant de chantier</option>
        //             <option value="Encadrant technique">Encadrant technique</option>                        
        //             <option value="Intérimaire">Intérimaire</option>
        //             <option value="Opérateur">Opérateur</option>
        //             <option value="Ouvrier">Ouvrier</option>
        //         </select>
        //         <label className='ajouter-form-label default-label' htmlFor="secteur">Secteur</label>
        //         <select className="new-default-input" name="secteur" defaultValue={props.salarie.user.secteur} {...register("Secteur", { required: true })}>
        //             <option value="Travaux publics">Travaux publics</option>
        //             <option value="Gros oeuvres">Gros oeuvres</option>
        //             <option value="3D">3D</option>
        //             <option value="Transport">Transport</option>
        //             <option value="Atelier">Atelier</option>
        //         </select>
        //         <label className='ajouter-form-label default-label' htmlFor="matricule">Téléphone</label>
        //         <input className="ajouter-form-input default-input" name="matricule" type="text" placeholder="Téléphone" defaultValue={props.salarie.user.telephone} {...register("Téléphone")} />
        //         {errors['Téléphone'] && errors['Téléphone'].type === "required" && <p className="login-error-message">Veuillez saisir un numéro de téléphone</p>}
        //         <label className='modifier-form-label default-label' htmlFor="matricule">Matricule</label>
        //         <input className="modifier-form-input default-input" name="matricule" type="text" placeholder="Matricule" defaultValue={props.salarie.user.login_matricule} {...register("Matricule", {required: true})} />
        //         {errors['Matricule'] && errors['Matricule'].type === "required" && <p className="login-error-message">Veuillez saisir un matricule</p>}
        //         <div className="motDePasse-iconAndText">
        //             <label className='default-label' htmlFor="mdp">Mot de passe</label>
        //             <Icon icon="ep:question-filled" data-tip data-for="modifierSalariePassword"/>
        //             <ReactTooltip id="modifierSalariePassword" place="right" effect="solid">
        //                 Le nouveau mot de passe doit contenir de 8 a 15 caractères, au moins 1 lettre en majuscule et au moins 1 chiffre
        //             </ReactTooltip>
        //         </div>
        //         <div className="mdpInput-button-container">
        //             <input onChange={onChangeHandler} value={mdpValue} className="modifier-form-input default-input" name="mdp" type="text" placeholder="Mot de passe"  />
        //             <button type="button" onClick={() => getGeneratePassword()} disabled={isButtonDisabled} className='generate-mdp-button' data-tip data-for="genertateBtn" ><Icon icon="si-glyph:arrow-reload" /></button>
        //             <ReactTooltip id="genertateBtn" place="bottom" effect="solid">
        //                 Génerer un mot de passe
        //             </ReactTooltip>
        //         </div>
        //         {errorMdp !== null && errorMdp !== "" && <p className="login-error-message">{errorMdp}</p>}
        //         <div className="button-container">
        //             <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
        //             <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="Modifier"/>
        //         </div>
        //     </form>
        // </div>
    );
};

export default ModifySalarieForm;
