import React, { useEffect, useRef, useState, useMemo } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Hider from "react-hider";
import axiosInstance from '../../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import { showError } from '../../utils/ErrorHelper';
import { banqueHeureTotal, capitalizeFirstLetter, formatIntTwoDigitString } from '../../utils/GeneralHelper';
import * as yup from "yup";
import { useFormik } from 'formik';
import { format } from 'date-fns';
import SearchInput from '../../components/ui/SearchInput';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from '../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import Modal from '../../components/Modal';
import AjouterDocumentSalarie from '../../components/forms/GestionsForms/AjouterDocumentSalarie';
import TableRender from '../../components/tables/TableRender';
import { getDocumentsSalarieColumns } from '../../components/tables/TableColumns';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ModifierDocumentSalarie from '../../components/forms/GestionsForms/ModifierDocumentSalarie';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import FormationsSalarie from '../../components/FormationsSalarie';
import Breadcrumb from '../../components/ui/Breadcrumb';
import CreerFormationSalarieModal from '../../components/forms/GestionsForms/CreerFormationSalarieModal';
import ModifierFormationSalarieModal from '../../components/forms/GestionsForms/ModifierFormationSalarieModal';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import fileDownload from 'js-file-download';
import customToast from '../../utils/ToastifyHelper';
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import ProfilePicture from '../../components/ui/ProfilePicture';
import DefaultProfilePicture from '../../components/ui/DefaultProfilePicture';



const ProfilSalarieNew = (props) => {
    let { idSalarie } = useParams();
    const progressBarRef = useRef(null);
    const [loadingApi, setLoadingApi] = useState(false);
    const [salarie, setSalarie] = useState(null);
    const [salarieBanqueHeure, setSalarieBanqueHeure] = useState(null);
    const [statAmiante, setStatAmiante] = useState(null);
    const [afficherProfilSalarie, setAfficherProfilSalarie] = useState("informationsGenerales");
    const [showedDocuments, setShowedDocuments] = useState([]);
    const [vehiculesGroupedOptions, setVehiculesGroupedOptions] = useState({ "tracteurBenne": [], "remorque": [] });
    //document search bar
    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchDocument = (e) => {
        setSearchBarContent(e.target.value);
    };

    // form schema
    const formSchema = yup.object().shape({
        nom: yup.string().required("Veuillez saisir le nom du salarié"),
        prenom: yup.string().required("Veuillez saisir le prénom du salarié"),
        statut: yup.string().required("Aucun statut sélectionné pour le salarié"),
        secteur: yup.string().required("Aucun secteur sélectionné pour le salarié"),
        telephone: yup.string().matches(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/, "Veuillez saisir un numéro de téléphone correct "),
        matricule: yup.string().required("Veuillez saisir le matricule du salarié"),
        residence: yup.string().nullable().notRequired(),
        ville: yup.string().nullable().notRequired(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        numeroPermis: yup.string().nullable().notRequired()
    },
        ["codePostal", "codePostal"]
    );

    // handle submit
    const onSubmit = (values, actions) => {
        const patchData = {
            nom: values.nom,
            prenom: values.prenom,
            statut: values.statut,
            secteur: values.secteur,
            telephone: values.telephone,
            login_matricule: values.matricule,
            adresse: values.residence,
            codePostal: values.codePostal.length ? values.codePostal : null,
            ville: values.ville,
            numeroPermis: values.numeroPermis
        };
        setLoadingApi(true);
        setSubmitting(true);
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/utilisateurs/${idSalarie}`, patchData).then((result) => {
            setLoadingApi(false);
            setSubmitting(false);
            progressBarRef.current.complete();
            setSalarie(result.data);
            setFieldValue("nom", result.data.nom);
            setFieldValue("prenom", result.data.prenom);
            setFieldValue("statut", result.data.userRole.statut);
            setFieldValue("secteur", result.data.secteur);
            setFieldValue("telephone", result.data.telephone ? result.data.telephone : "");
            setFieldValue("matricule", result.data.login_matricule);
            if (result.data.residence.adresse !== null) {
                setFieldValue("residence", result.data.residence.adresse);
            }
            if (result.data.residence.ville !== null) {
                setFieldValue("ville", result.data.residence.ville);
            }
            if (result.data.residence.codePostal !== null) {
                setFieldValue("codePostal", result.data.residence.codePostal);
            }
            if (result.data.userRole.statut === "Chauffeur") {
                setFieldValue("numeroPermis", result.data.numeroPermis);
            }
            customToast.success("Le salarié a été modifié avec succès");
        }).catch((err) => {
            setLoadingApi(false);
            setSubmitting(false);
            progressBarRef.current.complete();
        });
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nom: "",
            prenom: "",
            statut: "Administration",
            secteur: "Travaux publics",
            telephone: "",
            matricule: "",
            motDePasse: "",
            residence: "",
            ville: "",
            codePostal: "",
            numeroPermis: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const [tracteurBenneSelected, setTracteurBenneSelected] = useState(null);
    const [remorqueSelected, setRemorqueSelected] = useState(null);

    useEffect(() => {
        //load salarie data
        // loading
        setLoadingApi(true);
        progressBarRef.current.continuousStart();
        axiosInstance.get(`utilisateurs/${idSalarie}`).then((result) => {
            setLoadingApi(false);
            progressBarRef.current.complete();
            setSalarie(result.data);
            setFieldValue("nom", result.data.nom);
            setFieldValue("prenom", result.data.prenom);
            setFieldValue("statut", result.data.userRole.statut);
            setFieldValue("secteur", result.data.secteur);
            setFieldValue("telephone", result.data.telephone ? result.data.telephone : "");
            setFieldValue("matricule", result.data.login_matricule);
            if (result.data.residence.adresse !== null) {
                setFieldValue("residence", result.data.residence.adresse);
            }
            if (result.data.residence.ville !== null) {
                setFieldValue("ville", result.data.residence.ville);
            }
            if (result.data.residence.codePostal !== null) {
                setFieldValue("codePostal", result.data.residence.codePostal);
            }
            if (result.data.isAmiante) {
                axiosInstance.get(`entree_sortie_zone/stats/${idSalarie}`).then((res) => {
                    setStatAmiante(res.data);
                }).catch((error) => {
                    showError(error);
                });
            }
            if (result.data.userRole.statut === "Chauffeur") {
                setFieldValue("numeroPermis", result.data.numeroPermis);
                axiosInstance.get("/vehicules").then((res) => {
                    let groupedOptionsTracteur = {
                        label: "Tracteur",
                        options: res.data.filter(vehicule => {
                            return vehicule.categorie === "Tracteur";
                        }).map((vehicule) => {
                            return { label: `n°${vehicule.numeroParc} - ${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id };
                        })
                    };
                    let groupedOptionsPorteur = {
                        label: "Porteur",
                        options: res.data.filter(vehicule => {
                            return vehicule.categorie === "Porteur";
                        }).map((vehicule) => {
                            return { label: `n°${vehicule.numeroParc} - ${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id };
                        })
                    };
                    let remorqueOptions = res.data.filter(vehicule => {
                        return vehicule.categorie === "Remorque";
                    }).map((vehicule) => {
                        return vehicule.numeroParc ? { label: `n°${vehicule.numeroParc} - ${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id } : { label: `${vehicule.immatriculation} - ${vehicule.marque}`, value: vehicule._id };
                    });
                    setVehiculesGroupedOptions({ "tracteurBenne": [groupedOptionsTracteur, groupedOptionsPorteur], "remorque": remorqueOptions });
                }).catch((err) => {
                    showError(err);
                });
                // getVehicule
                axiosInstance.get(`/chauffeurs/${idSalarie}/vehiculeParDefault`).then((result) => {
                    if (result.data.vehiculeParDefaut.remorque !== null) {
                        setRemorqueSelected(result.data.vehiculeParDefaut.remorque.numeroParc ? { label: `n°${result.data.vehiculeParDefaut.remorque.numeroParc} - ${result.data.vehiculeParDefaut.remorque.immatriculation} - ${result.data.vehiculeParDefaut.remorque.marque}`, value: result.data.vehiculeParDefaut.remorque._id } : { label: `${result.data.vehiculeParDefaut.remorque.immatriculation} - ${result.data.vehiculeParDefaut.remorque.marque}`, value: result.data.vehiculeParDefaut.remorque._id });
                    }
                    if (result.data.vehiculeParDefaut.tracteurPorteur !== null) {
                        setTracteurBenneSelected({ label: `n°${result.data.vehiculeParDefaut.tracteurPorteur.numeroParc} - ${result.data.vehiculeParDefaut.tracteurPorteur.immatriculation} - ${result.data.vehiculeParDefaut.tracteurPorteur.marque}`, value: result.data.vehiculeParDefaut.tracteurPorteur._id });
                    }
                }).catch((err) => {
                    showError(err);
                });
            }
        }).catch((err) => {
            setLoadingApi(false);
            showError(err);
        });
        //load salarie banque heure
        axiosInstance.get(`/banque_heure/${idSalarie}`)
            .then((result) => {
                setSalarieBanqueHeure(result.data);
            }).catch((err) => {
                showError(err);
            });
        // LOAD TYPE DE DOC SALARIE
        // axiosInstance.get(`/gestionTypeSuivie`)
        // .then((result) => {
        //     setDocumentType(result.data);
        // }).catch((err) => {
        //     showError(err)
        // });
    }, [idSalarie]);

    // SEARCH INPUT

    useEffect(() => {
        if (salarie) {
            if (searchBarContent !== null && searchBarContent !== "") {
                let timer = setTimeout(() => {
                    setShowedDocuments(salarie.documents.filter(doc => {
                        return doc.titre.toLowerCase().includes(searchBarContent.toLowerCase());
                    }));
                }, 1000);
                return () => {
                    clearTimeout(timer);
                };
            }
            else {
                setShowedDocuments(salarie.documents);
            }
        }
    }, [salarie, searchBarContent]);



    const totalHeure = salarieBanqueHeure !== null ? banqueHeureTotal(salarieBanqueHeure) : { heure: 0, minute: 0 };

    const fieldEdited = () => {
        return salarie.nom !== values.nom || salarie.prenom !== values.prenom || values.statut !== salarie.userRole.statut || values.telephone !== salarie.telephone || values.secteur !== salarie.secteur || values.matricule !== salarie.login_matricule || values.codePostal !== salarie.residence.codePostal || values.adresse !== salarie.residence.adresse || values.ville !== salarie.residence.ville;
    };

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            openAjouterDocument(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    // GESTION MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const addDocumentSalarie = (doc) => {
        setSalarie({ ...salarie, documents: salarie.documents.concat(doc) });
    };

    const openAjouterDocument = (documentData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterDocumentSalarie documentData={documentData} addDocumentSalarie={(doc) => addDocumentSalarie(doc)} salarie={salarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const editDocumentSalarie = (doc) => {
        setSalarie({
            ...salarie, documents: salarie.documents.map((document) => {
                if (document._id === doc._id) {
                    return doc;
                }
                else {
                    return document;
                }
            })
        });
    };

    const openEditDocument = (documentData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierDocumentSalarie startProgress={() => progressBarRef.current.continuousStart()} completeProgress={() => progressBarRef.current.complete()} documentData={documentData} editDocumentSalarie={(doc) => editDocumentSalarie(doc)} salarie={salarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const deleteDocumentSalarie = (documentId) => {
        setSalarie({
            ...salarie, documents: salarie.documents.filter(doc => {
                return doc._id !== documentId;
            })
        });
    };

    const openSupprimerDocument = (documentData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerDocumentSalarie" documentData={documentData} salarie={salarie} deleteDocumentSalarie={(documentId) => deleteDocumentSalarie(documentId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };


    const openModalAjouterFormation = (addFormation) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<CreerFormationSalarieModal addFormation={(formation) => addFormation(formation)} salarie={salarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModalSupprimerFormation = (formationData, deleteFormation) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="SupprimerFormationSalarie" formationData={formationData} deleteFormation={(formation) => deleteFormation(formation)} salarie={salarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModalFormationSalarie = (formationData, editFormation) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierFormationSalarieModal formationData={formationData} editFormation={(formationUpdated) => editFormation(formationUpdated)} salarie={salarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const [dateExport, onChangeDate] = useState([new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)]);
    const [isLoading, setIsLoading] = useState(false);

    const exportData = () => {
        if (dateExport !== null && dateExport[0] !== null && dateExport[1] !== null) {
            setIsLoading(true);
            axiosInstance.get(`/export_excel/vacationSalarie/${idSalarie}?dateDebutexport=${format(dateExport[0], "yyyy-MM-dd")}&dateFinExport=${format(dateExport[1], "yyyy-MM-dd")}`, {
                responseType: 'blob',
            })
                .then((result) => {
                    fileDownload(result.data, `ExportVacations-${salarie.login_matricule}_${salarie.prenom}_${salarie.nom}-${format(dateExport[0], "dd/MM/yyyy")}_${format(dateExport[1], "dd/MM/yyyy")}.xlsx`);
                    setIsLoading(false);
                }).catch((err) => {
                    showError(err);
                    setIsLoading(false);
                });
        }
        else {
            customToast.error("Sélectionner une date de début et de fin de période pour télécharger l'export");
        }
    };

    const modifierVehiculeParDefaut = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/chauffeurs/VehiculeParDefaut/${idSalarie}`, { tracteurPorteur: tracteurBenneSelected ? tracteurBenneSelected.value : tracteurBenneSelected, remorque: remorqueSelected ? remorqueSelected.value : remorqueSelected }).then((result) => {
            progressBarRef.current.complete();
            customToast.success("Les véhicules par défaut ont été modifiés avec succès");
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {salarie &&
                <div className="section">
                    <div className='mgB-s3' style={{ display: "flex", alignItems: "center" }}>
                        <div className='mgR-s3'>
                            <Hider state={salarie.hasOwnProperty("profilePicture") && salarie.profilePicture !== null}>
                                <ProfilePicture width="120px" height="120px" edit={false} profilePicture={salarie.profilePicture} />
                                <DefaultProfilePicture additionalClassname={"salarieProfil"} width="120px" height="120px" edit={false} />
                            </Hider>
                        </div>
                        <div>
                            <h1>{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)}</h1>
                            <p>{salarie.userRole.statut}</p>
                        </div>
                    </div>
                    <Breadcrumb
                        links={[
                            { label: "Gestion des données", link: "/gestion-de-donnees/" },
                            { label: "Salariés", link: "/gestion-de-donnees/salaries/" },
                            { label: "Profil", link: "/gestion-de-donnees/salaries/profil/" },
                        ]}
                    />
                    <InsideNavigationBar
                        activeValue={afficherProfilSalarie}
                        setActiveValue={(value) => setAfficherProfilSalarie(value)}
                        links={[
                            { label: "Informations générales", value: "informationsGenerales" },
                            { label: "Documents", value: "documents" },
                            { label: "Formations", value: "formations" },
                            { label: "Export", value: "export" }
                        ]}
                    />
                    <Hider state={afficherProfilSalarie === "informationsGenerales"}>
                        <>
                            <div className="profil-salarie-container">
                                <div className='container-basic salarie-info'>
                                    <h4 className='mgB-b'>Informations générales</h4>
                                    <form className='form' onSubmit={handleSubmit}>
                                        <div className="two-column-form">
                                            <div className='label-and-input'>
                                                <label htmlFor='nom' className='form-label'>Nom<span className='required-field'>*</span></label>
                                                <input name='nom' className={`new-default-input${errors.nom && touched.nom ? " input-error" : ""}`} type="text" placeholder="Nom" value={values.nom} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.nom && touched.nom && <p className="form-error-message">{errors.nom}</p>}
                                            </div>
                                            <div className='label-and-input'>
                                                <label htmlFor='prenom' className='form-label'>Prénom<span className='required-field'>*</span></label>
                                                <input name='prenom' className={`new-default-input${errors.prenom && touched.prenom ? " input-error" : ""}`} type="text" placeholder="Prénom" value={values.prenom} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.prenom && touched.prenom && <p className="form-error-message">{errors.prenom}</p>}
                                            </div>
                                        </div>
                                        <div className='label-and-input'>
                                            <label className='form-label' htmlFor='statut' >Statut<span className='required-field'>*</span></label>
                                            <select name="statut" className={`new-default-input${errors.statut && touched.statut ? " input-error" : ""}`} value={values.statut} onChange={handleChange} onBlur={handleBlur} >
                                                <option value="Administration">Administration</option>
                                                <option value="Chauffeur">Chauffeur</option>
                                                <option value="Chef de chantier">Chef de chantier</option>
                                                <option value="Encadrant de chantier">Encadrant de chantier</option>
                                                <option value="Encadrant technique">Encadrant technique</option>
                                                <option value="Intérimaire">Intérimaire</option>
                                                <option value="Opérateur">Opérateur</option>
                                                <option value="Ouvrier">Ouvrier</option>
                                            </select>
                                            {errors.statut && touched.statut && <p className="form-error-message">{errors.statut}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor='secteur' className='form-label'>Secteur<span className='required-field'>*</span></label>
                                            <select name="secteur" className={`new-default-input${errors.secteur && touched.secteur ? " input-error" : ""}`} value={values.secteur} onChange={handleChange} onBlur={handleBlur} >
                                                <option value="Travaux publics">Travaux publics</option>
                                                <option value="Gros oeuvres">Gros oeuvres</option>
                                                <option value="3D">3D</option>
                                                <option value="Transport">Transport</option>
                                                <option value="Atelier">Atelier</option>
                                            </select>
                                            {errors.secteur && touched.secteur && <p className="form-error-message">{errors.secteur}</p>}
                                        </div>
                                        <Hider state={values.statut === "Chauffeur"}>
                                            <div className='label-and-input'>
                                                <label htmlFor='numeroPermis' className='form-label'>Numéro de permis</label>
                                                <input name='numeroPermis' className={`new-default-input${errors.numeroPermis && touched.numeroPermis ? " input-error" : ""}`} type="text" placeholder="Numéro de permis" value={values.numeroPermis} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.numeroPermis && touched.numeroPermis && <p className="form-error-message">{errors.numeroPermis}</p>}
                                            </div>
                                        </Hider>
                                        <div className='label-and-input'>
                                            <label htmlFor='telephone' className='form-label'>Téléphone</label>
                                            <input name='telephone' className={`new-default-input${errors.telephone && touched.telephone ? " input-error" : ""}`} type="text" placeholder="Téléphone" value={values.telephone} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.telephone && touched.telephone && <p className="form-error-message">{errors.telephone}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label className='form-label' htmlFor="residence">Adresse</label>
                                            <input className={`new-default-input${errors.residence && touched.residence ? " input-error" : ""}`} name="residence" type="text" placeholder="residence" value={values.residence} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.residence && touched.residence && <p className="form-error-message">{errors.residence}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label className='form-label' htmlFor="codePostal">Code postal</label>
                                            <input className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} name="codePostal" type="text" placeholder="codePostal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label className='form-label' htmlFor="ville">Ville</label>
                                            <input className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} name="ville" type="text" placeholder="ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                                        </div>
                                        <div className='label-and-input'>
                                            <label className='form-label' htmlFor="matricule">Matricule<span className='required-field'>*</span></label>
                                            <input className={`new-default-input${errors.matricule && touched.matricule ? " input-error" : ""}`} name="matricule" type="text" placeholder="Matricule" value={values.matricule} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.matricule && touched.matricule && <p className="form-error-message">{errors.matricule}</p>}
                                        </div>
                                        <input disabled={loadingApi || isSubmitting || !fieldEdited()} style={{ opacity: !loadingApi && !isSubmitting && fieldEdited() ? 1 : 0.4 }} className="btn btn-primary" type="submit" value="Sauvegarder" />
                                    </form>
                                </div>
                                <div className='salarie-stats'>
                                    {salarie.userRole.statut === "Chauffeur" &&
                                        <div className="container-basic">
                                            <h4 className='mgB-s2'>Engin par défaut</h4>
                                            <div className='mgB-s2'>
                                                <p className='form-label'>Tracteur</p>
                                                <Select
                                                    options={vehiculesGroupedOptions.tracteurBenne}
                                                    className="formSelect-search-container formSelect-search-container-full"
                                                    classNamePrefix="formSelect-search"
                                                    onChange={(opt) => setTracteurBenneSelected(opt)}
                                                    placeholder={"Porteur et tracteur"}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            fontFamily: "DM Sans",
                                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                            borderRadius: "0.6rem",
                                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                            '&:hover': {
                                                                border: '1px solid black',
                                                            },
                                                        })
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={tracteurBenneSelected}
                                                />
                                            </div>
                                            <div className='mgB-m1'>
                                                <p className='form-label'>Remorque</p>
                                                <Select
                                                    options={vehiculesGroupedOptions.remorque}
                                                    className="formSelect-search-container formSelect-search-container-full"
                                                    classNamePrefix="formSelect-search"
                                                    onChange={(opt) => setRemorqueSelected(opt)}
                                                    placeholder={"Remorque"}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            fontFamily: "DM Sans",
                                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                            borderRadius: "0.6rem",
                                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                            '&:hover': {
                                                                border: '1px solid black',
                                                            },
                                                        })
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={remorqueSelected}
                                                />
                                            </div>
                                            <button onClick={() => modifierVehiculeParDefaut()} style={{ opacity: !loadingApi && !isSubmitting && fieldEdited() ? 1 : 0.4 }} className="btn btn-primary" type="button">Modifier</button>

                                        </div>
                                    }
                                    <div className="container-basic mgT-b">
                                        <h4 className='mgB-s2'>Statistiques</h4>
                                        <div>
                                            <p className='form-label'>Banque d'heure</p>
                                            <div style={{ display: "flex" }}>
                                                <p className='small-label mgR-s1'>Total:</p>
                                                <p className="small-text">
                                                    {totalHeure.val === "neg" ?
                                                        `-${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}` :
                                                        totalHeure.val === "pos" ?
                                                            `+${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}` :
                                                            `${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}`
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <p className='form-label'>Historique</p>
                                        <ul>
                                            <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} est {salarie.userRole.statut} depuis le {format(new Date(salarie.userRole.dateDebutStatut), "dd/MM/yyyy")}</p></li>
                                            {salarie.historiqueStatut.map((statut, index) => {
                                                if (salarie.historiqueStatut.length - 1 === index) {
                                                    <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} a commencé en tant que {statut.statut} le {format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")}</p></li>;
                                                }
                                                else if (index) {
                                                    // n'est ni le premier ni le dernier élément de la liste
                                                    <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} à été {statut.statut} du {format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")} au {format(new Date(salarie.historiqueStatut[index + 1].dateDebutStatut), "dd/MM/yyyy")}</p></li>;

                                                }
                                                else {
                                                    // premier element de la liste
                                                    <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} à été {statut.statut} du {format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")} au {format(new Date(salarie.userRole.dateDebutStatut), "dd/MM/yyyy")}</p></li>;
                                                }
                                            })}
                                        </ul>
                                    </div>

                                    <div className="container-basic mgT-b">
                                        <h4 className='mgB-s2'>Amiante</h4>
                                        <div>
                                            <p className='form-label'>Banque d'heure</p>
                                            <div style={{ display: "flex" }}>
                                                <p className='small-label mgR-s1'>Total:</p>
                                                <p className="small-text">
                                                    {totalHeure.val === "neg" ?
                                                        `-${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}` :
                                                        totalHeure.val === "pos" ?
                                                            `+${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}` :
                                                            `${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}`
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <p className='form-label'>Historique</p>
                                        <ul>
                                            <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} est {salarie.userRole.statut} depuis le {format(new Date(salarie.userRole.dateDebutStatut), "dd/MM/yyyy")}</p></li>
                                            {salarie.historiqueStatut.map((statut, index) => {
                                                if (salarie.historiqueStatut.length - 1 === index) {
                                                    <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} a commencé en tant que {statut.statut} le {format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")}</p></li>;
                                                }
                                                else if (index) {
                                                    // n'est ni le premier ni le dernier élément de la liste
                                                    <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} à été {statut.statut} du {format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")} au {format(new Date(salarie.historiqueStatut[index + 1].dateDebutStatut), "dd/MM/yyyy")}</p></li>;

                                                }
                                                else {
                                                    // premier element de la liste
                                                    <li><p className="small-text">{capitalizeFirstLetter(salarie.nom)} {capitalizeFirstLetter(salarie.prenom)} à été {statut.statut} du {format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")} au {format(new Date(salarie.userRole.dateDebutStatut), "dd/MM/yyyy")}</p></li>;
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Hider>
                    <Hider state={afficherProfilSalarie === "documents"}>
                        <>
                            <div className="search-filter-container mgB-s3">
                                <div className='search-filter-content'>
                                    <div className="search-filter-section">
                                        <p>Rechercher un document</p>
                                        <SearchInput placeholder="Rechercher un document" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchDocument} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                    <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                </div>
                                {/* <div>
                                    {files.length > 0 && <h4 className='ajouter-form-label default-label'>Document à enregistrer</h4>}
                                    <ul className='fileList'>{files}</ul>
                                </div> */}
                            </div>
                            <TableRender
                                columns={getDocumentsSalarieColumns((doc) => openSupprimerDocument(doc), (doc) => openEditDocument(doc))}
                                tableData={showedDocuments}
                                sortBy={[

                                ]}
                                pageName="ProfilSalarieDocuments"
                            />
                        </>
                    </Hider>
                    <Hider state={afficherProfilSalarie === "formations"}>
                        <FormationsSalarie salarie={salarie} openModalAjouterFormation={(addFormation) => openModalAjouterFormation(addFormation)} openModalSupprimerFormation={(formationData, deleteFormation) => openModalSupprimerFormation(formationData, deleteFormation)} openModalFormationSalarie={(formationData, editFormation) => openModalFormationSalarie(formationData, editFormation)} />
                    </Hider>
                    <Hider state={afficherProfilSalarie === "export"}>
                        {salarie.isAmiante && statAmiante !== null &&
                            <div className="container-basic mgB-m2">
                                <h2 className="section-title mgB-s2">Amiante</h2>
                                <div className="mgB-s1">
                                    <p className="small-title">Nombre de vacations</p>
                                    <p className="small-text">
                                        {statAmiante.entreesSortiesZoneTotal} vacation{statAmiante.entreesSortiesZoneTotal > 1 ? "s" : ""} effectuée{statAmiante.entreesSortiesZoneTotal > 1 ? "s" : ""} au total
                                    </p>
                                    <p className="small-text">
                                        {statAmiante.entreesSortiesZoneTotalCurrentMonth} vacation{statAmiante.entreesSortiesZoneTotalCurrentMonth > 1 ? "s" : ""} effectuée{statAmiante.entreesSortiesZoneTotalCurrentMonth > 1 ? "s" : ""} ce mois-ci
                                    </p>
                                </div>
                                <div className="mgB-s1">
                                    <p className="small-title">Exporter les vacations</p>
                                    <div className="container-flex mgT-s1">
                                        {/* <label className='small-text' htmlFor="nom">Sélectionner la période d'export: </label> */}
                                        <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker searchDate-select" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChangeDate} value={dateExport} />
                                        <button onClick={() => exportData()} className='fullEdit-button black-button'>{isLoading ? <ClipLoader loading={isLoading} color={"#dcd8d3"} size={15} /> : <Icon icon="eva:download-fill" />}<p>Télécharger</p></button>
                                    </div>
                                </div>

                            </div>
                        }
                    </Hider>
                </div>
            }
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </>
    );
};

export default ProfilSalarieNew;